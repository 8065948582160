import React, { Component } from 'react';
import Authentication from '../components/user/authentication';

class PasswordReset extends Component {
  render() {
    return <Authentication status={true} type={'reset'} />;
  }
}

export default PasswordReset;
