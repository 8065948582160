import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';

import { usePlacesWidget } from 'react-google-autocomplete';

import AdminService from '../../services/admin.service';

import { v4 as uuidv4 } from 'uuid';

import { useUserStore, useSnackbarStore } from '../../store';

function CustomGeocoder() {
  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const accountInfo = useUserStore((state) => state.accountInfo);
  const { showSnackbar } = useSnackbarStore();
  let navigate = useNavigate();

  const [existsDialogOpen, setExistsDialogOpen] = useState(false);
  const [existsDialogId, setExistsDialogId] = useState();

  const sendPlaceToForm = async (value) => {
    const googleString = JSON.stringify(value);
    const googleParse = JSON.parse(googleString);

    var zipcode = '';

    for (var i = 0; i < googleParse.address_components.length; i++) {
      if (googleParse.address_components[i].types.some((e) => e === 'postal_code')) {
        zipcode = googleParse.address_components[i].short_name;
      }
    }

    var parcel = null;

    await AdminService.addSearch(
      isLoggedIn ? accountInfo.id : null,
      null,
      zipcode,
      googleParse.formatted_address
    );

    await AdminService.getParcelInfo(
      zipcode,
      googleParse.geometry.location.lng,
      googleParse.geometry.location.lat
    ).then((response) => {
      parcel = response.data;
    });

    if (parcel.success === false) {
      showSnackbar(parcel.message, parcel.severity, 5000);
      return;
    } else {
      handleAnalyze(parcel, googleParse, true);
    }
  };

  const handleAnalyze = async (parcel, googleParse, continueWithAnalysis) => {
    if (!existsDialogId && isLoggedIn && accountInfo.id) {
      const userSearches = await AdminService.getQueriesByUserId(accountInfo.id).then(
        (response) => {
          const allSearches = response.data;
          const allSearchesMinusArchived = allSearches.filter((a) => a.archived === false);
          return allSearchesMinusArchived;
        },
        (error) => {}
      );
      for (let i = 0; i < userSearches.length; i++) {
        if (googleParse.formatted_address === userSearches[i].address) {
          setExistsDialogOpen(true);
          continueWithAnalysis = false;
          setExistsDialogId(userSearches[i].id);
        }
      }
    }

    if (continueWithAnalysis === true) {
      const queryId = uuidv4();
      var user = null;

      if (parcel.parcel.zoneId === null) {
        if (isLoggedIn) {
          user = accountInfo.id;
        } else {
          user = 'anonymous';
        }

        await AdminService.addQuery(
          queryId,
          googleParse.formatted_address,
          parcel.parcel.zipcode,
          parcel.parcel.latitude,
          parcel.parcel.longitude,
          parcel.parcel.cityId,
          (40 * parcel.parcel.max_buildable).toFixed(0),
          null,
          user,
          null,
          null,
          googleParse,
          parcel.parcel.id,
          false
        );
      } else {
        const product = await AdminService.getProductsByZoneId(parcel.parcel.zoneId);

        var pl = null;
        if (product.data.products.length > 1) {
          pl = product.data.products.find((p) => p.key === 'multifamily');
          if (pl === undefined) {
            pl = product.data.products.find((p) => p.key === 'lowrise');
          }
        } else {
          pl = product.data.products[0];
        }
        var productLineKey = pl.key;

        if (isLoggedIn) {
          user = accountInfo.id;
        } else {
          user = 'anonymous';
        }

        await AdminService.addQuery(
          queryId,
          googleParse.formatted_address,
          parcel.parcel.zipcode,
          parcel.parcel.latitude,
          parcel.parcel.longitude,
          parcel.parcel.cityId,
          (40 * parcel.parcel.max_buildable).toFixed(0),
          null,
          user,
          productLineKey,
          null,
          googleParse,
          parcel.parcel.id,
          false
        );
      }

      var metadata = {
        zipcode: parcel.parcel.zipcode,
        address: googleParse.formatted_address
      };

      window.Intercom('trackEvent', 'new-search', metadata);

      navigate(`/redtech/${queryId}`);
    }
  };

  const { ref: materialRef } = usePlacesWidget({
    onPlaceSelected: (place) => sendPlaceToForm(place),
    inputAutocompleteValue: 'address',
    options: {
      types: 'address',
      componentRestrictions: { country: 'us' }
    }
  });

  return (
    <>
      <TextField
        label="Address"
        placeholder="Enter an address"
        variant="outlined"
        inputRef={materialRef}
      />
      <Dialog open={existsDialogOpen} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>Previously Analyzed</DialogTitle>
        <DialogContent>
          <div>
            <p>
              You have previously analyzed this property. Please decide how you would like to
              proceed.
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setExistsDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/redtech/${existsDialogId}`);
            }}
            color="secondary">
            View Previous
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomGeocoder;
