import React, { Component } from 'react';

class SingleSlider extends Component {
  render() {
    return (
      <div className="home-each-slide">
        <div
          style={{
            backgroundImage: `url(${this.props.projectImage})`,
            backgroundPosition: 'center'
          }}></div>
      </div>
    );
  }
}

export default SingleSlider;
