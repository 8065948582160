import React, { Component } from 'react';

import Header from '../components/common/header';
import PrivacyPolicyText from '../components/common/pp';

class PrivacyPolicy extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="container">
          <PrivacyPolicyText />
        </div>
      </>
    );
  }
}

export default PrivacyPolicy;
