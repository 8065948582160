import React, { Component } from 'react';

import Header from '../components/common/header';

import AdminService from '../services/admin.service';

class ApplicableZones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cityData: []
    };
  }

  componentDidMount = async () => {
    var cityData = [];
    const cities = [
      '0eb77cb6-1a83-48d6-a879-778a1320e749',
      '2b309139-9077-487e-97e3-86861947dda8',
      '9718a1bc-988f-4c01-80ff-e6a493e75842'
    ];
    for (let i = 0; i < cities.length; i++) {
      const city = cities[i];
      const cityInfo = await AdminService.getCityById(city);
      const zones = await AdminService.getZonesByCityId(city);
      cityData[i] = { cityInfo, zones };
    }

    this.setState({ cityData: cityData });

    console.log(this.state.cityData);
  };

  render() {
    const allZones = this.state.cityData.map((city) => (
      <div>
        <h1 key={city.cityInfo.data.id}>
          {city.cityInfo.data.name}, {city.cityInfo.data.state}
        </h1>
        <ul className="zones-list">
          {city.zones.data.map((zone) => (
            <li key={zone.id}>{zone.name}</li>
          ))}
        </ul>
      </div>
    ));

    return (
      <>
        {this.state.cityData && (
          <>
            <Header />
            <div className="container">{allZones}</div>
          </>
        )}
      </>
    );
  }
}

export default ApplicableZones;
