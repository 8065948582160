// import necessary modules and components
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/common/header';
import AuthService from '../services/auth.service';
import AdminService from '../services/admin.service';

import { useSnackbarStore, useUserStore } from '../store';

export default function VerifyEmail() {
  const { token } = useParams();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbarStore();
  const update = useUserStore((state) => state.update);
  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const accountInfo = useUserStore((state) => state.accountInfo);
  useEffect(() => {
    if (token) {
      AuthService.verifyEmail(token).then((response) => {
        if (response.success === true) {
          showSnackbar(response.message, 'success', 3000);
          if (isLoggedIn) {
            AdminService.getUserInfo(accountInfo.id).then((res) => {
              update(res.data);
            });
            navigate('/dashboard');
          }
        } else {
          showSnackbar(response.message, 'error', 3000);
        }
      });
    }
  }, [token, navigate, showSnackbar, isLoggedIn, accountInfo, update]);

  return (
    <>
      <Header />
    </>
  );
}
