import axios from 'axios';

import { API_URL } from '../config';

class AuthService {
  register(firstName, lastName, email, password, occupation, host) {
    return axios
      .post(API_URL + 'auth/signup', {
        firstName,
        lastName,
        email,
        password,
        occupation,
        host
      })
      .then((response) => {
        return response.data;
      });
  }

  login(email, password) {
    return axios.post(API_URL + 'auth/signin', { email, password }).then((response) => {
      return response.data;
    });
  }

  googleLogin(access_token) {
    return axios.post(API_URL + 'auth/google-authenticate', { access_token }).then((response) => {
      return response.data;
    });
  }

  sendResetLink(host, email) {
    return axios
      .post(API_URL + 'auth/forgot-password', {
        host,
        email
      })
      .then((response) => {
        return response.data;
      });
  }

  resetPassword(token, password) {
    return axios
      .post(API_URL + 'auth/reset-password/' + token, {
        password
      })
      .then((response) => {
        return response.data;
      });
  }

  verifyEmail(token) {
    return axios.post(API_URL + 'auth/email/verify/' + token).then((response) => {
      return response.data;
    });
  }
}
// eslint-disable-next-line
export default new AuthService();
