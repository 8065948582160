import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
//import FolderIcon from '@mui/icons-material/Folder';
//import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ListIcon from '@mui/icons-material/List';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Button from '@mui/material/Button';

import { Link } from 'react-router-dom';
import { Search } from '@mui/icons-material';

export default function IconMenu(props) {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    if (menuOpen) {
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }
  };

  if (window.innerWidth < 960) {
    return (
      <div className="dashboard-menu">
        <Button
          style={{ marginTop: '10px', color: '#fff' }}
          component={Link}
          to={'/search'}
          color="secondary"
          variant="contained"
          startIcon={<Search />}
          fullWidth>
          Start New Search
        </Button>

        {!menuOpen && (
          <Button
            style={{ marginTop: '10px' }}
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={handleMenuClick}>
            Menu <KeyboardArrowDownIcon />
          </Button>
        )}

        {menuOpen && (
          <>
            <Button
              style={{ marginTop: '10px' }}
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={handleMenuClick}>
              Menu <KeyboardArrowUpIcon />
            </Button>
            <MenuList>
              <Divider />
              <p>My Content</p>
              <MenuItem>
                <ListItemIcon>
                  <PersonIcon fontSize="small" />
                </ListItemIcon>

                <ListItemText onClick={() => props.handleMenuClick('account')}>
                  Account Info
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <ListIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText onClick={() => props.handleMenuClick('underwritings')}>
                  My Underwritings
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <Search fontSize="small" />
                </ListItemIcon>
                <ListItemText onClick={() => props.handleMenuClick('collections')}>
                  My Collections
                </ListItemText>
              </MenuItem>
              <Divider />
              <p>Resources</p>
              <MenuItem>
                <ListItemText>
                  <Link to={'/news'}>Curated News</Link>
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText>
                  <Link to={'/products'}>Product Line Descriptions</Link>
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText>
                  <Link to={'/applicable-zones'}>View Zoning List</Link>
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText>
                  <Link to={'/help'}>Help</Link>
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText>
                  <Link color="secondary" to={'/terms-and-conditions'}>
                    Terms and Conditions
                  </Link>
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText>
                  <Link to={'/privacy-policy'}>Privacy Policy</Link>
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText>
                  <Link to={'/download'}>Get Mobile App</Link>
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText>
                  <Link to={'/contact'}>Contact Us</Link>
                </ListItemText>
              </MenuItem>
            </MenuList>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="dashboard-menu">
        <Button
          style={{ marginTop: '10px', color: '#fff' }}
          startIcon={<Search />}
          component={Link}
          to={'/search'}
          color="secondary"
          variant="contained"
          fullWidth>
          Start New Search
        </Button>
        <MenuList>
          <Divider />
          <p>My Content</p>
          <MenuItem>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText onClick={() => props.handleMenuClick('account')}>
              Account Info
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <ListIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={() => props.handleMenuClick('underwritings')}>
              My Underwritings
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <Search fontSize="small" />
            </ListItemIcon>
            <ListItemText onClick={() => props.handleMenuClick('collections')}>
              My Collections
            </ListItemText>
          </MenuItem>
          <Divider />
          <p>Resources</p>
          <MenuItem>
            <ListItemText>
              <Link to={'/news'}>Curated News</Link>
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>
              <Link to={'/products'}>Product Descriptions</Link>
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>
              <Link to={'/applicable-zones'}>View Zoning List</Link>
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>
              <Link to={'/terms-and-conditions'}>Terms and Conditions</Link>
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>
              <Link to={'/privacy-policy'}>Privacy Policy</Link>
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>
              <Link to={'/download'}>Get Mobile App</Link>
            </ListItemText>
          </MenuItem>
        </MenuList>
      </div>
    );
  }
}
