import React, { useRef, useState, useEffect } from 'react';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';

import axios from 'axios';

import ParcelMap from '../shared/parcelMapSatellite';
import LocationMap from '../shared/locationMap';
import StreetViewMap from '../shared/streetViewMap';

import Header from '../../common/header';

import { HexColorPicker } from 'react-colorful';
import { FileUploader } from 'react-drag-drop-files';

import { Box, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ArrowLeft, ExpandMore } from '@mui/icons-material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonBiking, faPersonWalking, faBus } from '@fortawesome/free-solid-svg-icons';

export default function OmPDf(props) {
  const pdfContentRef = useRef(null);

  const downloadPDF = () => {
    html2canvas(document.getElementById('parcelMap'), {
      useCORS: true,
      onrendered: function (canvas) {
        var img = canvas.toDataURL('image/png');
        img = img.replace('data:image/png;base64,', '');
        var finalImageSrc = 'data:image/png;base64,' + img;
        '#googlemapbinary'.attr('src', finalImageSrc);
      }
    });

    html2canvas(document.getElementById('locationMap'), {
      useCORS: true,
      onrendered: function (canvas) {
        var img = canvas.toDataURL('image/png');
        img = img.replace('data:image/png;base64,', '');
        var finalImageSrc = 'data:image/png;base64,' + img;
        '#googlemapbinary'.attr('src', finalImageSrc);
      }
    });

    const options = {
      filename: 'pdf-preview.pdf',
      image: { type: 'jpg', quality: 0.98 },
      html2canvas: {
        scale: 2,
        useCORS: true
      },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    html2pdf().set(options).from(pdfContentRef.current).save();

    const metadata = {
      queryId: props.queryId,
      address: props.data.query.address
    };

    window.Intercom('trackEvent', 'download-om', metadata);
  };

  console.log(props);

  const fileTypes = ['JPG', 'PNG'];
  // eslint-disable-next-line no-unused-vars
  const [logo, setLogo] = useState('/images/mainLogo.png');
  // eslint-disable-next-line no-unused-vars
  const [footerLogo, setFooterLogo] = useState('/images/footerLogo.png');
  // eslint-disable-next-line no-unused-vars
  const [fullBackground, setFullBackground] = useState(true);
  const [primaryColor, setPrimaryColor] = useState('#092423');
  const [secondaryColor, setSecondaryColor] = useState('#32b576');
  const [footerColor, setFooterColor] = useState('#ffffff');
  const [footerFontColor, setFooterFontColor] = useState('#000000');

  const styles = {
    title: {
      fontSize: '32px',
      fontWeight: '900',
      textAlign: 'right',
      margin: 0,
      padding: '35px 35px 0 35px',
      color: fullBackground ? '#fff' : '#000'
    },
    subtitle: {
      fontSize: '18px',
      textAlign: 'right',
      padding: '0 35px 0 35px',
      margin: 0,
      color: fullBackground ? '#fff' : '#000'
    },
    highlightedBold: {
      color: secondaryColor,
      fontWeight: '900'
    },
    highlighted: {
      color: secondaryColor
    },
    mainLogo: {
      position: 'absolute',
      bottom: 30,
      left: 30
    },
    footer: {
      container: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '8.5in',
        padding: '25px 35px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: footerColor,
        color: footerFontColor,
        alignItems: 'center',
        boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)'
      },
      right: { flexBasis: '33%', justifyContent: 'flex-end', textAlign: 'right' },
      left: { flexBasis: '33%', justifyContent: 'flex-start' },
      numberContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px'
      },
      numberWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column'
      },
      number: {
        fontSize: '18px',
        fontWeight: '900'
      }
    },
    pageHeader: {
      wrapper: { position: 'absolute', top: 55, left: 0, width: '8.5in' },
      container: { padding: '15px 15px 15px 35px', width: '75%', backgroundColor: primaryColor },
      title: { color: '#fff', fontSize: '24px', fontWeight: '900', margin: 0 }
    },
    fourColumn: {
      container: {
        position: 'absolute',
        top: '4.75in',
        left: '1.75in',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '5in'
      },
      container2: {
        position: 'absolute',
        top: '1.75in',
        left: '1.75in',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '5in'
      },
      item: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        top: {
          fontSize: '22px',
          fontWeight: '900'
        },
        bottom: {
          fontSize: '16px',
          fontWeight: '400'
        }
      }
    },
    opportunity: {
      container: {
        position: 'absolute',
        top: '5.5in',
        left: '.75in',
        width: '7in'
      }
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {/* 

        <Box sx={{ flexBasis: '50%', padding: '30px' }}>
          <Button onClick={() => props.setOmOpen(false)} startIcon={<ArrowLeft />}>
            Return to Underwriting
          </Button>
          <Box>
            <h1>Options</h1>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '10px' }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <h2>Colors</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}>
                      <Box>
                        <p>Primary Color</p>
                        <HexColorPicker color={primaryColor} onChange={setPrimaryColor} />
                      </Box>
                      <Box>
                        <p>Secondary Color</p>
                        <HexColorPicker color={secondaryColor} onChange={setSecondaryColor} />
                      </Box>
                      <Box>
                        <p>Footer Background Color</p>
                        <HexColorPicker color={footerColor} onChange={setFooterColor} />
                      </Box>
                      <Box>
                        <p>Footer Font Color</p>
                        <HexColorPicker color={footerFontColor} onChange={setFooterFontColor} />
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <h2>Logos</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}>
                      <Box>
                        <p>Main Logo</p>
                        <FileUploader
                          handleChange={(file) => console.log(file)}
                          name="file"
                          types={fileTypes}
                        />
                      </Box>
                      <Box>
                        <p>Footer Logo</p>
                        <FileUploader
                          handleChange={(file) => console.log(file)}
                          name="file"
                          types={fileTypes}
                        />
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <h2>Misc</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>

          <Button onClick={downloadPDF} variant="contained" color="secondary">
            Download PDF
          </Button>
        </Box>
        
        */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '10px'
          }}>
          <Button onClick={downloadPDF} variant="contained" color="secondary">
            Download PDF
          </Button>
        </Box>
        <Box
          sx={{
            height: '60vh',
            flexBasis: '100%',
            overflowY: 'scroll'
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px',
              backgroundColor: '#999',
              fontFamily: 'Lato, sans-serif'
            }}>
            <div ref={pdfContentRef}>
              <div
                style={{
                  width: '8.5in',
                  minHeight: '11in',
                  backgroundColor: 'white',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                  position: 'relative',
                  background: fullBackground
                    ? 'url(/images/1mobile.jpg) no-repeat center center'
                    : 'none'
                }}>
                <p style={styles.title}>Offering Memorandum</p>
                <p style={styles.subtitle}>{props.data.query.address}</p>
                <div style={styles.mainLogo}>
                  <img
                    style={{ width: '250px', zIndex: '10', marginLeft: '50px' }}
                    src={logo}
                    alt="logo"
                  />
                </div>
              </div>
              <div
                id="element-to-hide"
                data-html2canvas-ignore="true"
                style={{ height: '10px' }}></div>
              <div
                style={{
                  width: '8.5in',
                  minHeight: '11in',
                  backgroundColor: 'white',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                  position: 'relative'
                }}>
                <Box
                  sx={{
                    width: '7in',
                    height: '3in',
                    position: 'absolute',
                    top: '0.5in',
                    left: '0.75in'
                  }}>
                  <ParcelMap id="parcelMap" parcel={props.data.query.parcel} />
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '3.75in',
                    left: '1.25in',
                    width: '8.5in'
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '6in',
                      padding: '20px',
                      backgroundColor: secondaryColor
                    }}>
                    <span style={styles.pageHeader.title}>INVESTMENT OPPORTUNITY</span>
                  </Box>
                </Box>
                <Box sx={styles.fourColumn.container}>
                  <Box sx={styles.fourColumn.item}>
                    <span style={styles.fourColumn.item.top}>
                      {props.data.architecture.grossBuildable.toLocaleString()}
                    </span>
                    <span style={styles.fourColumn.item.bottom}>Building Size</span>
                  </Box>
                  <Box sx={styles.fourColumn.item}>
                    <span style={styles.fourColumn.item.top}>
                      {props.data.architecture.numberUnits}
                    </span>
                    <span style={styles.fourColumn.item.bottom}>Total Units</span>
                  </Box>
                  <Box sx={styles.fourColumn.item}>
                    <span style={styles.fourColumn.item.top}>
                      {props.data.operations.yoc.toFixed(2)} %
                    </span>
                    <span style={styles.fourColumn.item.bottom}>Yield On Cost</span>
                  </Box>
                </Box>
                <Box sx={styles.opportunity.container}>
                  <p>
                    {props.user.firstName + ' ' + props.user.lastName} is pleased to present an
                    investment opportunity for a {props.data.architecture.numberUnits} unit
                    residential development using{' '}
                    <span style={styles.highlightedBold}>Madelon's Multi-family Product Line</span>.
                    The development area for this lot is approximately{' '}
                    <span style={styles.highlighted}>
                      {Math.round(props.data.query.parcel.size).toLocaleString()} sq ft
                    </span>
                    .
                  </p>
                  <p>
                    A feasibility study of the property potential has been performed using Madelon's
                    Multi-family product line. The study offers a variety of unit mixes with
                    standardized housing modules suitable to be manufactured off-site cutting
                    development timelines by up to 50% so you have a building ready to be rented in
                    a shorter period of time.
                  </p>
                  <p>
                    The unit mix includes studios, one, and two bedroom units and accommodates other
                    accessory uses such as ground floor parking for vehicles and bicycles, a
                    finished roof deck, and other storage rooms.
                  </p>
                </Box>
                <Box sx={styles.footer.container}>
                  <p style={styles.footer.left}>
                    <Box sx={styles.footer.numberContainer}>
                      <span style={styles.footer.number}>02</span>
                      <Box sx={styles.footer.numberWrapper}>
                        <span style={styles.footer.number}>REDtech.app</span>
                        <span>Streamlining Development</span>
                      </Box>
                    </Box>
                  </p>
                  <img style={{ width: '100px', zIndex: '10' }} src={footerLogo} alt="logo" />
                  {props.user.firstName && props.user.lastName && props.user.email && (
                    <p style={styles.footer.right}>
                      {props.user.firstName + ' ' + props.user.lastName}
                      <br />
                      {props.user.email}
                    </p>
                  )}
                </Box>
              </div>
              <div
                id="element-to-hide"
                data-html2canvas-ignore="true"
                style={{ height: '10px' }}></div>
              <div
                style={{
                  width: '8.5in',
                  minHeight: '11in',
                  backgroundColor: 'white',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                  position: 'relative'
                }}>
                <Box sx={styles.pageHeader.wrapper}>
                  <Box sx={styles.pageHeader.container}>
                    <span style={styles.pageHeader.title}>PROPERTY INFORMATION</span>
                  </Box>
                </Box>

                <Box
                  sx={{
                    position: 'absolute',
                    top: '1.75in',
                    left: '1.25in',
                    width: '6in'
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: '10px 0px 10px 0px',
                      borderBottom: '2px dotted #000000'
                    }}>
                    <Box>Address</Box>
                    <Box>{props.data.query.address}</Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: '10px 0px 10px 0px',
                      borderBottom: '2px dotted #000000'
                    }}>
                    <Box>Zoning</Box>
                    <Box>{props.data.query.parcel.zone_name}</Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: '10px 0px 10px 0px',
                      borderBottom: '2px dotted #000000'
                    }}>
                    <Box>Lot Dimensions</Box>
                    <Box>
                      {props.data.query.parcel.width_num}' x {props.data.query.parcel.depth_num}'
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: '10px 0px 10px 0px',
                      borderBottom: '2px dotted #000000'
                    }}>
                    <Box>Lot Size</Box>
                    <Box>{Math.round(props.data.query.parcel.size).toLocaleString()} sq ft</Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: '10px 0px 10px 0px',
                      borderBottom: '2px dotted #000000'
                    }}>
                    <Box>TOC</Box>
                    <Box>{props.data.query.parcel.toc_tier || 'N/A'}</Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: '10px 0px 10px 0px',
                      borderBottom: '2px dotted #000000'
                    }}>
                    <Box>FAR</Box>
                    <Box>{props.data.zone.far || 'N/A'}</Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: '10px 0px 10px 0px',
                      borderBottom: '2px dotted #000000'
                    }}>
                    <Box>Max Height</Box>
                    <Box>{props.data.zone.maxHeight || 'N/A'}'</Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: '10px 0px 10px 0px',
                      borderBottom: '2px dotted #000000'
                    }}>
                    <Box>Max Buildable</Box>
                    <Box>
                      {Math.round(props.data.architecture.maxBuildable).toLocaleString()} sq ft
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: '10px 0px 10px 0px',
                      borderBottom: '2px dotted #000000'
                    }}>
                    <Box>Max Dwelling Units*</Box>
                    <Box>{props.data.architecture.numberUnits}</Box>
                  </Box>
                  <p>*With incentives provided by zoning code</p>
                </Box>

                <Box
                  sx={{
                    width: '7in',
                    height: '3in',
                    position: 'absolute',
                    bottom: '1.75in',
                    left: '.75in'
                  }}></Box>
                <Box sx={styles.footer.container}>
                  <p style={styles.footer.left}>
                    <Box sx={styles.footer.numberContainer}>
                      <span style={styles.footer.number}>03</span>
                      <Box sx={styles.footer.numberWrapper}>
                        <span style={styles.footer.number}>REDtech.app</span>
                        <span>Streamlining Development</span>
                      </Box>
                    </Box>
                  </p>
                  <img style={{ width: '100px', zIndex: '10' }} src={footerLogo} alt="logo" />
                  {props.user.firstName && props.user.lastName && props.user.email && (
                    <p style={styles.footer.right}>
                      {props.user.firstName + ' ' + props.user.lastName}
                      <br />
                      {props.user.email}
                    </p>
                  )}
                </Box>
              </div>
              <div
                id="element-to-hide"
                data-html2canvas-ignore="true"
                style={{ height: '10px' }}></div>
              <div
                style={{
                  width: '8.5in',
                  minHeight: '11in',
                  backgroundColor: 'white',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                  position: 'relative'
                }}>
                <Box sx={styles.pageHeader.wrapper}>
                  <Box sx={styles.pageHeader.container}>
                    <span style={styles.pageHeader.title}>NEIGHBORHOOD DESCRIPTION</span>
                  </Box>
                </Box>
                <Box sx={{ position: 'absolute', top: '1.5in', width: '7in', left: '.75in' }}>
                  {' '}
                  <p>{props.data.query.parcel.neighborhood_description}</p>
                </Box>

                <Box
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: '4in',
                    width: '8.5in',
                    height: '5in'
                  }}></Box>

                <Box sx={styles.footer.container}>
                  <p style={styles.footer.left}>
                    <Box sx={styles.footer.numberContainer}>
                      <span style={styles.footer.number}>04</span>
                      <Box sx={styles.footer.numberWrapper}>
                        <span style={styles.footer.number}>REDtech.app</span>
                        <span>Streamlining Development</span>
                      </Box>
                    </Box>
                  </p>
                  <img style={{ width: '100px', zIndex: '10' }} src={footerLogo} alt="logo" />
                  {props.user.firstName && props.user.lastName && props.user.email && (
                    <p style={styles.footer.right}>
                      {props.user.firstName + ' ' + props.user.lastName}
                      <br />
                      {props.user.email}
                    </p>
                  )}
                </Box>
              </div>
              <div
                id="element-to-hide"
                data-html2canvas-ignore="true"
                style={{ height: '10px' }}></div>
              <div
                style={{
                  width: '8.5in',
                  minHeight: '11in',
                  backgroundColor: 'white',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                  position: 'relative'
                }}>
                <Box sx={styles.pageHeader.wrapper}>
                  <Box sx={styles.pageHeader.container}>
                    <span style={styles.pageHeader.title}>LOCATION OVERVIEW</span>
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '1.75in',
                    left: '.75in',
                    width: '7in',
                    height: '3in'
                  }}>
                  <LocationMap id="locationMap" parcel={props.data.query.parcel} />
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '5in',
                    left: '.75in'
                  }}>
                  <Box
                    sx={{
                      margin: '20px 0',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '30px',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}>
                    <Box sx={{ width: '30px' }}>
                      <FontAwesomeIcon icon={faPersonWalking} size={'xl'} color={secondaryColor} />
                    </Box>
                    <Box>
                      <p style={{ margin: 0, padding: 0, fontSize: '16px', fontWeight: 900 }}>
                        Walk Score
                      </p>
                      <p style={{ margin: 0, padding: 0 }}>
                        {props.data.query.parcel.walk_score[0] || '--'}/100
                      </p>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: '20px 0',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '30px',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}>
                    <Box sx={{ width: '30px' }}>
                      <FontAwesomeIcon icon={faPersonBiking} size={'xl'} color={secondaryColor} />
                    </Box>
                    <Box>
                      <p style={{ margin: 0, padding: 0, fontSize: '16px', fontWeight: 900 }}>
                        Bike Score
                      </p>
                      <p style={{ margin: 0, padding: 0 }}>
                        {props.data.query.parcel.walk_score[1] || '--'}/100
                      </p>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: '20px 0',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '30px',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}>
                    <Box sx={{ width: '30px' }}>
                      <FontAwesomeIcon icon={faBus} size={'xl'} color={secondaryColor} />
                    </Box>
                    <Box>
                      <p style={{ margin: 0, padding: 0, fontSize: '16px', fontWeight: 900 }}>
                        Transit Score
                      </p>
                      <p style={{ margin: 0, padding: 0 }}>
                        {props.data.query.parcel.walk_score[2] || '--'}/100
                      </p>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '5in',
                    left: '3.5in',
                    paddingRight: '.75in'
                  }}>
                  <p>{props.data.query.parcel.transit_info}</p>
                </Box>
                <Box sx={styles.footer.container}>
                  <p style={styles.footer.left}>
                    <Box sx={styles.footer.numberContainer}>
                      <span style={styles.footer.number}>05</span>
                      <Box sx={styles.footer.numberWrapper}>
                        <span style={styles.footer.number}>REDtech.app</span>
                        <span>Streamlining Development</span>
                      </Box>
                    </Box>
                  </p>
                  <img style={{ width: '100px', zIndex: '10' }} src={footerLogo} alt="logo" />
                  {props.user.firstName && props.user.lastName && props.user.email && (
                    <p style={styles.footer.right}>
                      {props.user.firstName + ' ' + props.user.lastName}
                      <br />
                      {props.user.email}
                    </p>
                  )}
                </Box>
              </div>
              <div
                id="element-to-hide"
                data-html2canvas-ignore="true"
                style={{ height: '10px' }}></div>
              <div
                style={{
                  width: '8.5in',
                  minHeight: '11in',
                  backgroundColor: 'white',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                  position: 'relative'
                }}>
                <Box sx={styles.pageHeader.wrapper}>
                  <Box sx={styles.pageHeader.container}>
                    <span style={styles.pageHeader.title}>DEVELOPMENT POTENTIAL</span>
                  </Box>
                </Box>
                <Box sx={styles.fourColumn.container2}>
                  <Box sx={styles.fourColumn.item}>
                    <span style={styles.fourColumn.item.top}>TDC</span>
                    <span style={styles.fourColumn.item.bottom}>
                      $ {Math.round(props.data.operations.totalCost).toLocaleString()}
                    </span>
                  </Box>
                  <Box sx={styles.fourColumn.item}>
                    <span style={styles.fourColumn.item.top}>YOC</span>
                    <span style={styles.fourColumn.item.bottom}>
                      {props.data.operations.yoc.toFixed(2)} %
                    </span>
                  </Box>
                  <Box sx={styles.fourColumn.item}>
                    <span style={styles.fourColumn.item.top}>NOI</span>
                    <span style={styles.fourColumn.item.bottom}>
                      $ {Math.round(props.data.operations.noi).toLocaleString()}
                    </span>
                  </Box>
                  <Box sx={styles.fourColumn.item}>
                    <span style={styles.fourColumn.item.top}>CAP</span>
                    <span style={styles.fourColumn.item.bottom}>
                      {props.data.operations.exitCap * 100} %
                    </span>
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    width: '8.5in',
                    top: '2.5in',
                    textAlign: 'center',
                    padding: '0px 1in 0px 1in',
                    fontSize: '18px'
                  }}>
                  <p>
                    The subject property presents a great opportunity to do a modular multi-family
                    building using Madelon's standardized housing product.
                  </p>
                </Box>
                <Box sx={{ position: 'absolute', top: '3.5in', width: '7in', left: '.75in' }}>
                  <p style={{ fontSize: '1.5em', fontWeight: 900, margin: 0, padding: 0 }}>
                    Market Analysis
                  </p>
                  <p>{props.data.query.parcel.market_info}</p>
                </Box>
                <Box sx={styles.footer.container}>
                  <p style={styles.footer.left}>
                    <Box sx={styles.footer.numberContainer}>
                      <span style={styles.footer.number}>06</span>
                      <Box sx={styles.footer.numberWrapper}>
                        <span style={styles.footer.number}>REDtech.app</span>
                        <span>Streamlining Development</span>
                      </Box>
                    </Box>
                  </p>
                  <img style={{ width: '100px', zIndex: '10' }} src={footerLogo} alt="logo" />
                  {props.user.firstName && props.user.lastName && props.user.email && (
                    <p style={styles.footer.right}>
                      {props.user.firstName + ' ' + props.user.lastName}
                      <br />
                      {props.user.email}
                    </p>
                  )}
                </Box>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
}
