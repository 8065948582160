import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import Map, { Source, Layer, NavigationControl } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';

import { MAPBOX_KEY } from '../../../config';
import { buildingLayer } from '../../common/map-styles.js';

import { FormControlLabel, Switch } from '@mui/material';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export default function AdminSearchMap(props) {
  const hideZoomLevel = 12;

  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11');
  const [satChecked, setSatChecked] = useState(false);

  const parcelLayerOutlined = {
    id: 'parcelLayerOutlined',
    filter: ['!', ['has', 'point_count']],
    type: 'fill',
    paint: {
      'fill-color': ['match', ['get', 'listingStatus'], 1, '#eb4e32', '#EEB902'],
      'fill-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        hideZoomLevel,
        0,
        hideZoomLevel + 0.001,
        0.25
      ]
    }
  };

  const parcelLayerOutlinedLine = {
    id: 'parcelLayerOutlinedLine',
    filter: ['!', ['has', 'point_count']],
    type: 'line',
    paint: {
      'line-color': ['match', ['get', 'listingStatus'], 1, '#eb4e32', '#EEB902'],
      'line-width': 2,
      'line-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        hideZoomLevel,
        0,
        hideZoomLevel + 0.001,
        1
      ]
    }
  };

  const clusterLayer = {
    id: 'clusters',
    type: 'circle',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      'circle-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        hideZoomLevel,
        1,
        hideZoomLevel + 0.001,
        0
      ]
    }
  };

  const clusterCountLayer = {
    id: 'cluster-count',
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12
    },
    paint: {
      'text-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        hideZoomLevel,
        1,
        hideZoomLevel + 0.001,
        0
      ]
    }
  };

  const onHover = useCallback((event) => {
    const { features } = event;
    const hoveredFeature = features && features[0];
    if (hoveredFeature) {
      props.onHover(hoveredFeature);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
  const onClick = useCallback((event) => {
    const { features } = event;
    const clickedFeature = features && features[0];
    if (clickedFeature) {
      props.onParcelFirstClick(clickedFeature);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  */

  const [viewport, setViewport] = useState({ ...props.viewport });
  const [zoomLevel, setZoomLevel] = useState(4);
  const [interactiveLayerIds, setInteractiveLayerIds] = useState([]);
  const [parcelGeoJson, setParcelGeoJSON] = useState(props.parcelsGeo);

  useEffect(() => {
    setParcelGeoJSON(props.parcelsGeo);
  }, [props.parcelsGeo, props.updateMap]);

  useEffect(() => {
    setViewport({ ...props.viewport });
  }, [props.viewport]);

  useEffect(() => {
    if (props.parcelsGeo.features.length > 0) {
      if (zoomLevel > 12) {
        setInteractiveLayerIds(['parcelLayerOutlined']);
      }
      if (zoomLevel <= 12) {
        setInteractiveLayerIds([]);
      }
    }
  }, [props.parcelsGeo, zoomLevel]);

  const toggleSatChecked = () => {
    if (satChecked === true) {
      setMapStyle('mapbox://styles/mapbox/streets-v11');
      setSatChecked(false);
    } else {
      setMapStyle('mapbox://styles/mapbox/satellite-streets-v11');
      setSatChecked(true);
    }
  };

  return (
    <>
      <Map
        {...viewport}
        zoom={zoomLevel}
        onMove={() => setViewport()}
        onZoom={(e) => setZoomLevel(e.viewState.zoom)}
        width={'100%'}
        height={'100%'}
        mapStyle={mapStyle}
        mapboxAccessToken={MAPBOX_KEY}
        cursor="pointer"
        dragRotate={true}
        touchRotate={true}
        scrollZoom={true}
        touchZoom={true}
        interactiveLayerIds={interactiveLayerIds}
        onMouseMove={onHover}>
        <NavigationControl position="top-left" />
        {props.parcelsGeo.features.length > 0 && (
          <>
            <Source
              id="cluser"
              type="geojson"
              data={props.pointsGeo}
              cluster={true}
              clusterMaxZoom={12}
              clusterRadius={50}>
              <Layer {...clusterLayer} />
              <Layer {...clusterCountLayer} />
            </Source>
            <Source id="parcels" type="geojson" data={parcelGeoJson}>
              <Layer {...parcelLayerOutlined} />
              <Layer {...parcelLayerOutlinedLine} />
            </Source>
          </>
        )}
        <Layer {...buildingLayer} />
      </Map>
      <FormControlLabel
        control={<Switch checked={satChecked} onChange={toggleSatChecked} />}
        label="Satellite"
      />
    </>
  );
}
