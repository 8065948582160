import React, { Component } from 'react';

import Header from '../components/common/header';

import LeadsService from '../services/leads.service';

import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      message: '',
      dialogOpen: false
    };
  }

  handleEmailAddressChange = (event) => {
    const emailAddress = event.target.value;
    this.setState({
      emailAddress: emailAddress
    });
  };

  handleMessageChange = (event) => {
    const message = event.target.value;

    this.setState({
      message: message
    });
  };

  handleSendEmail = async () => {
    const subject = 'Madelon REDtech Contact Form';
    const subject2 = `Madelon REDtech Contact Form - ${this.state.emailAddress}`;
    const message = `<p>Hi ${this.state.emailAddress},</p>
        <p>Thank you for you message!</p>
        <p>Be on the look out for futher communication from us.</p>
        <p>Team Madelon</p>
        <p><img src="https://redtech.app/images/logo.png" width="150px" alt="redtech logo" />
        </p>`;
    await LeadsService.sendEmail(
      'hello@madelongroup.com',
      this.state.emailAddress,
      subject,
      message
    );
    await LeadsService.sendEmail(
      'hello@madelongroup.com',
      'hello@madelongroup.com',
      subject2,
      this.state.message
    );

    this.setState({ emailAddress: '', message: '', dialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    return (
      <>
        <Header />
        <div className="contact-container">
          <h1>Contact Us</h1>
          <Grid container spacing={12}>
            <Grid item xs={12} md={12}>
              <h2>Get In Touch</h2>
              <p>
                If you have any issues with the REDtech platform or the Mobile application please
                contact us <a href="mailto:hello@madelongroup.com">hello@madelongroup.com</a>, give
                us a call at <a href="tel:3474645556">347-464-5556</a>, or use the form below:
              </p>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    autoComplete="email"
                    fullWidth
                    label="E-mail address"
                    required
                    type="email"
                    value={this.state.emailAddress}
                    variant="outlined"
                    InputLabelProps={{ required: true }}
                    onChange={this.handleEmailAddressChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    autoComplete="message"
                    fullWidth
                    multiline
                    rows={8}
                    label="Message"
                    required
                    type="message"
                    value={this.state.message}
                    variant="outlined"
                    InputLabelProps={{ required: true }}
                    onChange={this.handleMessageChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    disabled={!this.state.emailAddress || !this.state.message}
                    color="primary"
                    variant="contained"
                    onClick={this.handleSendEmail}>
                    Send
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Dialog open={this.state.dialogOpen} onClose={this.handleCloseDialog}>
            <DialogTitle id="form-dialog-title">Success</DialogTitle>
            <DialogContent>Thank you for your message!</DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDialog} variant="outlined" color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }
}

export default Contact;
