import React, { useState } from 'react';
import validate from 'validate.js';

import {
  Grid,
  TextField,
  Checkbox,
  Button,
  Alert,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

import constraints from '../../../data/constraints';
import TermsAndConditionsText from '../../common/tandc';

import AuthService from '../../../services/auth.service';

import { useUserStore, useSnackbarStore } from '../../../store';

const initialState = {
  performingAction: false,
  emailAddress: '',
  password: '',
  passwordConfirmation: '',
  occupation: '',
  errors: null,
  redirect: null,
  checked: false,
  openDialog: false,
  alertOpen: false,
  message: null
};

const SignUpForm = (props) => {
  const [state, setState] = useState(initialState);
  const [message, setMessage] = useState(null);

  const login = useUserStore((state) => state.login);
  const { showSnackbar } = useSnackbarStore();

  const signUp = async () => {
    const { firstName, lastName, emailAddress, password, passwordConfirmation, occupation } = state;

    const errors = validate(
      {
        emailAddress: emailAddress,
        password: password,
        passwordConfirmation: passwordConfirmation,
        occupation: occupation
      },
      {
        emailAddress: constraints.emailAddress,
        password: constraints.password,
        passwordConfirmation: constraints.passwordConfirmation,
        occupation: constraints.occupation
      }
    );

    if (errors) {
      setState({ ...state, errors: errors });
    } else {
      setState({ ...state, performingAction: true, errors: null });

      await AuthService.register(
        firstName,
        lastName,
        emailAddress,
        password,
        occupation,
        window.location.hostname
      ).then((response) => {
        setMessage(response);
        setState({ ...state, successful: true });
      });

      await AuthService.login(emailAddress, password)
        .then((response) => {
          if (response.accessToken !== null) {
            login(response);
            props.handleStatus(false);
            showSnackbar(response.message, 'success', 3000);
          } else {
            showSnackbar(response.message, 'error', 5000);
          }
        })
        .catch(() => {});
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleAccept = (event) => {
    setState({ ...state, checked: event.target.checked, openDialog: true });
  };

  const handleCloseDialog = () => {
    setState({ ...state, openDialog: false });
  };

  const {
    performingAction,
    firstName,
    lastName,
    emailAddress,
    password,
    passwordConfirmation,
    occupation,
    checked,
    errors
  } = state;

  return (
    <div className="form-container">
      <Grid container direction="column">
        <Grid item xs={12} md={4}>
          <h3>Create Account</h3>
          <p>
            Sign up now and get a <strong>7 day free trial</strong> for Full Underwritings, Project
            Customization, Offering Memorandums, and more.
          </p>
          {message && (
            <div className="alerts">
              <Alert severity={message.severity}>{message.message}</Alert>
            </div>
          )}
          <TextField
            autoComplete="given-name"
            disabled={performingAction}
            error={!!(errors && errors.firstName)}
            fullWidth
            helperText={errors && errors.firstName ? errors.firstName[0] : ''}
            label="First Name"
            required
            value={firstName}
            variant="outlined"
            InputLabelProps={{ required: true }}
            name="firstName"
            onChange={handleChange}
          />
          <TextField
            autoComplete="family-name"
            disabled={performingAction}
            error={!!(errors && errors.lastName)}
            fullWidth
            helperText={errors && errors.lastName ? errors.lastName[0] : ''}
            label="Last Name"
            required
            value={lastName}
            variant="outlined"
            InputLabelProps={{ required: true }}
            name="lastName"
            onChange={handleChange}
          />
          <TextField
            autoComplete="email"
            disabled={performingAction}
            error={!!(errors && errors.emailAddress)}
            fullWidth
            helperText={errors && errors.emailAddress ? errors.emailAddress[0] : ''}
            label="E-mail address"
            required
            type="email"
            value={emailAddress}
            variant="outlined"
            InputLabelProps={{ required: true }}
            name="emailAddress"
            onChange={handleChange}
          />
          <TextField
            autoComplete="new-password"
            disabled={performingAction}
            error={!!(errors && errors.password)}
            fullWidth
            helperText={errors && errors.password ? errors.password[0] : ''}
            label="Password"
            required
            type="password"
            value={password}
            variant="outlined"
            InputLabelProps={{ required: true }}
            name="password"
            onChange={handleChange}
          />
          <TextField
            autoComplete="password"
            disabled={performingAction}
            error={!!(errors && errors.passwordConfirmation)}
            fullWidth
            helperText={errors && errors.passwordConfirmation ? errors.passwordConfirmation[0] : ''}
            label="Password Confirmation"
            required
            type="password"
            value={passwordConfirmation}
            variant="outlined"
            InputLabelProps={{ required: true }}
            name="passwordConfirmation"
            onChange={handleChange}
          />
          <FormControl fullWidth variant="outlined">
            <InputLabel id="occupation-label">I am a</InputLabel>
            <Select
              labelId="occupation-label"
              id="occupation"
              value={occupation}
              onChange={handleChange}
              label="occupation"
              name="occupation">
              <MenuItem value={'investor'}>Investor</MenuItem>
              <MenuItem value={'developer'}>Developer</MenuItem>
              <MenuItem value={'broker'}>Broker</MenuItem>
              <MenuItem value={'other'}>Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <FormControlLabel
          value="end"
          control={<Checkbox color="secondary" checked={checked} onChange={handleAccept} />}
          label="I certify am over the age of 18. I agree to the terms and conditions."
          labelPlacement="end"
        />
        <Button
          disabled={
            !firstName ||
            !lastName ||
            !emailAddress ||
            !password ||
            !passwordConfirmation ||
            !occupation ||
            !checked ||
            performingAction
          }
          color="secondary"
          variant="contained"
          onClick={signUp}>
          Sign up
        </Button>
        <Grid item xs={12}>
          <p>
            Already have an account?{' '}
            <Button color="secondary" onClick={() => props.setType('signin')}>
              Sign In
            </Button>
          </p>
        </Grid>
        <Dialog open={state.openDialog} onClose={handleCloseDialog}>
          <DialogTitle id="terms-and-conditions">Terms and Conditions</DialogTitle>
          <DialogContent>
            <TermsAndConditionsText />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} variant="contained" color="secondary">
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
};

export default SignUpForm;
