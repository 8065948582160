import React, { Component } from 'react';

import Header from '../components/common/header';
import TermsAndConditionsText from '../components/common/tandc';

class TermsAndConditions extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="container">
          <TermsAndConditionsText />
        </div>
      </>
    );
  }
}

export default TermsAndConditions;
