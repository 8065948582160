import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, MarkerClusterer, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const CollectionMapGoogle = (props) => {
  const [mapInstance, setMapInstance] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY
  });

  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  const onLoad = useCallback((map) => {
    setMapInstance(map);
  }, []);

  const parcels = props.parcels;

  useEffect(() => {
    if (isLoaded && mapInstance && parcels) {
      const bounds = new window.google.maps.LatLngBounds();

      parcels.forEach((feature) => {
        bounds.extend(new window.google.maps.LatLng(feature.latitude, feature.longitude));
      });

      mapInstance.fitBounds(bounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, mapInstance]);

  const onZoomChanged = React.useCallback(
    function callback() {
      if (isLoaded) {
        if (mapInstance.getZoom() > 14) {
          mapInstance.data.addGeoJson(props.parcelsGeo);

          mapInstance.data.setStyle({
            fillColor: '#EEB902',
            strokeWeight: 1
          });

          mapInstance.data.addListener('click', function (event) {
            mapInstance.data.revertStyle();
            mapInstance.data.overrideStyle(event.feature, { fillColor: 'red' });
            props.onHover(event.feature);
            console.log(event.feature);
            setCenter({
              lat: parseFloat(event.feature.h.latitude),
              lng: parseFloat(event.feature.h.longitude)
            });
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapInstance, props]
  );

  return isLoaded ? (
    <>
      <GoogleMap
        onLoad={onLoad}
        onZoomChanged={onZoomChanged}
        mapContainerStyle={containerStyle}
        zoom={11}
        center={center}
        options={{
          gestureHandling: 'greedy'
        }}>
        <>
          <MarkerClusterer minimumClusterSize={3}>
            {(clusterer) =>
              props.parcels.map((parcel) => (
                <Marker
                  key={parcel.id}
                  position={{ lat: parseFloat(parcel.latitude), lng: parseFloat(parcel.longitude) }}
                  clusterer={clusterer}
                  visible={false}
                />
              ))
            }
          </MarkerClusterer>
        </>
      </GoogleMap>
    </>
  ) : (
    <></>
  );
};

export default CollectionMapGoogle;
