import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useSnackbarStore } from '../../store';

const Notifications = () => {
  const { open, message, severity, duration, closeSnackbar } = useSnackbarStore();

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={closeSnackbar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert onClose={closeSnackbar} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notifications;
