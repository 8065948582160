import React, { Component } from 'react';

import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { Dialog } from '@capacitor/dialog';

import AdminService from '../../services/admin.service';

import logoHeader from '../../files/images/logoWhite.png';

import { Box } from '@mui/material';

import GitInfo from 'react-git-info/macro';
import Moment from 'react-moment';

const gitInfo = GitInfo();

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appInfo: null
    };
  }

  componentDidMount = async () => {
    if (Capacitor.getPlatform() === 'ios') {
      const appVersionRequired = await AdminService.fetchAppRequirements();
      const appInfo = await App.getInfo();
      if (appInfo.version !== appVersionRequired.data.ios.version) {
        this.showConfirm(appVersionRequired.data);
      }
      this.setState({ appInfo: appInfo });
    }
  };

  showConfirm = async (data) => {
    const { value } = await Dialog.confirm({
      title: 'Update',
      message: `An app update is required.`,
      okButtonTitle: 'Update'
    });

    if (value === true) {
      window.location.replace(data.ios.updateUrl);
    } else {
      await Dialog.alert({
        title: 'Outdated',
        message: `We cannot guarantee the accuracy of the information provided without updating the app.`,
        okButtonTitle: 'Ok'
      });
    }
  };

  render() {
    return (
      <footer>
        <Box
          sx={{
            margin: '0 auto',
            width: { xs: '100%', md: '70%' },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: { xs: '20px', md: '0px' }
          }}>
          <Box>
            <img className="footer-logo" src={logoHeader} alt="logo" />
            <p style={{ textAlign: 'right' }}>
              v3.1.0 - {gitInfo.commit.shortHash} <br />
              <Moment format="DD MMM YYYY">{gitInfo.commit.date}</Moment>
            </p>
          </Box>
          <Box>
            <p>Useful Links</p>
            <ul>
              <li>
                <a href="/search">New Search</a>
              </li>
              <li>
                <a href="/dashboard">My Dashboard</a>
              </li>
            </ul>
          </Box>
          <Box>
            <p>Contact</p>
            <ul>
              <li>
                <a href="mailto:hello@madelongroup.com">hello@madelongroup.com</a>
              </li>
            </ul>
          </Box>
          <Box>
            <p>Resources</p>
            <ul>
              <li>
                <a href="/terms-and-conditions">Terms and Conditions</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
            </ul>
          </Box>
          {this.state.appInfo !== null && <p>v{this.state.appInfo.version}</p>}
        </Box>
      </footer>
    );
  }
}

export default Footer;
