import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import AdminService from '../../services/admin.service';

import { Box, Card, Button, Select, MenuItem } from '@mui/material';

import HomepageCard from './homepageCard';
import CustomGeocoder from '../search/customGeocoder';

import { useJsApiLoader } from '@react-google-maps/api';
const libraries = ['places'];

export default function HomepageSelector(props) {
  const [step, setStep] = useState(0);
  const [userType, setUserType] = useState('');
  const [searchType, setSearchType] = useState('');

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(10);

  let navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: libraries
  });

  useEffect(() => {
    async function getCities() {
      const cities = await AdminService.getAllCities().then((response) => {
        return response.data
          .filter((city) => city.citykey !== null)
          .filter((city) => city.include === true || city.comingSoon === true)
          .sort((a, b) => a.name.localeCompare(b.name));
      });
      setCities(cities);
    }
    getCities();
  }, []);

  const handleCitySelect = (event) => {
    setSelectedCity(event.target.value);
    navigate(`search?city=${event.target.value}`);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        paddingTop: { xs: '50px', md: '0px' }
      }}>
      <Box>
        <p>
          <span style={{ fontWeight: 900, fontSize: '1.5em' }}>
            Let the REDtech platform help you.
          </span>
        </p>
      </Box>
      {step === 0 && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              marginTop: '50px',
              marginBottom: '50px',
              textAlign: 'center',
              gap: { xs: '20px', md: '10%' }
            }}>
            <Box sx={{ flex: 1, alignSelf: 'stretch' }}>
              <p style={{ fontSize: '2em', fontWeight: '700' }}>Land Brokers</p>
              <p>White labeled Offering Memorandums</p>
              <p>
                Stop spending hours creating OMs. Simply type in the listing address, sale price,
                add your company logo, and you’re done.
              </p>
              <Button
                sx={{ marginTop: '25px' }}
                color="secondary"
                variant="contained"
                onClick={() => {
                  setStep(1);
                  setUserType('broker');
                  setSearchType('address');
                }}>
                Generate Resources
              </Button>
            </Box>
            <Box sx={{ flex: 1, alignSelf: 'stretch' }}>
              <p style={{ fontSize: '2em', fontWeight: '700' }}>Ground-Up Developers</p>
              <p>Find, analyze, and build your perfect project</p>
              <p>
                Don’t spend thousands analyzing sites & hiring architects. Underwrite on REDtech and
                order a feasibility study for a fraction of the cost.
              </p>
              <Button
                sx={{ marginTop: '25px' }}
                color="secondary"
                variant="contained"
                onClick={() => {
                  setStep(1);
                  setUserType('developer');
                }}>
                Find a Project
              </Button>
            </Box>
          </Box>
          <Box sx={{ marginTop: '100px', flex: 1, textAlign: 'center' }}>
            <p style={{ fontSize: '2em', fontWeight: '700' }}>Con-Tech Partners</p>
            <p>
              If you're interested in becoming a partner with REDtech, please click the button below
              to learn
            </p>
            <Button color="secondary" variant="contained" onClick={() => navigate('/partners')}>
              Partner Onboarding
            </Button>
          </Box>
        </>
      )}
      {step === 1 && (
        <>
          <Button variant="outlined" color="secondary" onClick={() => setStep(step - 1)}>
            Return to Previous
          </Button>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: { xs: '20px', md: '40px' },
              marginTop: '50px',
              marginBottom: '50px'
            }}>
            {userType === 'developer' && (
              <>
                <Box
                  onClick={() => {
                    setStep(2);
                    setSearchType('map');
                  }}>
                  <HomepageCard
                    front="Find a Site"
                    back="Find the perfect parcel using our search tool"
                  />
                </Box>
                <Box
                  onClick={() => {
                    setStep(2);
                    setSearchType('address');
                  }}>
                  <HomepageCard
                    front="I Have a Site"
                    back="Analyze your parcel by entering an address"
                  />
                </Box>
              </>
            )}
            {userType === 'broker' && searchType === 'address' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: { xs: '20px', md: '40px' },
                  marginTop: '50px',
                  height: '250px'
                }}>
                <Card sx={{ height: '250px', textAlign: 'center', padding: '20px' }}>
                  <p style={{ fontSize: '1em' }}>
                    Enter the listing address and get everything you need in seconds
                  </p>
                  {isLoaded && <CustomGeocoder isLoaded={isLoaded} />}
                </Card>
              </Box>
            )}
          </Box>
        </>
      )}
      {step === 2 && (
        <>
          <Button variant="outlined" color="secondary" onClick={() => setStep(step - 1)}>
            Return to Previous
          </Button>
          {searchType === 'map' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: '20px', md: '40px' },
                marginTop: '50px',
                marginBottom: '50px',
                height: '250px'
              }}>
              <Card sx={{ height: '250px', textAlign: 'center', padding: '20px' }}>
                <p style={{ fontSize: '1em' }}>Choose a city to get started searching</p>
                {cities && (
                  <Select
                    sx={{ width: '100%' }}
                    label="City"
                    onChange={(e) => handleCitySelect(e)}
                    value={selectedCity}
                    defaultValue={10}>
                    <MenuItem key={'none'} value={10}>
                      Select a City
                    </MenuItem>
                    {cities.map((city) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}, {city.state}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                <p style={{ fontSize: '0.8em' }}>Dont see the city you're looking for?</p>
                <Button color="secondary" variant="outlined">
                  Contact Us
                </Button>
              </Card>
            </Box>
          )}
          {searchType === 'address' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: '20px', md: '40px' },
                marginTop: '50px',
                height: '250px'
              }}>
              <Card sx={{ height: '200px', textAlign: 'center', padding: '20px' }}>
                <p style={{ fontSize: '1em' }}>Enter address</p>
                <CustomGeocoder />
              </Card>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
