import React, { useState } from 'react';

import validate from 'validate.js';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import constraints from '../../../data/constraints';

import AuthService from '../../../services/auth.service';

import { useSnackbarStore } from '../../../store';

const ResetPasswordForm = (props) => {
  const [performingAction, setPerformingAction] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errors, setErrors] = useState(null);

  const [requestNew, setRequestNew] = useState(false);
  const [email, setEmail] = useState('');

  const { showSnackbar } = useSnackbarStore();

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setPerformingAction(true);

    AuthService.sendResetLink(window.location.hostname, email)
      .then((response) => {
        showSnackbar(response.message, 'warning', 5000);
        setPerformingAction(false);
      })
      .catch(() => {
        setPerformingAction(false);
      });
  };

  const resetPassword = () => {
    const validationErrors = validate(
      {
        password: password,
        passwordConfirmation: passwordConfirmation
      },
      {
        password: constraints.password,
        passwordConfirmation: constraints.passwordConfirmation
      }
    );

    if (validationErrors) {
      setErrors(validationErrors);
    } else {
      setPerformingAction(true);
      setErrors(null);

      const segment_str = window.location.pathname;
      const segment_array = segment_str.split('/');
      const token = segment_array.pop();

      AuthService.resetPassword(token, password).then((response) => {
        showSnackbar(response.message, response.severity, 3000);
        setPerformingAction(false);
        if (response.success) {
          props.setType('signin');
          props.setStatus(true);
        } else {
          setRequestNew(true);
        }
      });
    }
  };

  return (
    <div className="form-container">
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12} md={4}>
          {!requestNew && (
            <>
              <h3>Reset Password</h3>
              <TextField
                autoComplete="new-password"
                disabled={performingAction}
                error={!!(errors && errors.password)}
                fullWidth
                helperText={errors && errors.password ? errors.password[0] : ''}
                label="Password"
                required
                type="password"
                value={password}
                variant="outlined"
                InputLabelProps={{ required: true }}
                onChange={(event) => setPassword(event.target.value)}
              />
              <TextField
                autoComplete="password"
                disabled={performingAction}
                error={!!(errors && errors.passwordConfirmation)}
                fullWidth
                helperText={
                  errors && errors.passwordConfirmation ? errors.passwordConfirmation[0] : ''
                }
                label="Password Confirmation"
                required
                type="password"
                value={passwordConfirmation}
                variant="outlined"
                InputLabelProps={{ required: true }}
                onChange={(event) => setPasswordConfirmation(event.target.value)}
              />
              <Button
                disabled={!password || !passwordConfirmation || performingAction}
                color="secondary"
                variant="contained"
                onClick={resetPassword}>
                Change Password
              </Button>
            </>
          )}
          {requestNew && (
            <>
              <h3>Request New Link</h3>
              <TextField
                autoComplete="email"
                disabled={performingAction}
                error={!!(errors && errors.passwordConfirmation)}
                fullWidth
                label="Email Address"
                required
                type="password"
                value={email}
                variant="outlined"
                InputLabelProps={{ required: true }}
                onChange={(event) => setEmail(event.target.value)}
              />
              <Button
                disabled={!email}
                color="secondary"
                variant="contained"
                onClick={handleForgotPassword}>
                Request New Password Reset Link
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPasswordForm;
