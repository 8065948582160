import React, { Component } from 'react';

import { Slide } from 'react-slideshow-image';

import SingleSlider from './singleSliderHome';

import project0 from '../../../files/images/home/0.jpg';
import project1 from '../../../files/images/home/1.jpg';
import project2 from '../../../files/images/home/2.jpg';
import project3 from '../../../files/images/home/3.jpg';

import project0m from '../../../files/images/home/0mobile.jpg';
import project1m from '../../../files/images/home/1mobile.jpg';
import project2m from '../../../files/images/home/2mobile.jpg';

import 'react-slideshow-image/dist/styles.css';

class Slider extends Component {
  render() {
    return (
      <div>
        {window.innerWidth >= 768 && (
          <Slide easing="ease">
            <SingleSlider projectImage={project0} />
            <SingleSlider projectImage={project1} />
            <SingleSlider projectImage={project2} />
            <SingleSlider projectImage={project3} />
          </Slide>
        )}
        {window.innerWidth < 768 && (
          <Slide easing="ease">
            <SingleSlider projectImage={project0m} />
            <SingleSlider projectImage={project1m} />
            <SingleSlider projectImage={project2m} />
          </Slide>
        )}
      </div>
    );
  }
}

export default Slider;
