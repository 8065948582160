import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, MarkerClusterer, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%'
};

function AdvancedSearchMapGoogle(props) {
  const [center, setCenter] = useState({
    lat: props.viewport.latitude,
    lng: props.viewport.longitude
  });

  useEffect(() => {
    if (props.parcels.length === 0) {
      setCenter({
        lat: props.viewport.latitude,
        lng: props.viewport.longitude
      });
    }
  }, [props.viewport, props.parcels]);

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(mapInstance) {
    setMap(mapInstance);
  }, []);

  const parcels = props.parcels;

  useEffect(() => {
    if (props.isLoaded && map && parcels) {
      const bounds = new window.google.maps.LatLngBounds();

      if (parcels.length > 0) {
        parcels.forEach((feature) => {
          bounds.extend(new window.google.maps.LatLng(feature.latitude, feature.longitude));
        });

        map.fitBounds(bounds);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isLoaded, map, parcels]);

  useEffect(() => {
    if (map && map.data) {
      map.data.forEach(function (feature) {
        map.data.remove(feature);
      });
    }
  }, [map, props.parcelsGeo]);

  useEffect(() => {
    const handleHoverZip = (v) => props.zipcodeHover(v);

    if (map && props.zipcodeFeatures.features.length !== 0) {
      map.data.addGeoJson(props.zipcodeFeatures);

      map.data.setStyle({
        fillOpacity: 0,
        strokeWeight: 1
      });

      map.data.addListener('mouseover', function (event) {
        if (
          event.feature.getProperty('zcta') &&
          !props.selectedZipcodes.includes(event.feature.getProperty('zipcode'))
        ) {
          map.data.overrideStyle(event.feature, {
            fillColor: '#eb4e32',
            fillOpacity: 1
          });
        }
      });

      map.data.addListener('mouseout', function (event) {
        if (
          event.feature.getProperty('zcta') &&
          !props.selectedZipcodes.includes(event.feature.getProperty('zipcode'))
        ) {
          map.data.revertStyle();
        }
      });

      map.data.addListener('click', function (event) {
        if (
          event.feature.getProperty('zcta') &&
          !props.selectedZipcodes.includes(event.feature.getProperty('zipcode'))
        ) {
          map.data.overrideStyle(event.feature, {
            fillColor: '#eb4e32',
            fillOpacity: 1
          });
          handleHoverZip(event.feature.getProperty('zipcode'));
        }
        if (event.feature.getProperty('zcta') === undefined) {
          map.data.overrideStyle(event.feature, { fillColor: '#eb4e32' });
          props.onHover(event.feature);
        }
      });

      map.data.addGeoJson(props.parcelsGeo);

      map.data.forEach(function (feature) {
        if (
          feature.getProperty('zcta') &&
          props.selectedZipcodes.includes(feature.getProperty('zipcode'))
        ) {
          map.data.remove(feature);
        }
      });

      map.data.setStyle(function (feature) {
        if (feature.getProperty('zcta')) {
          return {
            fillOpacity: 0,
            strokeWeight: 1
          };
        } else {
          return {
            fillColor: '#EEB902',
            fillOpacity: 0.5,
            strokeWeight: 1
          };
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, props.zipcodeFeatures, props.parcelsGeo]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const [mapBounds, setMapBounds] = useState(null);

  const handleZoomChanged = () => {
    if (map) {
      const bounds = map.getBounds();
      setMapBounds(bounds);
    }
  };

  const handleDragEnd = () => {
    if (map) {
      const bounds = map.getBounds();
      setMapBounds(bounds);
    }
  };

  return props.isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={11}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onZoomChanged={handleZoomChanged}
        onDragEnd={handleDragEnd}
        options={{
          gestureHandling: 'greedy',
          tilt: 0
        }}>
        <MarkerClusterer
          minimumClusterSize={props.parcels.length / 20 > 5 ? props.parcels.length / 20 : 5}>
          {(clusterer) =>
            props.parcels.map((parcel) => (
              <Marker
                key={parcel.id}
                position={{ lat: parseFloat(parcel.latitude), lng: parseFloat(parcel.longitude) }}
                clusterer={clusterer}
                visible={false}
              />
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default React.memo(AdvancedSearchMapGoogle);
