import React, { Component } from 'react';

import Header from '../components/common/header';

import { Grid } from '@mui/material';

class Help extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="container">
          <h1>Help</h1>
          <Grid container spacing={12}>
            <Grid item xs={12} md={12}>
              <h2>FAQs</h2>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default Help;
