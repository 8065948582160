import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import logo from '../../files/images/logoWhiteHome.png';

import { Box, Button } from '@mui/material';
import { HashLink } from 'react-router-hash-link';

export default function HomepageSplash(props) {
  let navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: { xs: '20px', md: '40px' },
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        height: '100%',
        paddingTop: { xs: '50px', md: '0px' }
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: '30px', md: '60px' },
          alignItems: { xs: 'center', md: 'flex-start' },
          justifyContent: 'center'
        }}>
        <Box sx={{ width: '25%', minWidth: '300px' }}>
          <img style={{ width: '100%' }} src={logo} alt="logo" />
        </Box>
        <Box sx={{ width: '25%', minWidth: '300px' }}>
          <Box>
            <p style={{ padding: 0, margin: '0 0 40px 0', fontWeight: 900, fontSize: '2.5em' }}>
              REDtech helps you find and analyze real estate projects in seconds.
            </p>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                const element = document.getElementById('selector');
                console.log(element);
                if (element) {
                  // 👇 Will scroll smoothly to the top of the next section
                  element.scrollIntoView({ behavior: 'smooth' });
                }
              }}>
              Try for Free
            </Button>
            <p style={{ margin: 0, padding: 0 }}>*no credit card required</p>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
