// import necessary modules and components
import React, { useEffect, useState } from 'react';
import Header from '../components/common/header';
import Moment from 'react-moment';
import AdminService from '../services/admin.service';
import { Box, Chip } from '@mui/material';

// define functional component
export default function News() {
  // define state variables
  const [news, setNews] = useState(null); // news items
  const [searchTerms, setSearchTerms] = useState(['housing']); // search terms

  // use effect hook to fetch news items when search terms change
  useEffect(() => {
    // define function to fetch news items
    async function fetchNews() {
      // concatenate search terms into a single string
      const searchTermsString = searchTerms.join('+');
      // make API call to get news items
      const response = await AdminService.getNews(searchTermsString);
      // update state variable with news items
      setNews(response.data);
    }
    // call fetchNews function
    fetchNews();
  }, [searchTerms]);

  // render loading message if news items have not yet been fetched
  if (!news) {
    return (
      <>
        <Header />
        <h1>Loading</h1>
      </>
    );
  }

  // render news items and search term chips
  return (
    <>
      <Header />
      <Box sx={{ padding: '15px 5%' }}>
        <Box sx={{ display: 'flex', width: '100%', minHeight: '100vh', flexWrap: 'wrap' }}>
          {/* render search term chips */}
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: '5px' }}>
            {['housing', 'affordable', 'denver', 'newyork', 'losangeles'].map((term) => (
              <Chip
                key={term}
                label={term}
                color="secondary"
                variant={searchTerms.includes(term) ? 'default' : 'outlined'}
                onClick={() => setSearchTerms([...searchTerms, term])}
                onDelete={() => setSearchTerms(searchTerms.filter((item) => item !== term))}
              />
            ))}
          </Box>
          {/* render news items */}
          <Box sx={{ width: '100%' }}>
            <h1>News</h1>
            {news.items.map((item) => (
              <div style={{ paddingTop: 5, paddingBottom: 5 }} key={item.guid}>
                <a target="_blank" rel="noreferrer" href={item.link}>
                  <p style={{ margin: 0, padding: 0, fontSize: '1.6em', fontWeight: 600 }}>
                    {item.title}
                  </p>
                </a>
                <p style={{ margin: 0, padding: 0 }}>
                  {/* display publication date using Moment library */}
                  <Moment date={item.pubDate} format="MM/DD hh:mm" /> -{' '}
                  <Moment fromNow ago>
                    {item.pubDate}
                  </Moment>{' '}
                  ago
                </p>
              </div>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
}
