import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { useUserStore, useSnackbarStore } from '../../store';

import Iframe from 'react-iframe';

import { Device } from '@capacitor/device';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  Paper,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardMedia,
  Drawer,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Slider,
  Tab,
  Switch,
  FormControlLabel
} from '@mui/material';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

import { Edit, ChevronRight } from '@mui/icons-material';

import { TabContext, TabList, TabPanel } from '@mui/lab';

import ScriptTag from 'react-script-tag';

//common components
import Header from '../common/header';
import LoadingSpinner from '../common/loadingSpinner';
import ParcelMap from './shared/parcelMap';

import lowriseLoadingGif from '../../files/images/lowrise/lowriseLoading.gif';
import RedtechOptions from './util/redtechOptions';
import AdminService from '../../services/admin.service';
//import CheckoutForm from '../payment/checkoutForm';

import OmPDFv2 from './files/omPDFv2';
import Authentication from '../user/authentication';

export default function RedtechTool(props) {
  const accountInfo = useUserStore((state) => state.accountInfo);
  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const update = useUserStore((state) => state.update);

  const navigate = useNavigate();

  const [marginTop, setMarginTop] = useState('64px');

  useEffect(() => {
    const fetchDeviceInfo = async () => {
      const info = await Device.getInfo();
      if (info.platform === 'ios') {
        setMarginTop('89px');
      }
    };
    fetchDeviceInfo();
  }, []);

  const [signinDialogOpen, setSigninDialogOpen] = useState(false);

  const [omOpen, setOmOpen] = useState(false);

  const [tabValue, setTabValue] = useState('1');

  const [analysisColor, setAnalysisColor] = useState('black');

  const [loading, setLoading] = useState(true);
  const [softLoading, setSoftLoading] = useState(false);
  const [allData, setAllData] = useState(null);

  const [stacksourceURL, setStacksourceURL] = useState(null);

  const [dialogOpen, setDialogOpen] = useState({ open: false });
  const [drawerOpen, setDrawerOpen] = useState(true);

  const [activeStep, setActiveStep] = useState(1);
  const [fullAccess, setFullAccess] = useState(false);

  const [redtechOptions, setRedtechOptions] = useState({
    sliderChanged: null,
    studioNumber: null,
    oneBedNumber: null,
    twoBedNumber: null,
    flexmf: false
  });

  const [userData, setUserData] = useState({
    cost: null,
    productLine: null,
    customUnitMix: null,
    customHardCost: null,
    selectedFinish: null,
    customEquityPercent: null,
    customAmiPercent: null,
    customRent: null,
    customOccupancy: null,
    customExitCap: null,
    customOpexPercent: null,
    customZoning: null,
    numberFloors: null,
    denEHA: false
  });

  const [studioNumber, setStudioNumber] = useState();
  const [oneBedNumber, setOneBedNumber] = useState();
  const [twoBedNumber, setTwoBedNumber] = useState();

  const [numberFloors, setNumberFloors] = useState();

  const [denEHA, setDenEHA] = useState(false);

  const [rentPerUnit, setRentPerUnit] = useState();
  const [amiPercent, setAmiPercent] = useState();
  const [opexPercent, setOpexPercent] = useState();
  const [occupancy, setOccupancy] = useState();

  const [hardCost, setHardCost] = useState();

  const [equityPercent, setEquityPercent] = useState();

  const handleEquityPercentChange = (event, newValue) => {
    setEquityPercent(newValue);
  };

  const [exitCap, setExitCap] = useState();

  const handleHardCostChange = (event, newValue) => {
    setHardCost(newValue);
  };

  const handleStudioChange = (event, newValue) => {
    setStudioNumber(newValue);
  };

  const handleOneBedChange = (event, newValue) => {
    setOneBedNumber(newValue);
  };

  const handleTwoBedChange = (event, newValue) => {
    setTwoBedNumber(newValue);
  };

  const paymentAmounts = {
    offeringMemorandum: { product: 'prod_NebssSYKFjKZRG', price: 'price_1MtIf7JCVk20B5nLCrnkujAr' },
    proforma: { product: 'prod_NfiD05KY8VCtsi', price: 'price_1MuMmeJCVk20B5nLVXHSGKJI' },
    fullAccess: { product: 'prod_Nebs4io9n5o4sZ', price: 'price_1MtIf1JCVk20B5nLatxPnRLT' },
    feasibilityStudy: { product: 'prod_NebsLilgMBpyIu', price: 'price_1MtIerJCVk20B5nLQw2d3nE7' }
  };

  let { queryId } = useParams();

  const [loadingStripe, setLoadingStripe] = useState(false);

  const handlePurchase = async (id) => {
    setLoadingStripe(true);
    await AdminService.createCheckoutSession(accountInfo, id, queryId, window.location.hostname);
    setLoadingStripe(false);
  };

  let queryParams = new URLSearchParams(window.location.search);
  const success = queryParams.get('success');

  const { showSnackbar } = useSnackbarStore();

  const [refetch, setRefetch] = useState(false);

  const [rezone, setRezone] = useState(false);

  const accountCreated = new Date(accountInfo.createdAt);

  const isAccountOlderThanSevenDays = () => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    return accountCreated.getTime() < sevenDaysAgo.getTime();
  };

  useEffect(() => {
    async function fetchData() {
      if (allData === null) {
        setLoading(true);
      } else {
        setSoftLoading(true);
      }

      const response = await AdminService.getQueryV2(queryId, userData, redtechOptions);

      if (response.data.rezone === true && allData === null) {
        setRezone(true);
        setDialogOpen({
          open: true,
          title: 'This property must be rezoned or select a different property.',
          content: (
            <Box>
              <p>
                You can select a zone from this list to rezone or return to search for a different
                property.
              </p>
              <FormControl fullWidth>
                <Select
                  onChange={(event) => {
                    handleUpdateUserData({
                      customZoning: event.target.value,
                      productLine: 'multifamily'
                    });
                    setRezone(false);
                    setLoading(true);
                    setDialogOpen({ open: false });
                  }}
                  value={null}>
                  {response.data.zoneList.map((zone) => (
                    <MenuItem key={zone.id} value={zone.id}>
                      {zone.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button color="secondary" variant="outlined" onClick={() => navigate('/search')}>
                Return to Search
              </Button>
            </Box>
          )
        });
        setLoading(false);
        setSoftLoading(false);
      } else {
        setStacksourceURL(
          `https://platform.stacksource.com/embed.js?referral-token=MadelonGroup&zip-code=${response.data.query.zipcode}&property-value=${response.data.operations.totalCost}&asset-type=multi_family&project-type=development&programType=bank&sizing=0.65`
        );

        setNumberFloors(response.data.architecture.numberFloors);
        setStudioNumber(response.data.architecture.numStu);
        setOneBedNumber(response.data.architecture.numOne);
        setTwoBedNumber(response.data.architecture.numTwo);
        setRentPerUnit(response.data.operations.rentPerUnit);
        setAmiPercent(response.data.operations.amiPercent);
        setOpexPercent(response.data.operations.opexPercent);
        setOccupancy(response.data.operations.occupancy);
        setHardCost(response.data.architecture.hardCostSQFT);
        setEquityPercent(response.data.operations.equityPercent);
        setExitCap(response.data.operations.exitCap);

        if (response.data.operations.stabilizedValue > response.data.operations.totalCost) {
          setAnalysisColor('green');
        } else {
          setAnalysisColor('red');
        }

        setAllData(response.data);

        if (
          response.data.query.products_purchased.includes(paymentAmounts.fullAccess.product) ||
          (isLoggedIn && accountInfo.roles.includes('ROLE_ADMIN'))
        ) {
          setFullAccess(true);
          setActiveStep(2);
        }

        var metadata = {
          queryId: response.data.query.id,
          address: response.data.query.address,
          zone: response.data.query.zone_name,
          grossConstruction: response.data.architecture.grossBuildable,
          units: response.data.architecture.numberUnits,
          averageRent: response.data.operations.rentPerUnit.toFixed(2),
          tdc: response.data.operations.totalCost.toLocaleString(),
          noi: response.data.operations.noi.toLocaleString(),
          yoc: response.data.operations.yoc.toFixed(2)
        };
        window.Intercom('trackEvent', 'underwriting-view', metadata);

        window.scrollTo(0, 0);

        setLoading(false);
        setSoftLoading(false);

        if (success === 'true') {
          showSnackbar('Payment Successful', 'success', 3000);
        } else if (success === 'false') {
          showSnackbar('Payment Failed', 'error', 3000);
        }
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match, queryId, userData, redtechOptions, omOpen, refetch]);

  const handleUpdateUserData = (value) => {
    setUserData({ ...userData, ...value });
  };

  const handleUpdateRedtechOptions = (value) => {
    setRedtechOptions({ ...redtechOptions, ...value });
  };

  const steps = [
    'Find Parcel',
    'Project Basics',
    'Full Underwriting and Customization',
    'Order Feasibility Study',
    'Submit RFPs to Project Partners'
  ];

  const handleStep = (index) => {
    if (index === 2) {
      setDrawerOpen(true);
    }
    if (index === 3) {
      setTabValue('8');
    }
    if (index === 4) {
      setTabValue('8');
    }
  };

  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginRight: 0,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }),
        marginRight: theme.breakpoints.up('lg') ? '25%' : '100%'
      })
    })
  );

  if (loading === true) {
    return (
      <div className="loading-spinner">
        <LoadingSpinner />
      </div>
    );
  } else {
    if (rezone !== true) {
      return (
        <>
          {softLoading === true && (
            <div className="soft-loading-spinner">
              <LoadingSpinner />
            </div>
          )}
          <Header />
          <div>
            {allData !== null && allData.query.parcel && allData.query && (
              <Main open={drawerOpen}>
                <div>
                  <Box
                    sx={{
                      width: '100%',
                      marginTop: '15px',
                      marginBottom: '15px',
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: { xs: '0 15px', lg: '0 50px' }
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        gap: '3px'
                      }}>
                      <h2 className="redtech-address">{allData.query.address}</h2>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: '10px',
                          fontWeight: '300',
                          fontColor: '#888'
                        }}>
                        RTID: {allData.query.parcel.id}
                      </p>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                        gap: '10px'
                      }}>
                      {((isLoggedIn && allData.query.userId === accountInfo.id) ||
                        (isLoggedIn && accountInfo.roles.includes('ROLE_ADMIN')) ||
                        queryId === `ae25b45c-8d0d-4101-85b9-0400e6ba210d`) &&
                        !drawerOpen && (
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => setDrawerOpen(true)}
                            startIcon={<Edit />}>
                            Customize Project
                          </Button>
                        )}
                    </Box>
                  </Box>
                  <Divider />
                </div>
                <Box sx={{ padding: '25px 0 0 0' }}>
                  <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                    {steps.map((label, index) => (
                      <Step key={label} completed={index < activeStep}>
                        <StepButton
                          onClick={() => handleStep(index)}
                          disabled={index <= activeStep}>
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                    marginBottom: '15px',
                    padding: { xs: '0 15px', lg: '0 50px' }
                  }}>
                  <Box
                    sx={{
                      flexBasis: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '15px',
                      gap: '15px'
                    }}>
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', md: 'row' },
                          textAlign: 'center',
                          gap: '10px'
                        }}>
                        <Box
                          sx={{
                            textAlign: 'center',
                            width: '100%',
                            margin: '0 auto'
                          }}>
                          <Card>
                            <p>Unit Count</p>
                            <h1 className={fullAccess === true ? '' : 'blur-text'}>
                              {allData.architecture.numberUnits}
                            </h1>
                          </Card>
                        </Box>
                        <Box
                          sx={{
                            textAlign: 'center',
                            width: '100%',
                            margin: '0 auto'
                          }}>
                          <Card>
                            <p>Total Built</p>
                            <h1>{allData.architecture.grossBuildable.toLocaleString()} sqft</h1>
                          </Card>
                        </Box>
                        <Box
                          sx={{
                            textAlign: 'center',
                            width: '100%',
                            margin: '0 auto'
                          }}>
                          <Card>
                            <p>Total Development Cost</p>
                            <h1>$ {allData.operations.totalCost.toLocaleString()}</h1>
                          </Card>
                        </Box>
                        <Box
                          sx={{
                            textAlign: 'center',
                            width: '100%',
                            margin: '0 auto'
                          }}>
                          <Card>
                            <p>Yield On Cost</p>
                            <h1>{allData.operations.yoc.toFixed(2)} %</h1>
                          </Card>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: '100%'
                      }}>
                      <TabContext value={tabValue}>
                        <TabList
                          value={tabValue}
                          onChange={(e, nv) => setTabValue(nv)}
                          aria-label="basic tabs example"
                          variant="scrollable"
                          scrollButtons={true}>
                          <Tab label="Development Details" value="1" />
                          <Tab label="Map" value="2" />
                          <Tab label="Exterior" value="3" />
                          <Tab label="Interior" value="4" />
                          <Tab label="Offering Memorandum" value="6" />
                          <Tab label="Proforma" value="7" />
                          <Tab label="Feasibility Study" value="8" />
                          <Tab label="Project Partners" value="9" />
                        </TabList>
                        <Divider />
                        <TabPanel value="6">
                          {isLoggedIn &&
                            (fullAccess ||
                              allData.query.products_purchased.includes(
                                paymentAmounts.offeringMemorandum.product
                              )) && (
                              <OmPDFv2
                                queryId={queryId}
                                data={allData}
                                user={accountInfo}
                                setOmOpen={setOmOpen}
                              />
                            )}
                          {isLoggedIn && !fullAccess && (
                            <>
                              <h1>Please Purchase Offering Memorandum</h1>
                              <Box sx={{ display: 'flex', gap: '15px' }}>
                                <Button
                                  color="secondary"
                                  variant="outlined"
                                  href="/files/examples/offering-memorandum.pdf"
                                  target="_blank">
                                  Download Example
                                </Button>
                                {isAccountOlderThanSevenDays() && (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                      handlePurchase(paymentAmounts.offeringMemorandum.price)
                                    }
                                    disabled={loadingStripe}>
                                    Purchase
                                  </Button>
                                )}
                              </Box>
                            </>
                          )}
                          {!isLoggedIn && (
                            <>
                              <h1>Please Signin To Access</h1>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setSigninDialogOpen(true)}>
                                Signin
                              </Button>
                            </>
                          )}
                        </TabPanel>
                        <TabPanel value="7">
                          {!isLoggedIn && (
                            <>
                              <h1>Please Signin To Access</h1>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setSigninDialogOpen(true)}>
                                Signin
                              </Button>
                            </>
                          )}
                          {isLoggedIn && <h1>Coming Soon</h1>}
                        </TabPanel>
                        <TabPanel value="8">
                          <>
                            {!isLoggedIn && (
                              <>
                                <h1>Please Signin To Access</h1>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => setSigninDialogOpen(true)}>
                                  Signin
                                </Button>
                              </>
                            )}
                            {isLoggedIn && (
                              <>
                                <h1>Feasibility Study</h1>
                                <Box>
                                  <p>
                                    Stay ahead of the competition with REDtech's Feasibility Study,
                                    thorough analysis of your property investment opportunities.
                                    With a 72-hour turnaround time, our team of experts delivers an
                                    in-depth assessment of the property, including property
                                    analysis, floor plans, financials, and more all to help you make
                                    informed decisions quickly and confidently.
                                  </p>
                                  <p>
                                    Key Features:
                                    <ul>
                                      <li>
                                        Rapid turnaround: Receive a comprehensive feasibility study
                                        within just 72 hours, ensuring you stay ahead in the
                                        fast-paced real estate market.
                                      </li>
                                      <li>
                                        Expert property analysis: Benefit from a detailed assessment
                                        of the property's current condition, potential improvements,
                                        and market positioning.
                                      </li>
                                      <li>
                                        Custom floor plans: Visualize the property's layout and
                                        potential modifications with professionally designed floor
                                        plans that cater to your specific requirements.
                                      </li>
                                      <li>
                                        Comprehensive financials: Review a complete financial
                                        analysis, including projected revenues, expenses, cash flow,
                                        and key investment metrics to help you evaluate the
                                        investment opportunity.
                                      </li>
                                      <li>
                                        Market insight: Leverage our in-depth knowledge of local
                                        market trends and comparable properties to accurately assess
                                        the property's potential value and performance.
                                      </li>
                                    </ul>
                                  </p>
                                  <Box sx={{ display: 'flex', gap: '15px' }}>
                                    <Button
                                      color="secondary"
                                      variant="outlined"
                                      href="/files/examples/feasibility-study.pdf"
                                      target="_blank">
                                      Download Example
                                    </Button>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      onClick={() =>
                                        handlePurchase(paymentAmounts.feasibilityStudy.price)
                                      }
                                      disabled={loadingStripe}>
                                      Purchase
                                    </Button>
                                  </Box>
                                </Box>
                                <p>
                                  A REDtech Feasibility Study is required to proceed with submission
                                  of RFPs (Request For Proposal) to project partners.
                                </p>
                              </>
                            )}
                          </>
                        </TabPanel>
                        <TabPanel value="9">
                          {!isLoggedIn && (
                            <>
                              <h1>Please Signin To Access</h1>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setSigninDialogOpen(true)}>
                                Signin
                              </Button>
                            </>
                          )}
                          {isLoggedIn && (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                flexWrap: 'wrap',
                                gap: '15px'
                              }}>
                              <Box sx={{ flex: 1 }}>
                                <Card sx={{ padding: '15px' }}>
                                  <h1 style={{ margin: 0, padding: 0 }}>Financing</h1>
                                  <CardMedia
                                    sx={{ height: 220, marginBottom: '15px' }}
                                    image="/images/stacksource.jpg"
                                    title="stacksource"
                                  />
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between'
                                    }}>
                                    <p>Stacksource</p>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      size="small"
                                      onClick={() =>
                                        setDialogOpen({
                                          open: true,
                                          title: 'Financing Options from StackSource',
                                          content: (
                                            <div className="financing-options">
                                              <ScriptTag
                                                type="text/javascript"
                                                src={stacksourceURL}
                                              />
                                            </div>
                                          )
                                        })
                                      }>
                                      View Live Rates
                                    </Button>
                                  </Box>
                                </Card>
                              </Box>
                              <Box sx={{ flex: 1 }}>
                                <Card sx={{ padding: '15px' }}>
                                  <h1 style={{ margin: 0, padding: 0 }}>Manufacturing</h1>
                                  <CardMedia
                                    sx={{ height: 220, marginBottom: '15px' }}
                                    image="/images/riseModular.jpeg"
                                    title="rise"
                                  />
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between'
                                    }}>
                                    <p>Rise</p>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      size="small"
                                      href="https://risemodular.com/"
                                      target="_blank">
                                      Learn More
                                    </Button>
                                  </Box>
                                </Card>
                              </Box>
                              <Box sx={{ flex: 1 }}>
                                <Card sx={{ padding: '15px' }}>
                                  <h1 style={{ margin: 0, padding: 0 }}>General Contracting</h1>
                                  <CardMedia
                                    sx={{ height: 220, marginBottom: '15px' }}
                                    image="/images/prosetModular.jpeg"
                                    title="proset"
                                  />
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between'
                                    }}>
                                    <p>Proset</p>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      size="small"
                                      href="https://www.prosetmodular.com/"
                                      target="_blank">
                                      Learn More
                                    </Button>
                                  </Box>
                                </Card>
                              </Box>
                              <Box sx={{ flex: 1 }}>
                                <Card sx={{ padding: '15px' }}>
                                  <h1 style={{ margin: 0, padding: 0 }}>Operations</h1>
                                  <CardMedia
                                    sx={{ height: 220, marginBottom: '15px' }}
                                    image="/images/jefferson2.jpg"
                                    title="maison"
                                  />
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between'
                                    }}>
                                    <p>Maison</p>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      size="small"
                                      href="https://maisonmeanshome.com/"
                                      target="_blank">
                                      Learn More
                                    </Button>
                                  </Box>
                                </Card>
                              </Box>
                            </Box>
                          )}
                        </TabPanel>
                        <TabPanel value="2">
                          <Box
                            sx={{
                              width: '100%',
                              height: '500px'
                            }}>
                            <ParcelMap parcel={allData.query.parcel} />
                          </Box>
                        </TabPanel>
                        <TabPanel value="3">
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '100%',
                              height: '500px',
                              margin: '0 auto'
                            }}>
                            {allData.query.productLine !== 'lowrise' && (
                              <img
                                style={{ margin: '0 auto', maxHeight: '500px' }}
                                src="/images/1.jpg"
                                alt="exterior"
                              />
                            )}
                            {allData.query.productLine === 'lowrise' && (
                              <img
                                style={{ margin: '0 auto', maxHeight: '500px' }}
                                src={lowriseLoadingGif}
                                alt="lowrise-loading"
                              />
                            )}
                          </Box>
                        </TabPanel>
                        <TabPanel value="4">
                          <Box
                            sx={{
                              height: '500px'
                            }}>
                            <Iframe
                              url={'https://renderings.madelongroup.com/multifamily/'}
                              width="100%"
                              height="500px"
                              id="threedvisualinterior"
                              frameBoarder="0"
                            />
                          </Box>
                        </TabPanel>
                        <TabPanel value="1">
                          <Box>
                            <TableContainer component={Paper}>
                              <Table aria-label="simple table">
                                <TableBody>
                                  <TableRow
                                    key={'parcel'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ fontSize: '1.3em', fontWeight: 900 }}>
                                      Parcel
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'zone_class'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Zone Class
                                    </TableCell>
                                    <TableCell align="right">
                                      {allData.query.parcel.zone_name}
                                    </TableCell>
                                  </TableRow>
                                  {allData.query.parcel.toc_tier !== null && (
                                    <TableRow
                                      key={'toc_tier'}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                      <TableCell component="th" scope="row">
                                        TOC Tier
                                      </TableCell>
                                      <TableCell align="right">
                                        {allData.query.parcel.toc_tier}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                  <TableRow
                                    key={'size'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Size
                                    </TableCell>
                                    <TableCell align="right">
                                      {allData.architecture.sqft.toLocaleString()} ft<sup>2</sup> (
                                      {allData.architecture.length}' x {allData.architecture.width}'
                                      ~LxW)
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'owner'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Owner
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={fullAccess === true ? '' : 'blur-text'}>
                                      {allData.query.parcel.owner_name}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'ops'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ fontSize: '1.3em', fontWeight: 900 }}>
                                      Operations
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'rent'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Rent/Unit/Month
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={fullAccess === true ? '' : 'blur-text'}>
                                      $ {allData.operations.rentPerUnit.toLocaleString()} (
                                      {(allData.operations.amiPercent.toFixed(2) * 100).toFixed(0)}%
                                      AMI)
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'revenue'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Revenue
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={fullAccess === true ? '' : 'blur-text'}>
                                      $ {allData.operations.grossRevenue.toLocaleString()}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'occupancy'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Occupancy
                                    </TableCell>
                                    <TableCell align="right">
                                      {allData.operations.occupancy * 100}%
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'revenue_occupancy'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Gross Revenue (Occupancy)
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={fullAccess === true ? '' : 'blur-text'}>
                                      $ {allData.operations.grossRevenueOccupancy.toLocaleString()}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'opex'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Operating Expenses
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={fullAccess === true ? '' : 'blur-text'}>
                                      $ {allData.operations.ox.toLocaleString()} (
                                      {allData.operations.opexPercent * 100}% of Revenue)
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'noi'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Net Operating Income
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={fullAccess === true ? '' : 'blur-text'}>
                                      $ {allData.operations.noi.toLocaleString()}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'architecture'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ fontSize: '1.3em', fontWeight: 900 }}>
                                      Architecture
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'units'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Unit Count (Total/Studio/One Bed/Two Bed)
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={fullAccess === true ? '' : 'blur-text'}>
                                      {allData.architecture.numberUnits} /{' '}
                                      {allData.architecture.numStu} / {allData.architecture.numOne}{' '}
                                      / {allData.architecture.numTwo}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'parking'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Parking Spaces Required
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className={fullAccess === true ? '' : 'blur-text'}>
                                      {allData.architecture.numberSpaces}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'floors'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Number of Floors
                                    </TableCell>
                                    <TableCell align="right">
                                      {allData.architecture.numberFloors}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'maxBuildable'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Max Buildable
                                    </TableCell>
                                    <TableCell align="right">
                                      {allData.architecture.maxBuildable.toLocaleString()} ft
                                      <sup>2</sup>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'leasable'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Leasable Area
                                    </TableCell>
                                    <TableCell align="right">
                                      {Math.round(
                                        allData.architecture.leasableArea
                                      ).toLocaleString()}{' '}
                                      ft
                                      <sup>2</sup>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'common'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Total Common Area
                                    </TableCell>
                                    <TableCell align="right">
                                      {allData.architecture.commonArea.toLocaleString()} ft
                                      <sup>2</sup>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'gross'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Gross Built
                                    </TableCell>
                                    <TableCell align="right">
                                      {allData.architecture.grossBuildable.toLocaleString()} ft
                                      <sup>2</sup>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'development'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ fontSize: '1.3em', fontWeight: 900 }}>
                                      Development
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'land'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Land Acquisition Cost
                                    </TableCell>
                                    <TableCell align="right">
                                      $ {allData.query.cost.toLocaleString()}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'construction'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Construction Cost
                                    </TableCell>
                                    <TableCell align="right">
                                      $ {allData.operations.constructionCost.toLocaleString()} ( ${' '}
                                      {allData.architecture.hardCostSQFT} per ft<sup>2</sup>)
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'tdc'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Total Development Cost
                                    </TableCell>
                                    <TableCell align="right">
                                      $ {allData.operations.totalCost.toLocaleString()}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'equity'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Equity Required
                                    </TableCell>
                                    <TableCell align="right">
                                      $ {allData.operations.equityRequired.toLocaleString()} (
                                      {(allData.operations.equityPercent * 100).toFixed(0)}% of TDC)
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'financing'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Financing Required
                                    </TableCell>
                                    <TableCell align="right">
                                      $ {allData.operations.financingRequired.toLocaleString()}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'yoc'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Yield On Cost
                                    </TableCell>
                                    <TableCell align="right">
                                      {allData.operations.yoc.toFixed(2)} %
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'exit cap'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Exit Cap Rate
                                    </TableCell>
                                    <TableCell align="right" style={{ color: analysisColor }}>
                                      {(allData.operations.exitCap * 100).toFixed(2)} %
                                    </TableCell>
                                  </TableRow>
                                  <TableRow
                                    key={'stabilized cap'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                      Stabilized Value
                                    </TableCell>
                                    <TableCell align="right" style={{ color: analysisColor }}>
                                      $ {allData.operations.stabilizedValue.toLocaleString()}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </Box>
                </Box>
              </Main>
            )}
          </div>
          <Drawer
            sx={{
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: { xs: '100%', sm: '100%', md: '25%', lg: '25%', xl: '25%' },
                boxSizing: 'border-box',
                paddingTop: `${marginTop}`
              }
            }}
            variant="persistent"
            anchor="right"
            open={drawerOpen}>
            <Box sx={{ padding: '25px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
              <Box sx={{ alignItems: 'flex-start', textAlign: 'left' }}>
                <Button color="secondary" onClick={() => setDrawerOpen(false)}>
                  Close {<ChevronRight />}
                </Button>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px', width: '100%' }}>
                <Card sx={{ padding: '15px', backgroundColor: '#e5e4e1' }}>
                  <RedtechOptions
                    allData={allData}
                    userData={userData}
                    redtechOptions={redtechOptions}
                    handleUpdateUserData={handleUpdateUserData}
                    handleUpdateRedtechOptions={handleUpdateRedtechOptions}
                  />
                </Card>
              </Box>
              {fullAccess === false && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
                  <Box
                    sx={{
                      width: '100%'
                    }}>
                    <p>
                      <strong>Purchase Full Underwritin</strong>
                    </p>
                    <p>
                      If you would like to customize this project please purchase Full Underwriting
                    </p>
                    <Box>
                      <p>
                        Full Underwriting give you the ability to customize the underwriting
                        including:
                      </p>
                      <ul>
                        <li>Potential Rezoning of the Property</li>
                        <li>Adjusting the Unit Mix</li>
                        <li>Adjusting Operations Projections</li>
                        <li>Adjusting Construction Assumptions</li>
                        <li>And More</li>
                      </ul>
                      <p>
                        You will also be able to access the following documents:
                        <ul>
                          <li>Offering Memorandum</li>
                          <li>Proforma (Coming Soon)</li>
                        </ul>
                      </p>
                      <p>
                        If you would like to view an example of what REDtech Full Underwriting can
                        do take a look at this example
                      </p>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        <Button
                          color="secondary"
                          variant="outlined"
                          component={Link}
                          to="/redtech/ae25b45c-8d0d-4101-85b9-0400e6ba210d"
                          target="_blank">
                          View Example
                        </Button>
                        {isAccountOlderThanSevenDays() && (
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => handlePurchase(paymentAmounts.fullAccess.price)}
                            disabled={loadingStripe}>
                            Purchase
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              {fullAccess === true && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
                  {allData.city.id === '0eb77cb6-1a83-48d6-a879-778a1320e749' && (
                    <Box
                      sx={{
                        width: '100%'
                      }}>
                      <Card sx={{ padding: '15px', backgroundColor: '#e5e4e1' }}>
                        <h6 style={{ padding: 0, margin: 0 }}>Special Bonuses</h6>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={denEHA}
                              onChange={(e) => {
                                setDenEHA(e.target.checked);
                                handleUpdateUserData({ denEHA: e.target.checked });
                              }}
                              color="secondary"
                            />
                          }
                          label="Denver EHA"
                        />
                      </Card>
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: '100%'
                    }}>
                    <Card sx={{ padding: '15px', backgroundColor: '#e5e4e1' }}>
                      <h6 style={{ padding: 0, margin: 0 }}>Zone Class</h6>
                      <FormControl fullWidth>
                        <Select
                          onChange={(event) =>
                            handleUpdateUserData({ customZoning: event.target.value })
                          }
                          value={allData.zone.id}>
                          {allData.zoneList.map((zone) => (
                            <MenuItem key={zone.id} value={zone.id}>
                              {zone.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Card>
                  </Box>
                  <Box
                    sx={{
                      width: '100%'
                    }}>
                    <Card sx={{ padding: '15px', backgroundColor: '#e5e4e1' }}>
                      <h6 style={{ padding: 0, margin: 0 }}>Number Floors</h6>
                      <p>Total: {allData.architecture.numberFloors}</p>
                      <Slider
                        color="secondary"
                        value={numberFloors}
                        step={1}
                        min={0}
                        max={allData.architecture.maxNumberFloors}
                        onChange={(event) => setNumberFloors(event.target.value)}
                        onChangeCommitted={() =>
                          handleUpdateUserData({
                            numberFloors: numberFloors
                          })
                        }
                      />
                    </Card>
                  </Box>
                  {(allData.query.productLine === 'continuum_workforce' ||
                    allData.query.productLine === 'multifamily') && (
                    <Box
                      sx={{
                        width: '100%'
                      }}>
                      <Card sx={{ padding: '15px', backgroundColor: '#e5e4e1' }}>
                        <>
                          <h6 style={{ padding: 0, margin: 0 }}>Unit Mix</h6>
                          <p>Total: {allData.architecture.numberUnits}</p>
                          <p className="unit-mix-value">Studios: {studioNumber}</p>
                          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <Slider
                              color="secondary"
                              value={studioNumber}
                              step={1}
                              min={0}
                              max={allData.architecture.maxStu}
                              onChange={handleStudioChange}
                              onChangeCommitted={() =>
                                handleUpdateUserData({
                                  customUnitMix: [studioNumber, -1, -1]
                                })
                              }
                            />
                          </Stack>
                          <p className="unit-mix-value">One Bedrooms: {oneBedNumber}</p>
                          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <Slider
                              color="secondary"
                              value={oneBedNumber}
                              step={1}
                              min={0}
                              max={allData.architecture.maxOne}
                              onChange={handleOneBedChange}
                              onChangeCommitted={() =>
                                handleUpdateUserData({
                                  customUnitMix: [-1, oneBedNumber, -1]
                                })
                              }
                            />
                          </Stack>
                          <p className="unit-mix-value">Two Bedrooms: {twoBedNumber}</p>
                          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <Slider
                              color="secondary"
                              value={twoBedNumber}
                              step={1}
                              min={0}
                              max={allData.architecture.maxTwo}
                              onChange={handleTwoBedChange}
                              onChangeCommitted={() =>
                                handleUpdateUserData({
                                  customUnitMix: [-1, -1, twoBedNumber]
                                })
                              }
                            />
                          </Stack>
                        </>
                      </Card>
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: '100%'
                    }}>
                    <Card sx={{ padding: '15px', backgroundColor: '#e5e4e1' }}>
                      <h6 style={{ padding: 0, margin: 0 }}>Rent</h6>
                      <p>$ {Math.round(rentPerUnit).toLocaleString()}</p>
                      <Slider
                        color="secondary"
                        value={rentPerUnit}
                        step={5}
                        min={allData.operations.rentMin}
                        max={allData.operations.rentMax}
                        onChange={(event) => setRentPerUnit(event.target.value)}
                        onChangeCommitted={() =>
                          handleUpdateUserData({ customRent: rentPerUnit, customAmiPercent: null })
                        }
                        track={false}
                      />
                      <p>AMI: {(amiPercent * 100).toFixed(2)}%</p>
                      <Slider
                        color="secondary"
                        value={amiPercent}
                        step={0.01}
                        min={0}
                        max={3}
                        onChange={(event) => setAmiPercent(event.target.value)}
                        onChangeCommitted={() =>
                          handleUpdateUserData({ customAmiPercent: amiPercent, customRent: null })
                        }
                        track={false}
                      />
                    </Card>
                  </Box>
                  <Box
                    sx={{
                      width: '100%'
                    }}>
                    <Card sx={{ padding: '15px', backgroundColor: '#e5e4e1' }}>
                      <h6 style={{ padding: 0, margin: 0 }}>Occupancy</h6>
                      <Slider
                        color="secondary"
                        value={occupancy}
                        step={0.01}
                        min={0}
                        max={1}
                        onChange={(event) => setOccupancy(event.target.value)}
                        onChangeCommitted={() =>
                          handleUpdateUserData({ customOccupancy: occupancy })
                        }
                        track={false}
                      />
                      <p>{Math.round(allData.operations.occupancy * 100)}% occupancy</p>
                    </Card>
                  </Box>
                  <Box
                    sx={{
                      width: '100%'
                    }}>
                    <Card sx={{ padding: '15px', backgroundColor: '#e5e4e1' }}>
                      <h6 style={{ padding: 0, margin: 0 }}>Operating Expenses</h6>
                      <Slider
                        color="secondary"
                        value={opexPercent}
                        step={0.01}
                        min={0}
                        max={1}
                        onChange={(event) => setOpexPercent(event.target.value)}
                        onChangeCommitted={() =>
                          handleUpdateUserData({ customOpexPercent: opexPercent })
                        }
                        track={false}
                      />
                      <p>{Math.round(allData.operations.opexPercent * 100)}% gross revenue</p>
                    </Card>
                  </Box>
                  <Box>
                    <Card sx={{ padding: '15px', backgroundColor: '#e5e4e1' }}>
                      <h6 style={{ padding: 0, margin: 0 }}>Construction Hard Costs</h6>
                      <Slider
                        color="secondary"
                        value={hardCost}
                        step={5}
                        min={0}
                        max={500}
                        onChange={handleHardCostChange}
                        onChangeCommitted={() => handleUpdateUserData({ customHardCost: hardCost })}
                        track={false}
                      />
                      <p>
                        $ {hardCost} Hard Cost per ft<sup>2</sup>
                      </p>
                    </Card>
                  </Box>
                  <Box>
                    <Card sx={{ padding: '15px', backgroundColor: '#e5e4e1' }}>
                      <h6 style={{ padding: 0, margin: 0 }}>Equity Required</h6>
                      <Slider
                        color="secondary"
                        defaultValue={parseFloat(equityPercent)}
                        min={0}
                        max={1}
                        step={0.01}
                        onChange={handleEquityPercentChange}
                        onChangeCommitted={() =>
                          handleUpdateUserData({ customEquityPercent: equityPercent })
                        }
                        track={false}
                      />
                      <p>{(equityPercent * 100).toFixed()}% of TDC</p>
                    </Card>
                  </Box>
                  <Box>
                    <Card sx={{ padding: '15px', backgroundColor: '#e5e4e1' }}>
                      <h6 style={{ padding: 0, margin: 0 }}>Exit Cap</h6>
                      <Slider
                        color="secondary"
                        value={parseFloat(exitCap)}
                        step={0.0025}
                        min={0}
                        max={0.1}
                        onChange={(event) => setExitCap(event.target.value)}
                        onChangeCommitted={() => handleUpdateUserData({ customExitCap: exitCap })}
                        track={false}
                      />{' '}
                      <p>{(exitCap * 100).toFixed(2)}%</p>
                    </Card>
                  </Box>
                </Box>
              )}
            </Box>
          </Drawer>
          <Authentication status={signinDialogOpen} type={'signin'} />
          <Dialog open={dialogOpen.open} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>{dialogOpen.title}</DialogTitle>
            <DialogContent>{dialogOpen.content}</DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => setDialogOpen(false)} color="secondary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    } else {
      return (
        <Box sx={{ height: '100vh' }}>
          <Dialog open={dialogOpen.open} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle>{dialogOpen.title}</DialogTitle>
            <DialogContent>{dialogOpen.content}</DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => setDialogOpen(false)} color="secondary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      );
    }
  }
}
