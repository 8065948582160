//const nycColors = ['R3-2', '#00FF00', 'R4-1', '#00FF00', 'R5', '#00FF00', 'R6', '#00FF00', 'R6A', '#00FF00', 'R6B', '#00FF00', 'R5B', '#00FF00', 'C4-3', '#eb4e32', 'C4-3A', '#eb4e32', 'M1-1', '#FFFF00', 'M1-4', '#FFFF00', 'M1-4D', '#FFFF00', 'PARK', '#008000'];
//const laColors = ['INDUSTRIAL', '#FFFF00', 'COMMERCIAL', '#eb4e32', 'PARKING', '#000000', 'RESIDENTIAL', '#00FF00', 'PUBLIC FACILITY', '#000000', 'OPEN SPACE', '#008000'];
//const denColors = ['Residential', '#00FF00', 'Commercial Mixed Use', '#eb4e32', 'Industrial', '#FFFF00', 'PUD', '#0000FF', 'Open Space', '#008000'];
//const atlColors = ['R-2', '#00FF00', 'R-4', '#00FF00', 'R-5', '#00FF00', 'R-3', '#00FF00'];
//const sfColors = ['RH-1', '#00FF00', 'RH-2', '#00FF00', 'RH-3', '#00FF00', 'RM-1', '#00FF00', 'RM-2', '#00FF00'];

//const zonesColors = [...nycColors, ...laColors, ...denColors, ...atlColors, ...sfColors];

const opportunityColors = [
  'Highest Resource',
  '#234fa0',
  'High Resource',
  '#2897bf',
  'Moderate Resource(Rapidly Changing)',
  '#73c9bd',
  'Moderate Resources',
  '#73c9bd',
  'Low Resource',
  '#d5eeb3',
  'High Segregation & Poverty',
  '#ffffd9',
  'Missing/Insufficient Data',
  '#999',
  'National & State Park/Forest/Rec Area',
  '#00FF00'
];

export const zoneLayer = {
  id: 'zoneLayer',
  type: 'fill',
  paint: {
    'fill-color': '#00FF00',
    'fill-opacity': 0.3
  }
};

export const parcelLayer = {
  id: 'parcelLayer',
  type: 'fill',
  paint: {
    'fill-color': '#00FF00',
    'fill-opacity': 0.8,
    'fill-outline-color': '#00FF00'
  }
};

export const zipLayer = {
  id: 'zipLayer',
  beforeId: 'parcelLayerOutlined',
  type: 'line',
  paint: {
    'line-color': '#000',
    'line-width': 5
  }
};

export const singleParcelLayer = {
  id: 'singleParcelLayer',
  type: 'line',
  paint: {
    'line-color': '#00FF00',
    'line-width': 3
  }
};

export const allZipLayer = {
  id: 'zipcodeLayer',
  beforeId: 'parcelLayerOutlined',
  type: 'fill',
  paint: {
    'fill-color': '#FFF000',
    'fill-outline-color': '#000',
    'fill-opacity': 0
  }
};
export const allZipLayerLine = {
  id: 'zipcodeLayerLine',
  beforeId: 'parcelLayerOutlined',
  type: 'line',
  paint: {
    'line-color': '#000',
    'line-width': 2
  }
};

export const opportunityLayer = {
  id: 'opportunityLayer',
  type: 'fill',
  paint: {
    'fill-color': ['match', ['string', ['get', 'oppcat']], ...opportunityColors, '#000'],
    'fill-opacity': 0.5
  }
};

export const qctLayer = {
  id: 'qctLayer',
  type: 'fill',
  paint: {
    'fill-color': '#FF0000',
    'fill-opacity': 0.25,
    'fill-outline-color': '#FF0000'
  }
};

export const ddaLayer = {
  id: 'ddaLayer',
  type: 'fill',
  paint: {
    'fill-color': '#00FF00',
    'fill-opacity': 0.25,
    'fill-outline-color': '#00FF00'
  }
};

export const tocLayer = {
  id: 'qctLayer',
  type: 'fill',
  paint: {
    'fill-color': '#0000FF',
    'fill-opacity': 0.25,
    'fill-outline-color': '#0000FF'
  }
};

export const clickedLayer = {
  id: 'clickedLayer',
  type: 'fill',
  paint: {
    'fill-color': '#35c935',
    'fill-opacity': 0.25,
    'fill-outline-color': '#35c935'
  }
};

export const ozLayer = {
  id: 'qctLayer',
  type: 'fill',
  paint: {
    'fill-color': '#FF00FF',
    'fill-opacity': 0.25,
    'fill-outline-color': '#FF00FF'
  }
};

export const highlightLayer = {
  id: 'zipcodeHighlighted',
  type: 'fill',
  paint: {
    'fill-outline-color': '#484896',
    'fill-color': '#6e599f',
    'fill-opacity': 0.75
  }
};

export const buildingLayer = {
  id: '3d-buildings',
  source: 'composite',
  'source-layer': 'building',
  filter: ['==', 'extrude', 'true'],
  type: 'fill-extrusion',
  minzoom: 12,
  'fill-antialias': true,
  paint: {
    'fill-extrusion-color': '#d3d6ff',
    // use an 'interpolate' expression to add a smooth transition effect to the
    // buildings as the user zooms in
    'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
    'fill-extrusion-base': [
      'interpolate',
      ['linear'],
      ['zoom'],
      15,
      0,
      15.05,
      ['get', 'min_height']
    ],
    'fill-extrusion-opacity': 0.35
  }
};

export const panelParcelLayer = {
  id: 'panelParcelLayer',
  type: 'fill-extrusion',
  paint: {
    'fill-extrusion-color': '#eb4e32',
    'fill-extrusion-opacity': 1,
    'fill-extrusion-height': 15.24
  }
};

export const laMetroLayerRail = {
  id: 'laMetroLayerRail',
  type: 'circle',
  paint: {
    'circle-color': '#0000FF',
    'circle-radius': 5
  }
};

export const laMetroLayerBus = {
  id: 'laMetroLayerBus',
  type: 'circle',
  paint: {
    'circle-color': '#0000FF',
    'circle-radius': 5
  }
};
