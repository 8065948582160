import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { connect } from 'react-redux';

import logoHead from '../files/images/impact/headerLogo.png';
import logo from '../files/images/impact/logo.png';

import stacksourceImage from '../files/images/home/logos/Stacksource.png';
import prosetImage from '../files/images/home/logos/PROSET.png';
import indieDwellImage from '../files/images/home/logos/Indiedwell.png';
import maisonImage from '../files/images/home/logos/Maison.png';

import placeholderImage from '../files/images/home/0.jpg';
import identifyImage from '../files/images/impact/identify.png';
import underwriteImage from '../files/images/impact/underwrite.png';
import buildImage from '../files/images/impact/build.png';

import factoryImage from '../files/images/home/factory.png';

import searchVideo from '../files/videos/search.mp4';
import underwriteVideo from '../files/videos/underwriting.mp4';
import searchVideoThumb from '../files/videos/searchThumb.png';
import underwriteVideoThumb from '../files/videos/underwritingThumb.png';

import { InlineWidget } from 'react-calendly';

import {
  Box,
  Toolbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

import { Helmet } from 'react-helmet';

class Impact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      searchVideoDialogOpen: false,
      underwriteVideoDialogOpen: false
    };
  }

  render() {
    return (
      <>
        <Helmet>
          <meta property="og:type" content="page" />
          <meta
            property="og:description"
            content="Madelon REDtech partners with you to streamline the development process by providing accurate projections of product cost and return on investment. And by connecting you to pre-vetted partners to finance, construct, and operate the project."
          />
          <meta property="og:url" content="https://redtech.app/impact" />
          <meta property="og:title" content="Impact | REDtech" />
          <meta property="og:image" content="/images/1.jpg" />
          <meta property="og:image:type" content="image/jpg" />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="400" />
          <meta property="og:image:alt" content="Madelon REDtech Impact" />
          <meta property="og:site_name" content="Madelon REDtech Impact" />
        </Helmet>
        <div className="home">
          <div className="home-header">
            <Box sx={{ flexGrow: 1 }}>
              <Toolbar>
                <Link to="/" style={{ flex: 1 }}>
                  <img className="home-header-logo" src={logoHead} alt="Logo" />
                </Link>
                <div
                  className="home-header-buttons"
                  style={{ flex: 3, justifyContent: 'flex-end', alignItems: 'stretch' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.setState({ dialogOpen: true })}>
                    Request Demo
                  </Button>
                  <Box
                    sx={{
                      display: { xs: 'none', md: 'flex' }
                    }}>
                    <Button variant="outlined" color="secondary" component={Link} to="search">
                      Start Searching
                    </Button>
                  </Box>
                </div>
              </Toolbar>
            </Box>
          </div>
          <Box
            sx={{
              maxHeight: '100vh',
              scrollSnapType: { xs: 'none', md: 'y mandatory' },
              scrollBehavior: 'smooth',
              overflowY: 'scroll'
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                height: '100%',
                scrollSnapAlign: 'start'
              }}>
              <img className="impact-splash-logo" src={logo} alt="Logo" />
              <h1 className="impact-text">Empowering communities to build a better future</h1>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  marginBottom: '25px'
                }}>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={() => this.setState({ dialogOpen: true })}>
                  Request Demo
                </Button>
                <Button
                  size="large"
                  variant="outlined"
                  color="secondary"
                  component={Link}
                  to={'/search'}>
                  Start Searching
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: { xs: 'none', md: '100vh' },
                height: '100%',
                width: '100%',
                scrollSnapAlign: 'start',
                color: '#fff',
                backgroundColor: '#eb4e32',
                padding: '25px',
                marginTop: { xs: '50px', md: '0px' },
                marginBottom: { xs: '50px', md: '0px' }
              }}>
              <Box
                sx={{
                  flexBasis: '50%'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: { xs: 'flex-start', md: 'flex-end' },
                    textAlign: { xs: 'left', md: 'right' },
                    paddingRight: { xs: '0px', md: '50px' },
                    borderRight: { xs: 'none', md: '2px solid #fff' }
                  }}>
                  <Box
                    sx={{
                      maxWidth: { xs: '100%', md: '70%' }
                    }}>
                    <h1>Catalyzing the creation of affordable housing</h1>
                    <p>
                      Through the merging of technology and data, we are able to provide a platform
                      that drastically reduces the overhead associated with finding, underwriting,
                      and building housing that is affordable.
                      <br />
                      We seek to empower communities to build a better future by reducing
                      development costs and timelines to deliver well desgined housing where it is
                      needed the most.
                    </p>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  flexBasis: '50%'
                }}>
                <Box
                  sx={{
                    maxWidth: { xs: '100%', md: '70%' }
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: { xs: 'flex-start', md: 'flex-start' },
                      textAlign: { xs: 'left', md: 'left' },
                      paddingLeft: { xs: '25px', md: '50px' }
                    }}>
                    <Box>
                      <h1>Affordable/Workforce Housing Developers</h1>
                    </Box>
                    <Box>
                      <h1>Housing Authorities</h1>
                    </Box>
                    <Box>
                      <h1>Non-profit Community Organizations</h1>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: { xs: 'none', md: '100vh' },
                height: '100%',
                width: '100%',
                scrollSnapAlign: 'start',
                backgroundColor: '#e5e4e1',
                marginTop: { xs: '50px', md: '0px' },
                marginBottom: { xs: '50px', md: '0px' }
              }}>
              <ReactBeforeSliderComponent
                firstImage={{ imageUrl: '/images/impact/before.jpg' }}
                secondImage={{ imageUrl: '/images/impact/after.jpg' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: { xs: 'none', md: '100vh' },
                height: '100%',
                width: '100%',
                scrollSnapAlign: 'start',
                backgroundColor: '#e5e4e1',
                textAlign: 'center',
                padding: '25px',
                marginTop: { xs: '50px', md: '0px' },
                marginBottom: { xs: '50px', md: '0px' }
              }}>
              <h1 style={{ margin: 0, padding: 0 }}>The Madelon REDtech Process</h1>
              <p style={{ margin: 0, padding: 0 }}>No experience necessary</p>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: 'stretch',
                  justifyContent: 'center',
                  gap: '35px'
                }}>
                <Box
                  sx={{
                    padding: '25px',
                    boxShadow: '5px #777',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    maxWidth: { xs: '320px', md: '420px' },
                    textAlign: 'center'
                  }}>
                  <img
                    style={{ maxWidth: '100%', maxHeight: '150px' }}
                    src={identifyImage}
                    alt="Identify"
                  />
                  <h1>Identify</h1>
                  <p>
                    Search and filter to find the ideal parcel that is the perfect size, zoning,
                    location, and so much more!
                  </p>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => this.setState({ searchVideoDialogOpen: true })}>
                    Learn More
                  </Button>
                </Box>
                <Box
                  sx={{
                    padding: '25px',
                    boxShadow: '5px #777',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    maxWidth: { xs: '320px', md: '420px' },
                    textAlign: 'center'
                  }}>
                  <img
                    style={{ maxWidth: '100%', maxHeight: '150px' }}
                    src={underwriteImage}
                    alt="Underwrite"
                  />
                  <h1>Underwrite</h1>
                  <p>
                    Underwrite in seconds with the click of a button. REDtech's underwriting goes
                    beyond 'the back of an envelope'.
                  </p>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => this.setState({ underwriteVideoDialogOpen: true })}>
                    Learn More
                  </Button>
                </Box>
                <Box
                  sx={{
                    padding: '25px',
                    boxShadow: '5px #777',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    maxWidth: { xs: '320px', md: '420px' },
                    textAlign: 'center'
                  }}>
                  <img
                    style={{ maxWidth: '100%', maxHeight: '150px' }}
                    src={buildImage}
                    alt="Build"
                  />
                  <h1>Build</h1>
                  <p>
                    Work directly with the Madelon team and our series of pre-vetted partners to
                    bring your building to life.
                  </p>
                  <Button variant="outlined" color="secondary" component={HashLink} to="#partners">
                    Learn More
                  </Button>
                </Box>
              </Box>
              <p style={{ fontSize: '.7em' }}>
                The REDtech platform currently automates underwritings the cities of New York,
                Denver, and Los Angeles.
                <br />
                Interested in utilizing REDtech in your area? Drop us a line{' '}
                <a href="/contact">here</a>!
              </p>

              <Button
                size="large"
                variant="contained"
                color="secondary"
                onClick={() => this.setState({ dialogOpen: true })}>
                Request Demo
              </Button>
            </Box>
            <Box
              id="partners"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: { xs: 'none', md: '100vh' },
                height: '100%',
                width: '100%',
                scrollSnapAlign: 'start',
                backgroundColor: '#e5e4e1',
                padding: '25px',
                marginTop: { xs: '50px', md: '0px' },
                marginBottom: { xs: '50px', md: '0px' }
              }}>
              <h1>Partners</h1>
              <Box
                sx={{
                  maxWidth: '50%',
                  textAlign: 'center'
                }}>
                <p>
                  Madelon's pre-vetted partners are the best in the business. We work with them to
                  help finance, construct, and operate your project. Below are a few of our
                  partners, however we have many more and always look to choose the best fit for
                  each individual project.
                </p>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: '25px',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <Box sx={{ maxWidth: '300px' }}>
                  <a href="https://www.stacksource.com/">
                    <img className="search-image" src={stacksourceImage} alt="stacksource" />
                  </a>
                </Box>
                <Box sx={{ maxWidth: '300px' }}>
                  <a href="http://www.prosetmodular.com/">
                    <img className="search-image" src={prosetImage} alt="proset" />
                  </a>
                </Box>
                <Box sx={{ maxWidth: '300px' }}>
                  <a href="https://www.indiedwell.com/">
                    <img className="search-image" src={indieDwellImage} alt="indiedwell" />
                  </a>
                </Box>
                <Box sx={{ maxWidth: '300px' }}>
                  <a href="https://maisonmeanshome.com/">
                    <img className="search-image" src={maisonImage} alt="maison" />
                  </a>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: { xs: 'none', md: '100vh' },
                height: '100%',
                width: '100%',
                scrollSnapAlign: 'start',
                background: `linear-gradient(to right, rgba(255,255,255,0),
              rgba(229,228,225,1) 100%), url(${placeholderImage})`,
                backgroundSize: { xs: '100% 100%', md: '80% 100%' },
                backgroundRepeat: 'no-repeat',
                padding: '25px'
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Box
                  sx={{
                    flexBasis: '60%'
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      textAlign: 'right',
                      padding: '15px 50px'
                    }}></Box>
                </Box>
                <Box
                  sx={{
                    flexBasis: '40%'
                  }}>
                  <Box
                    sx={{
                      maxWidth: { xs: '100%', md: '70%' }
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        textAlign: 'left',
                        paddingLeft: '50px',
                        fontSize: '1.2em'
                      }}>
                      <h1 style={{ margin: 0, padding: 0 }}>BRH1</h1>
                      <p style={{ margin: 0, padding: 0 }}>Blue Room House One | Denver, CO</p>
                      <p>
                        The first of many project to utilize REDtech to identify, underwirte and
                        connect an impact developer with our prevetted partners to deliver
                        affordable housing units in the city of Denver.
                      </p>
                      <ul style={{ fontSize: '1.5em' }}>
                        <li>54 Units</li>
                        <li>50% offered as affordable housing</li>
                        <li>Dedicated to community impact</li>
                      </ul>
                      <a href="https://www.blueroomhouseone.com/" target="_blank" rel="noreferrer">
                        <Button size="large" variant="contained" color="secondary">
                          Learn More About Blueroom House One
                        </Button>
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: { xs: 'none', md: '100vh' },
                height: '100%',
                width: '100%',
                scrollSnapAlign: 'start',
                backgroundColor: '#e5e4e1',
                padding: '25px'
              }}>
              <Box
                sx={{
                  flexBasis: '50%'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: { xs: 'flex-start', md: 'flex-end' },
                    textAlign: { xs: 'left', md: 'right' },
                    paddingRight: { xs: '0px', md: '50px' }
                  }}>
                  <Box
                    sx={{
                      maxWidth: { xs: '100%', md: '70%' }
                    }}>
                    <h1>See how you can work with REDtech</h1>
                    <p>
                      We would love to show you how Madelon REDtech can help you deliver affordable
                      housing faster with less risk.
                    </p>
                    <Button
                      size="large"
                      variant="contained"
                      color="secondary"
                      onClick={() => this.setState({ dialogOpen: true })}>
                      Request Demo
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  flexBasis: '50%'
                }}>
                <Box
                  sx={{
                    maxWidth: { xs: '100%', md: '100%' }
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      textAlign: 'left',
                      padding: { xs: '15px 0px', md: '15px 50px' }
                    }}>
                    <img style={{ width: '100%' }} src={factoryImage} alt="factory" />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: { xs: 'none', md: '100vh' },
                height: '100%',
                width: '100%',
                scrollSnapAlign: 'start',
                backgroundColor: '#e5e4e1',
                padding: '25px'
              }}>
              <Box
                sx={{
                  flexBasis: '50%'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: { xs: 'flex-start', md: 'center' },
                    textAlign: { xs: 'left', md: 'right' },
                    paddingRight: { xs: '0px', md: '50px' }
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: { xs: '100%', md: '50%' },
                      justifyContent: 'flex-start'
                    }}>
                    <h1 style={{ margin: 0, padding: 0 }}>FAQs</h1>
                    <p style={{ margin: 0, padding: 0 }}>
                      If you have any other questions please feel free to reach out to us{' '}
                      <a href="/contact">here</a>.
                    </p>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexBasis: '50%'
                }}>
                <Box
                  sx={{
                    maxWidth: { xs: '100%', md: '80%' }
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: { xs: 'flex-start', md: 'center' },
                      textAlign: { xs: 'left', md: 'left' },
                      paddingLeft: { xs: '0px', md: '50px' }
                    }}>
                    <Box>
                      <h1>What cities do you work in?</h1>
                      <p>
                        REDtech currenly has automated underwriting in the cities of New York, Los
                        Angeles, and Denver. The team is working diligently to bring more cities
                        online in the future.
                      </p>
                    </Box>
                    <Box>
                      <h1>How accurate are the underwritings?</h1>
                      <p>
                        We strive to make every number, floorplan, and rendering as accurate as
                        possible! We constantly are monitoring zoning code changes, area rents, and
                        more to ensure that our underwritings give you an accurate representation of
                        a potential project.
                      </p>
                    </Box>
                    <Box>
                      <h1>How much does REDtech cost?</h1>
                      <p>
                        We are happy to say that the REDtech platform is{' '}
                        <span style={{ color: '#eb4e32', fontWeight: '800' }}>free</span> to use. We
                        want everyone to have access to REDtech in the hopes that we all together
                        can produce more affordable housing.
                      </p>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
        <Dialog fullWidth={true} maxWidth="lg" open={this.state.dialogOpen}>
          <DialogTitle>Request Demo</DialogTitle>
          <DialogContent>
            <p>Select a time that works for you!</p>
            <InlineWidget url="https://calendly.com/madelongroup-sean/discovery" />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ dialogOpen: false })}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="lg" open={this.state.searchVideoDialogOpen}>
          <DialogContent>
            <video src={searchVideo} poster={searchVideoThumb} playsInline controls={true} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ searchVideoDialogOpen: false })}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="lg" open={this.state.underwriteVideoDialogOpen}>
          <DialogContent>
            <video
              src={underwriteVideo}
              poster={underwriteVideoThumb}
              playsInline
              controls={true}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ underwriteVideoDialogOpen: false })}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user
  };
}

export default connect(mapStateToProps)(Impact);
