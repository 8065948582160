import React from 'react';
import { useParams } from 'react-router-dom';

import Header from '../components/common/header';
import Collection from '../components/user/collection';

const CollectionPage = () => {
  const { collectionId } = useParams();

  return (
    <>
      <Header />
      <Collection collectionId={collectionId} />
    </>
  );
};

export default CollectionPage;
