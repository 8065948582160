import axios from 'axios';

import { API_URL, GOOGLE_MAP_KEY } from '../config';

class AdminService {
  fetchAppRequirements() {
    return axios.get(API_URL + 'app/requirements');
  }

  reverseGeocode(latitude, longitude) {
    return axios.get(
      'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
        latitude +
        ',' +
        longitude +
        '&key=' +
        GOOGLE_MAP_KEY
    );
  }

  addQuery(
    id,
    address,
    zipcode,
    latitude,
    longitude,
    cityId,
    cost,
    userLocation,
    userId,
    productLine,
    saleEstimate,
    googlePlace,
    parcelId,
    paid,
    products_purchased
  ) {
    return axios.post(API_URL + 'query', {
      id,
      address,
      zipcode,
      latitude,
      longitude,
      cityId,
      cost,
      userLocation,
      userId,
      productLine,
      saleEstimate,
      googlePlace,
      parcelId,
      paid,
      products_purchased
    });
  }

  getAllQueries() {
    return axios.get(API_URL + 'queries');
  }

  getQueryById(id) {
    return axios.get(API_URL + 'query/' + id);
  }

  getQueriesByUserId(userId) {
    return axios.get(API_URL + 'queries/' + userId);
  }

  updateQueryv2(id, data) {
    return axios.post(API_URL + 'query/' + id, data);
  }

  updateQuery(id, parcelId, parcelFound, yoc, irrValue, madScore) {
    return axios.post(API_URL + 'query/' + id, {
      parcelId,
      parcelFound,
      yoc,
      irrValue,
      madScore
    });
  }

  updateQueryUser(id, userId) {
    return axios.post(API_URL + 'query/' + id, {
      userId
    });
  }

  updateQueryFavArc(id, favorited, archived) {
    return axios.post(API_URL + 'query/' + id, { favorited, archived });
  }

  updateQueryCost(id, cost) {
    return axios.post(API_URL + 'query/' + id, { cost });
  }

  updateQueryRent(id, customRent) {
    return axios.post(API_URL + 'query/' + id, { customRent });
  }

  updateQueryCap(id, exitCap) {
    return axios.post(API_URL + 'query/' + id, { exitCap });
  }

  updateQueryOccupancy(id, customOccupancy) {
    return axios.post(API_URL + 'query/' + id, { customOccupancy });
  }

  updateQueryEquity(id, customEquityPercent) {
    return axios.post(API_URL + 'query/' + id, { customEquityPercent });
  }

  updateQueryOpex(id, customOpexPercent) {
    return axios.post(API_URL + 'query/' + id, { customOpexPercent });
  }

  updateQueryHardCost(id, customHardCost) {
    return axios.post(API_URL + 'query/' + id, { customHardCost });
  }

  updateQueryContacted(id, contacted) {
    return axios.post(API_URL + 'query/' + id, { contacted });
  }

  updateQueryProductLine(id, productLine) {
    return axios.post(API_URL + 'query/' + id, { productLine });
  }

  updateQueryArchitectureAnalyzed(id, architectureAnalyzed) {
    return axios.post(API_URL + 'query/' + id, { architectureAnalyzed });
  }

  updateParcelEfficiency(id, efficiency) {
    return axios.post(API_URL + 'parcel/' + id, { efficiency });
  }

  updateParcelRealtyMoleData(
    id,
    compsRent,
    compsRentHigh,
    compsRentLow,
    compsRentMulti,
    compsRentHighMulti,
    compsRentLowMulti,
    compsDate,
    salePrice,
    salePriceHigh,
    salePriceLow
  ) {
    return axios.post(API_URL + 'parcel/' + id, {
      compsRent,
      compsRentHigh,
      compsRentLow,
      compsRentMulti,
      compsRentHighMulti,
      compsRentLowMulti,
      compsDate,
      salePrice,
      salePriceHigh,
      salePriceLow
    });
  }

  updateParcelWalkScore(id, walkScore) {
    return axios.post(API_URL + 'parcel/' + id, { walkScore });
  }

  updateParcelAddress(id, address) {
    return axios.post(API_URL + 'parcel/' + id, { address });
  }

  addRequest(city, zip) {
    return axios.post(API_URL + 'request', { city, zip });
  }

  addSearch(userId, city, zip, address) {
    return axios.post(API_URL + 'search', { userId, city, zip, address });
  }

  addRequestAddress(address) {
    return axios.post(API_URL + 'request', { address });
  }

  addCity(name, state, latitude, longitude, include, zonekey) {
    return axios.post(API_URL + 'city', { name, state, latitude, longitude, include, zonekey });
  }

  getAllCities() {
    return axios.get(API_URL + 'cities');
  }

  getCityById(cityId) {
    return axios.get(API_URL + 'city/' + cityId);
  }

  getCityByName(name) {
    return axios.post(API_URL + 'cities', { name });
  }

  addZone(name, cityId) {
    return axios.post(API_URL + 'zone', { name, cityId });
  }

  getZoneByName(name, cityId) {
    return axios.post(API_URL + 'zones', { name, cityId });
  }

  getZonesByCityId(cityId) {
    return axios.get(API_URL + 'zones/' + cityId);
  }

  getAllZones() {
    return axios.get(API_URL + 'zones');
  }

  getAllParcels(cityid, zones) {
    return axios.get(API_URL + 'all-parcels', {
      params: { cityid: cityid, zones: zones }
    });
  }

  getParcelInfo(zipcode, lotLongitude, lotLatitude) {
    return axios.post(API_URL + 'parcels', { zipcode, lotLongitude, lotLatitude });
  }

  getParcelInfoZipcode(zipcode) {
    return axios.get(API_URL + 'parcels/' + zipcode);
  }

  getParcelById(parcelId) {
    return axios.get(API_URL + 'parcel/' + parcelId);
  }

  getNeighborhoodInfo(lotLongitude, lotLatitude) {
    return axios.post(API_URL + 'neighborhoods', { lotLongitude, lotLatitude });
  }

  getAllNeighborhoods() {
    return axios.get(API_URL + 'neighborhoods');
  }

  getAllUsers() {
    return axios.get(API_URL + 'users');
  }

  getUserInfo(id) {
    return axios.get(API_URL + 'user/' + id);
  }

  updateUserInfo(id, info) {
    return axios.post(API_URL + 'user/' + id, info);
  }

  updateUserPassword(id, oldPassword, password) {
    return axios.post(API_URL + 'user/' + id, { oldPassword, password });
  }

  deleteUser(id) {
    return axios.post(API_URL + 'user/delete/' + id);
  }

  getZipcodeInfo(zipcode) {
    return axios.get(API_URL + 'zipcode/' + zipcode);
  }

  getCityZipcodes(city) {
    return axios.post(API_URL + 'zipcodes-bycity', { city });
  }

  getCountyZipcodes(county, state_id) {
    return axios.post(API_URL + 'zipcodes-bycounty', { county, state_id });
  }

  getAllZipcodeInfo() {
    return axios.get(API_URL + 'zipcodes');
  }

  createOrganization(userId, name) {
    return axios.post(API_URL + 'organization', { userId, name });
  }

  getProductByKey(key) {
    return axios.post(API_URL + 'products', { key });
  }

  getProducts() {
    return axios.get(API_URL + 'products');
  }

  getZonesByProductId(productId) {
    return axios.post(API_URL + 'products-zone', { productId });
  }

  getProductsByZoneId(zoneId) {
    console.log(zoneId);
    return axios.post(API_URL + 'zone-products', { zoneId });
  }

  fetchSaleEstimate = async (longitude, latitude) => {
    var config = {
      params: {
        compCount: '10',
        longitude: longitude,
        latitude: latitude
      },
      headers: {
        'x-rapidapi-key': '1a87c647f6msh64e833eacb5ac39p1a084djsnc8703ab6747b',
        'x-rapidapi-host': 'realty-mole-property-api.p.rapidapi.com'
      }
    };

    return axios.get('https://realty-mole-property-api.p.rapidapi.com/salePrice', config);
  };

  fetchSaleListings = async (offset, postal_code, city, state_code) => {
    var config = {
      params: {
        city: city,
        state_code: state_code,
        offset: offset,
        limit: '200',
        postal_code: postal_code,
        sort: 'relevance',
        is_contingent: 'false',
        is_pending: 'false'
      },
      headers: {
        'x-rapidapi-host': 'realty-in-us.p.rapidapi.com',
        'x-rapidapi-key': 'b5d362d1b3msh1c5302ceae86b3ap13fa09jsn489a41c9602a'
      }
    };

    return axios.get('https://realty-in-us.p.rapidapi.com/properties/v2/list-for-sale', config);
  };

  fetchSaleListingsV3 = async (zipcode) => {
    var config = {
      method: 'POST',
      url: 'https://realty-in-us.p.rapidapi.com/properties/v3/list',
      headers: {
        'content-type': 'application/json',
        'x-rapidapi-host': 'realty-in-us.p.rapidapi.com',
        'x-rapidapi-key': 'b5d362d1b3msh1c5302ceae86b3ap13fa09jsn489a41c9602a'
      },
      data: {
        offset: '0',
        limit: '200',
        postal_code: zipcode,
        status: ['for_sale'],
        sort: {
          direction: 'desc',
          field: 'list_date'
        }
      }
    };

    return axios.request(config);
  };

  fetchSaleListingDetail = async (property_id) => {
    var config = {
      params: {
        property_id: property_id
      },
      headers: {
        'x-rapidapi-host': 'realty-in-us.p.rapidapi.com',
        'x-rapidapi-key': 'b5d362d1b3msh1c5302ceae86b3ap13fa09jsn489a41c9602a'
      }
    };

    return axios.get('https://realty-in-us.p.rapidapi.com/properties/v2/detail', config);
  };

  fetchWalkScore = async (address, lat, lon) => {
    var config = {
      params: {
        wsapikey: process.env.REACT_APP_WSAPIKEY,
        address: address,
        lat: lat,
        lon: lon,
        format: 'json'
      },
      headers: {
        'x-rapidapi-host': 'walk-score.p.rapidapi.com',
        'x-rapidapi-key': 'b5d362d1b3msh1c5302ceae86b3ap13fa09jsn489a41c9602a'
      }
    };

    return axios.get('https://walk-score.p.rapidapi.com/score', config);
  };

  attomFetch = async (address1, address2) => {
    var config = {
      headers: {
        accept: 'application/json',
        apiKey: '2b1e86b638620bf2404521e6e9e1b19e'
      }
    };

    return axios.get(
      `https://api.gateway.attomdata.com/propertyapi/v1.0.0/property/expandedprofile?address1=${address1}&address2=${address2}`,
      config
    );
  };

  submitParcelNotAutomated(email, address, zone) {
    const webhookUrl =
      'https://hooks.slack.com/services/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';

    const data = {
      text: `New Search - Not Automated: ${email} \n${address} \n${zone}`
    };

    axios.post(webhookUrl, JSON.stringify(data), {
      withCredentials: false,
      transformRequest: [
        (data, headers) => {
          delete headers.post['Content-Type'];
          return data;
        }
      ]
    });
  }

  submitParcelNotAutomatedOutside(
    email,
    address,
    size,
    zone,
    saleDate,
    salePrice,
    yearBuilt,
    buildingSize,
    rentInfo
  ) {
    const webhookUrl =
      'https://hooks.slack.com/services/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';

    const data = {
      text: `New Search - Not Automated - Outside Cities: \n Email - ${email} \n Address - ${address} \n Zone - ${zone} \n Lot Size - ${size} \n saleDate - ${saleDate} \n salePrice - ${salePrice} \n yearBuilt - ${yearBuilt} \n buildingSize - ${buildingSize} \n rentInfo - ${rentInfo}`
    };

    axios.post(webhookUrl, JSON.stringify(data), {
      withCredentials: false,
      transformRequest: [
        (data, headers) => {
          delete headers.post['Content-Type'];
          return data;
        }
      ]
    });
  }

  submitParcelNotAutomatedOutsideRequest(
    email,
    address,
    size,
    zone,
    saleDate,
    salePrice,
    yearBuilt,
    buildingSize,
    rentInfo
  ) {
    const webhookUrl =
      'https://hooks.slack.com/services/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';

    const data = {
      text: `Request for more Info: \n Email - ${email} \n Address - ${address} \n Zone - ${zone} \n Lot Size - ${size} \n saleDate - ${saleDate} \n salePrice - ${salePrice} \n yearBuilt - ${yearBuilt} \n buildingSize - ${buildingSize} \n rentInfo - ${JSON.stringify(
        rentInfo
      )}`
    };

    axios.post(webhookUrl, JSON.stringify(data), {
      withCredentials: false,
      transformRequest: [
        (data, headers) => {
          delete headers.post['Content-Type'];
          return data;
        }
      ]
    });
  }

  submitNewParcelSearch(email, address, zone) {
    const webhookUrl =
      'https://hooks.slack.com/services/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';

    const data = {
      text: `New Search: ${email} \n${address} \n${zone}`
    };

    axios.post(webhookUrl, JSON.stringify(data), {
      withCredentials: false,
      transformRequest: [
        (data, headers) => {
          delete headers.post['Content-Type'];
          return data;
        }
      ]
    });
  }

  getHUD = async (zipInfo) => {
    var config = {
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_HUD_TOKEN}`
      }
    };

    var countyFIPS = zipInfo.county_fips;

    countyFIPS = countyFIPS.padEnd(10, '9');

    const fmr = await axios.get(
      `https://www.huduser.gov/hudapi/public/fmr/data/${countyFIPS}`,
      config
    );
    const iL = await axios.get(
      `https://www.huduser.gov/hudapi/public/il/data/${countyFIPS}`,
      config
    );

    return { fmr: fmr.data.data, iL: iL.data.data };
  };

  getHUDTrend = async (zipInfo) => {
    var config = {
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_HUD_TOKEN}`
      }
    };

    var countyFIPS = zipInfo.county_fips;

    countyFIPS = countyFIPS.padEnd(10, '9');

    const currentYear = new Date().getFullYear();

    var trendData = [];
    var index = 0;

    for (var i = 2017; i < currentYear + 1; i++) {
      const response = await axios.get(
        `https://www.huduser.gov/hudapi/public/fmr/data/${countyFIPS}?year=${i}`,
        config
      );
      const data = response.data.data.basicdata;
      trendData[index] = data;
      index++;
    }

    return trendData;
  };

  getPreset(id) {
    return axios.get(API_URL + 'preset/' + id);
  }

  getAllPresets(userid) {
    return axios.get(API_URL + 'presets/' + userid);
  }

  getSitePresets() {
    return axios.get(API_URL + 'site-presets');
  }

  savePresets(data) {
    return axios.post(API_URL + 'preset', data);
  }

  updatePresets(presetId, data) {
    return axios.post(API_URL + 'preset/' + presetId, data);
  }

  getAllParcelsV2(cityId, data) {
    return axios.post(API_URL + 'v2/all-parcels/' + cityId, data);
  }

  getQueryV2(id, userData, redtechOptions) {
    return axios.post(API_URL + 'v2/query/' + id, {
      userData: userData,
      redtechOptions: redtechOptions
    });
  }

  getParcelCollection(collectionId) {
    return axios.get(API_URL + 'collection/parcels/' + collectionId);
  }

  getAllParcelsInCollection(parcelIds) {
    return axios.post(API_URL + 'collection/parcels/data', parcelIds);
  }

  removeParcelsFromCollection(collectionId, parcelIds) {
    return axios.post(API_URL + 'collection/parcels/remove', {
      collectionId: collectionId,
      parcelIds: parcelIds
    });
  }

  getNews(search) {
    return axios.get(API_URL + 'news/' + search);
  }

  createCheckoutSession(user, priceId, queryId, host) {
    return axios
      .post(API_URL + `v2/stripe/create-checkout-session`, {
        user,
        priceId,
        queryId,
        host
      })
      .then((response) => {
        if (response.data.url) {
          window.location.href = response.data.url;
        }
      });
  }
}
// eslint-disable-next-line
export default new AdminService();
