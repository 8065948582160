import React, { useState } from 'react';

import Collections from './collections';
import IconMenu from './dashboardMenu';
import Presets from './presets';

import Grid from '@mui/material/Grid';
import SearchesTable from './searches';

import { useSearchParams } from 'react-router-dom';

import { useUserStore } from '../../store';
import Account from './account';

const DeveloperDashboard = () => {
  let [params, setParams] = useSearchParams();

  const tab = params.get('tab');

  if (tab === null) {
    setParams({ tab: 'underwritings' });
  }

  const handleMenuClick = (button) => {
    setParams({ tab: button });
  };

  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const accountInfo = useUserStore((state) => state.accountInfo);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xl={2} lg={3} md={4} xs={12}>
          <IconMenu handleMenuClick={handleMenuClick} />
        </Grid>
        <Grid item xl={10} lg={9} md={8} xs={12}>
          {tab === 'account' && <Account />}
          {tab === 'underwritings' && (
            <SearchesTable isLoggedIn={isLoggedIn} accountInfo={accountInfo} />
          )}
          {tab === 'collections' && <Presets />}
        </Grid>
      </Grid>
    </>
  );
};

export default DeveloperDashboard;
