// import necessary modules and components
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/common/header';
import { Box, Button } from '@mui/material';

export default function Pricing() {
  return (
    <>
      <Header />
      <Box sx={{ padding: '15px 5%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minHeight: '100vh',
            gap: '50px'
          }}>
          <Box>
            <h1>REDtech Pricing</h1>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <Box sx={{ flexBasis: '20%' }}>
              <h2>Offering Memorandum</h2>
              <p>$29.99 per property - instant download</p>
              <Button disabled variant="contained" color="secondary" component={Link} to="/">
                View Example
              </Button>
            </Box>
            <Box sx={{ flexBasis: '80%' }}>
              <p>
                Upgrade your property investment presentations with REDtech's state-of-the-art,
                fully customizable AutoGenerated Offering Memorandum. Tailored to suit the needs of
                modern real estate investors and brokers, this sophisticated document streamlines
                the process of showcasing investment opportunities while providing comprehensive and
                accurate information.
              </p>
              <p>
                <strong>Key Features:</strong>
                <ul>
                  <li>
                    White-label customization: Personalize the offering memorandum with your own
                    branding, colors, and logo, ensuring a professional and cohesive appearance.
                  </li>
                  <li>
                    Comprehensive property analysis: Automatically generate a detailed analysis of
                    the property, including property description, location, market overview,
                    financial performance, and investment highlights.
                  </li>
                  <li>
                    High-quality visuals: Enhance your presentation with stunning, high-resolution
                    images, maps, and graphs that effectively convey key information and property
                    features.
                  </li>
                  <li>
                    Dynamic content: Rely on REDtech's advanced algorithms to automatically generate
                    persuasive and informative content, tailored to appeal to potential investors.
                  </li>
                  <li>
                    Time-saving convenience: Eliminate manual data entry and report creation by
                    simply inputting the property details and letting REDtech's technology do the
                    rest.
                  </li>
                  <li>
                    Print & digital-ready: Export your offering memorandum as a high-resolution PDF,
                    ready for print or sharing via email and secure online platforms.
                  </li>
                </ul>
              </p>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <Box sx={{ flexBasis: '20%' }}>
              <h2>Proforma</h2>
              <p>$39.99 per property - instant download</p>
              <Button disabled variant="contained" color="secondary" component={Link} to="/">
                View Example
              </Button>
            </Box>
            <Box sx={{ flexBasis: '80%' }}>
              <p>
                Streamline your property investment analysis with REDtech's cutting-edge
                AutoGenerated Proforma. Designed for modern real estate professionals, this powerful
                tool simplifies the financial forecasting process, allowing you to make informed
                decisions and present investment opportunities with confidence.
              </p>
              <p>
                <strong>Key Features:</strong>
                <ul>
                  <li>
                    White-label customization: Personalize the offering memorandum with your own
                    branding, colors, and logo, ensuring a professional and cohesive appearance.
                  </li>
                  <li>
                    Comprehensive financial projections: Automatically generate detailed revenue and
                    expense forecasts, including rental income, operating expenses, debt service,
                    and capital expenditures.
                  </li>
                  <li>
                    Customizable assumptions: Easily adjust key variables such as rent growth,
                    vacancy rates, and expense escalation to create tailored financial scenarios
                    that align with your specific investment strategy.
                  </li>
                  <li>
                    Sensitivity analysis: Assess the potential impact of changing market conditions
                    on your investment by running multiple scenarios with varying assumptions.
                  </li>
                  <li>
                    Clear visual presentation: Enhance your analysis with intuitive charts and
                    graphs that effectively communicate key financial metrics and trends to
                    investors and stakeholders.
                  </li>
                  <li>
                    Time-saving convenience: Eliminate manual data entry and calculations by simply
                    inputting the property details and letting REDtech's technology do the rest.
                  </li>
                  <li>
                    Print & digital-ready: Export your proforma as a high-resolution PDF or Excel
                    file, ready for print or sharing via email and secure online platforms.
                  </li>
                </ul>
              </p>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <Box sx={{ flexBasis: '20%' }}>
              <h2>REDtech Full Underwriting</h2>
              <p>$49.99 per property - instant access</p>
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to="/redtech/ae25b45c-8d0d-4101-85b9-0400e6ba210d">
                View Example
              </Button>
            </Box>
            <Box sx={{ flexBasis: '80%' }}>
              <p>
                Transform your real estate business with REDtech Full Underwriting, an all-in-one
                platform that combines our cutting-edge AutoGenerated Marketing Flyer, Offering
                Memorandum, and Proforma tools. With an intuitive interface, you can customize and
                generate professional-quality documents to showcase your property listings, analyze
                investment opportunities, and present your projects with confidence.
              </p>
              <p>
                <strong>Key Features:</strong>
                <ul>
                  <li>
                    Complete real estate package: Access our top-tier tools, including the
                    AutoGenerated Marketing Flyer, Offering Memorandum, and Proforma, all within a
                    single, user-friendly platform.
                  </li>
                  <li>
                    Customization interface: Tailor each project to your unique requirements with
                    our easy-to-use customization options.
                  </li>
                  <li>
                    High-quality visuals: Enhance your presentations with stunning, high-resolution
                    images that effectively convey key property features.
                  </li>
                  <li>
                    Dynamic content generation: Leverage REDtech's advanced algorithms to
                    automatically produce compelling property descriptions, comprehensive financial
                    projections, and investment analyses.
                  </li>
                  <li>
                    Pre-vetted industry partners: Connect with our network of trusted industry
                    partners for services such as construction, financing, property management, and
                    more, ensuring a seamless end-to-end experience.
                  </li>
                  <li>
                    Time-saving efficiency: Streamline your workflow by eliminating manual data
                    entry and design work, allowing you to focus on growing your real estate
                    business.
                  </li>
                </ul>
                Invest in REDtech Full Underwriting and experience the difference a cutting-edge,
                fully integrated real estate solution can make. Empower your business with the tools
                needed to impress clients, make informed decisions, and secure more deals in today's
                competitive real estate market.
              </p>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <Box sx={{ flexBasis: '20%' }}>
              <h2>Feasibility Study</h2>
              <p>$299.99 per property - 72 hour delivery</p>
              <Button disabled variant="contained" color="secondary" component={Link} to="/">
                View Example
              </Button>
            </Box>
            <Box sx={{ flexBasis: '80%' }}>
              <p>
                Stay ahead of the competition with REDtech's Express Real Estate Feasibility Study,
                a fast and thorough analysis of your property investment opportunities. With a
                72-hour turnaround time, our team of experts delivers an in-depth assessment of the
                property, including property analysis, floor plans, financials, and more – all to
                help you make informed decisions quickly and confidently.
              </p>
              <p>
                <strong>Key Features:</strong>
                <ul>
                  <li>
                    Rapid turnaround: Receive a comprehensive feasibility study within just 72
                    hours, ensuring you stay ahead in the fast-paced real estate market.
                  </li>
                  <li>
                    Expert property analysis: Benefit from a detailed assessment of the property's
                    current condition, potential improvements, and market positioning.
                  </li>
                  <li>
                    Custom floor plans: Visualize the property's layout and potential modifications
                    with professionally designed floor plans that cater to your specific
                    requirements.
                  </li>
                  <li>
                    Comprehensive financials: Review a complete financial analysis, including
                    projected revenues, expenses, cash flow, and key investment metrics to help you
                    evaluate the investment opportunity.
                  </li>
                  <li>
                    Market insight: Leverage our in-depth knowledge of local market trends and
                    comparable properties to accurately assess the property's potential value and
                    performance.
                  </li>
                  <li>
                    Unparalleled support: Customer service from our dedicated team, ready to address
                    any questions or concerns throughout the process.
                  </li>
                </ul>
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
