const constraints = {
  firstName: {
    presence: {
      allowEmpty: false
    },

    type: 'string'
  },

  lastName: {
    presence: {
      allowEmpty: false
    },

    type: 'string'
  },

  emailAddress: {
    email: {
      message: 'E-mail address is invalid'
    },

    presence: {
      allowEmpty: false
    },

    type: 'string'
  },

  password: {
    length: {
      minimum: 6
    },

    presence: {
      allowEmpty: false
    },

    type: 'string'
  },

  passwordConfirmation: {
    equality: 'password',

    length: {
      minimum: 6
    },

    presence: {
      allowEmpty: false
    },

    type: 'string'
  },

  betaCode: {
    type: 'string',
    presence: {
      allowEmpty: false
    }
  }
};

export default constraints;
