import React from 'react';

import Header from '../components/common/header';
import DeveloperDashboard from '../components/user/developerDashboard';
import Authentication from '../components/user/authentication';

import Alert from '@mui/material/Alert';

import { useUserStore } from '../store';

const Dashboard = () => {
  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const accountInfo = useUserStore((state) => state.accountInfo);

  return (
    <>
      <Header />
      <div className="secondary-container">
        {isLoggedIn && (
          <>
            <h1 className="dashboard-title">Dashboard</h1>
            {accountInfo.email}
            <DeveloperDashboard />
          </>
        )}

        {!isLoggedIn && (
          <>
            <div className="alerts">
              <Alert severity="error">Please sign in to view your dashboard</Alert>
            </div>
          </>
        )}
        <Authentication status={!isLoggedIn} type={'signin'} />
      </div>
    </>
  );
};

export default Dashboard;
