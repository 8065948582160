import React from 'react';

import Header from '../common/header';

import OnboardingForm from './onboardingForm';

export default function PartnerOnboarding() {
  return (
    <>
      <Header />
      <OnboardingForm />
    </>
  );
}
