import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import SignInForm from './forms/signinForm';
import SignUpForm from './forms/signupForm';
import ResetPasswordForm from './forms/passwordResetForm';

function Authentication(props) {
  const [status, setStatus] = useState(props.status);
  const [type, setType] = useState(props.type);

  useEffect(() => {
    setStatus(props.status);
    setType(props.type);
  }, [props.status, props.type]);

  const handleType = (value) => {
    setType(value);
  };

  const handleStatus = (value) => {
    setStatus(value);
  };

  return (
    <Dialog
      open={status}
      onClose={() => {
        setStatus(false);
      }}>
      <DialogTitle>User Authentication</DialogTitle>
      <DialogContent>
        <>
          {type === 'signin' && (
            <SignInForm handleStatus={handleStatus} setType={handleType} setStatus={handleStatus} />
          )}
          {type === 'signup' && (
            <SignUpForm handleStatus={handleStatus} setType={handleType} setStatus={handleStatus} />
          )}
          {type === 'reset' && (
            <ResetPasswordForm
              handleStatus={handleStatus}
              setType={handleType}
              setStatus={handleStatus}
            />
          )}
        </>
      </DialogContent>
    </Dialog>
  );
}

export default Authentication;
