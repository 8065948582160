import React, { Component } from 'react';

import Header from '../components/common/header';

import appStore from '../files/images/app/appStore.svg';
import qrCode from '../files/images/app/app.svg';

class Home extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="download">
          <div className="mobile-app-container">
            <div>
              <h1>Download Mobile Applications</h1>
              <div>
                <a
                  alt="ios app store"
                  href="https://apps.apple.com/us/app/madelon-redtech/id1549482076"
                >
                  <img className="store-image" alt="ios app store" src={appStore} />
                </a>
              </div>
              <div>
                <h2>Android Coming Soon</h2>
              </div>
            </div>
          </div>
          <div className="desktop-app-container">
            <div>
              <h1>Download Mobile Applications</h1>
              <div>
                <img className="store-image" alt="ios app store" src={qrCode} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
