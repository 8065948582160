import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import TagManager from 'react-gtm-module';

import Header from './components/common/header';

import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

const tagManagerArgs = {
  gtmId: 'GTM-M8DZKMB'
};

TagManager.initialize(tagManagerArgs);

const theme = createTheme({
  palette: {
    primary: {
      main: '#082827',
      contrastText: '#fff'
    },
    secondary: {
      main: '#eb4e32',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariant: false
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#082827',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#082827'
          }
        }
      },
      button: {
        '&:hover': {
          backgroundColor: '#082827',
          color: '#fff'
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: `0`
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
    },
    MuiCard: {
      root: {
        boxShadow: '5px 5px rgba(8,40,39,.2)'
      }
    },
    MuiFormLabel: {
      filled: {
        color: '#fff',
        '&:focused': {
          color: '#fff'
        },
        '&:hover': {
          color: '#fff'
        }
      }
    },
    MuiCheckbox: {
      root: {
        color: '#fff',
        '&:focused': {
          color: '#fff'
        },
        '&:hover': {
          color: '#fff'
        }
      }
    },
    MuiFilledInput: {
      root: {
        background: 'rgba(0,0,0,.7)',
        color: '#fff',
        border: '1px solid #fff',
        borderRadius: 0,
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.9)',
          color: '#fff'
        },
        '&:focus': {
          backgroundColor: 'rgba(0,0,0,.9)',
          color: '#fff'
        }
      }
    },
    MuiAppBar: {
      root: {
        marginBottom: 0,
        minHeight: '64px',
        justifyContent: 'center'
      }
    }
  }
});

const mantainanceMode = false;

ReactDOM.render(
  <div className="app-wrapper">
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        {mantainanceMode === true && (
          <>
            <Header />
            <div className="maintenance-mode">
              <h1>
                The site is currently undergoing maintenance. Please check back later, we expect the
                site to be functional within 2 hours!
              </h1>
            </div>
          </>
        )}
        {mantainanceMode === false && <App />}
      </MuiThemeProvider>
    </StyledEngineProvider>
  </div>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}

reportWebVitals();
