import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ListIcon from '@mui/icons-material/List';
import MapIcon from '@mui/icons-material/Map';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  CircularProgress,
  Select,
  MenuItem
} from '@mui/material';

import CustomGeocoder from '../search/customGeocoder';

import MapboxMap from '../search/map/userSearchesMap';

import AdminService from '../../services/admin.service';

import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { CSVLink } from 'react-csv';
import { Lock } from '@mui/icons-material';

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'date') {
    if (new Date(b.updatedAt) < new Date(a.updatedAt)) {
      return -1;
    }
    if (new Date(b.updatedAt) > new Date(a.updatedAt)) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Actions'
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: true,
    label: 'Address'
  },
  /*
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status'
  },
  
  {
    id: 'collection',
    numeric: false,
    disablePadding: true,
    label: 'Collection'
  },
  */
  {
    id: 'product',
    numeric: true,
    disablePadding: false,
    label: 'Product'
  },
  {
    id: 'cost',
    numeric: true,
    disablePadding: false,
    label: 'Acquisition Cost'
  },
  {
    id: 'size',
    numeric: true,
    disablePadding: false,
    label: 'Parcel Size'
  },
  {
    id: 'YOC',
    numeric: true,
    disablePadding: false,
    label: 'YOC'
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Last Updated'
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, onRequestSort, numSelected, rowCount } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts'
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default function SearchesTable(props) {
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [view, setView] = useState('list');

  const [parcelsGeo, setParcelsGeo] = useState([]);
  const [pointsGeo, setPointsGeo] = useState(null);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [csvList, setCsvList] = useState([]);
  const [filteredCsvList, setFilteredCsvList] = useState([]);

  const [archiveConfirmOpen, setArchiveConfirmOpen] = useState(false);

  const [viewport] = useState({
    longitude: -98.5795,
    latitude: 39.8283,
    pitch: 0,
    bearing: 0
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchSearches() {
      setLoading(true);
      await AdminService.getQueriesByUserId(props.accountInfo.id).then(
        (response) => {
          const allSearches = response.data;
          const allSearchesMinusArchived = allSearches.filter((a) => a.archived === false);
          const allSearchesMinusFavorited = allSearchesMinusArchived.filter(
            (a) => a.favorited === false || a.favorited === false
          );
          const favoritedSearches = allSearches.filter((a) => a.favorited === true);
          const sortedFavoritedSearches = favoritedSearches
            .sort((a, b) => {
              return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
            })
            .reverse();
          const sortedSearches = allSearchesMinusFavorited
            .sort((a, b) => {
              return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
            })
            .reverse();
          const allOrderedSearches = sortedFavoritedSearches.concat(sortedSearches);

          setRows(allOrderedSearches);

          var featuresArray = [];
          var pointsArray = [];
          var csvParcelsList = [];

          for (let i = 0; i < allOrderedSearches.length; i++) {
            csvParcelsList.push({
              id: allOrderedSearches[i].id,
              address: allOrderedSearches[i].address,
              latitude: allOrderedSearches[i].latitude,
              longitude: allOrderedSearches[i].longitude,
              zone: allOrderedSearches[i].parcel.zone_name,
              dda_name: allOrderedSearches[i].parcel.dda_name,
              qct_name: allOrderedSearches[i].parcel.qct_name,
              size: parseFloat(allOrderedSearches[i].parcel.size_num).toFixed(2),
              yoc: parseFloat(allOrderedSearches[i].yoc).toFixed(2),
              link: `https://redtech.app/redtech/${allOrderedSearches[i].id}`
            });
            featuresArray.push({
              id: allOrderedSearches[i].id,
              type: 'Feature',
              geometry: allOrderedSearches[i].parcel.geo,
              properties: allOrderedSearches[i]
            });
            pointsArray.push({
              id: allOrderedSearches[i].id,
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [
                  parseFloat(allOrderedSearches[i].parcel.longitude),
                  parseFloat(allOrderedSearches[i].parcel.latitude),
                  0
                ]
              },
              properties: allOrderedSearches[i]
            });
          }

          const parcelsObj = {
            type: 'FeatureCollection',
            features: featuresArray
          };

          const pointsObj = {
            type: 'FeatureCollection',
            features: pointsArray
          };

          setParcelsGeo(parcelsObj);
          setPointsGeo(pointsObj);
          setCsvList(csvParcelsList);
          setFilteredCsvList(csvParcelsList);
          setLoading(false);
        },
        (error) => {}
      );
    }
    fetchSearches();
  }, [props.accountInfo.id]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      handleSelectedTable(newSelected);
      return;
    }
    setSelected([]);
    handleSelectedTable([]);
  };

  const handleSelectedTable = (v) => {
    const filteredList = csvList.filter((a) => v.includes(a.id));
    setFilteredCsvList(filteredList);
  };

  const handleArchive = async (archiveConfirm) => {
    if (archiveConfirm === true) {
      var newRows = rows;
      for (let i = 0; i < selected.length; i++) {
        await AdminService.updateQueryFavArc(selected[i], false, true);
        newRows = newRows.filter((a) => a.id !== selected[i]);
      }
      setRows(newRows);
      setArchiveConfirmOpen(false);
      setSelected([]);
    } else {
      setArchiveConfirmOpen(true);
    }
  };

  const handleClose = () => {
    setArchiveConfirmOpen(false);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    handleSelectedTable(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const onHover = (hoveredFeature) => {
    setHoveredFeature({
      properties: hoveredFeature.properties,
      parcel: JSON.parse(hoveredFeature.properties.parcel)
    });
  };

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(10);

  let navigate = useNavigate();

  useEffect(() => {
    async function getCities() {
      const cities = await AdminService.getAllCities().then((response) => {
        return response.data
          .filter((city) => city.citykey !== null)
          .filter((city) => city.include === true || city.comingSoon === true)
          .sort((a, b) => a.name.localeCompare(b.name));
      });
      setCities(cities);
    }
    getCities();
  }, []);

  const handleCitySelect = (event) => {
    setSelectedCity(event.target.value);
    navigate(`/search?city=${event.target.value}`);
  };

  if (loading === true) {
    return (
      <div className="loading">
        <CircularProgress />
      </div>
    );
  } else if (props.isLoggedIn && rows.length === 0) {
    return (
      <Box>
        <h1>Get Started!</h1>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: { xs: '20px', md: '40px' }
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <p style={{ fontSize: '1em' }}>Search By Address</p>
            <CustomGeocoder />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: '300px',
              width: '30vw'
            }}>
            <p style={{ fontSize: '1em' }}>Search By City</p>
            {cities && (
              <Select
                sx={{ width: '100%' }}
                label="City"
                onChange={(e) => handleCitySelect(e)}
                value={selectedCity}
                defaultValue={10}>
                <MenuItem key={'none'} value={10}>
                  Select a City
                </MenuItem>
                {cities.map((city) => (
                  <MenuItem key={city.id} value={city.id}>
                    {city.name}, {city.state}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <>
        <div className="admin-search-header">
          <div className="admin-search-header-buttons">
            <Button size="large" variant="outlined" color="secondary" startIcon={<FileCopyIcon />}>
              <CSVLink className="csv-link" data={filteredCsvList}>
                Download CSV ({filteredCsvList.length})
              </CSVLink>
            </Button>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={() => handleArchive(false)}>
              Archive ({selected.length})
            </Button>
          </div>
          <div className="admin-search-header-buttons-hidden">
            <p>
              <strong>
                <span className="orange">{rows.length.toLocaleString()}</span> Results
              </strong>
            </p>
            <ToggleButtonGroup
              variant="contained"
              color="secondary"
              exclusive
              value={view}
              onChange={(e, v) => {
                setView(v);
              }}>
              <ToggleButton value="list">
                <ListIcon />
              </ToggleButton>
              <ToggleButton value="map">
                <MapIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <Box sx={{ width: '100%' }}>
          {view === 'list' && (
            <>
              <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}>
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {rows
                        .sort(getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  onClick={(event) => handleClick(event, row.id)}
                                  inputProps={{
                                    'aria-labelledby': labelId
                                  }}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="secondary"
                                  component={Link}
                                  to={`/redtech/${row.id}`}>
                                  View
                                </Button>
                              </TableCell>
                              <TableCell component="th" id={labelId} scope="row">
                                {row.address}
                              </TableCell>
                              {/*
                              <TableCell align="left">{row.deal_status}</TableCell>
                              
                              <TableCell align="left">
                                {row.collectionId ? row.collectionId : 'None'}
  
                              </TableCell>
                                */}
                              <TableCell align="right">
                                {row.productLine.charAt(0).toUpperCase() + row.productLine.slice(1)}
                              </TableCell>
                              <TableCell align="right">
                                $ {parseFloat(row.cost).toLocaleString()}
                              </TableCell>
                              <TableCell align="right">
                                {parseFloat(row.parcel.size).toLocaleString()} ft<sup>2</sup>
                              </TableCell>
                              <TableCell align="right">
                                {((row.products_purchased !== null &&
                                  row.products_purchased.includes('prod_Nebs4io9n5o4sZ')) ||
                                  props.accountInfo.roles.includes('ROLE_ADMIN')) &&
                                  parseFloat(row.yoc).toLocaleString() + '%'}
                                {(row.products_purchased === null ||
                                  !row.products_purchased.includes('prod_Nebs4io9n5o4sZ')) &&
                                  !props.accountInfo.roles.includes('ROLE_ADMIN') && <Lock />}
                              </TableCell>
                              <TableCell align="right">
                                <Moment date={row.updatedAt} format="MMMM DD, YYYY" />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (dense ? 33 : 53) * emptyRows
                          }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
              <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
              />
            </>
          )}
          {view === 'map' && (
            <>
              {hoveredFeature !== null && (
                <div className="admin-search-map-hover">
                  <p>{hoveredFeature.properties.address}</p>
                  <p>{hoveredFeature.parcel.zone_name}</p>
                  <ul>
                    <li>
                      Size: {parseFloat(hoveredFeature.parcel.size).toLocaleString()} ft
                      <sup>2</sup>
                    </li>
                    <li>
                      Max Buildable:{' '}
                      {parseFloat(hoveredFeature.parcel.max_buildable).toLocaleString()} ft
                      <sup>2</sup>
                    </li>
                    {((hoveredFeature.properties.products_purchased !== null &&
                      hoveredFeature.properties.products_purchased.includes(
                        'prod_Nebs4io9n5o4sZ'
                      )) ||
                      props.accountInfo.roles.includes('ROLE_ADMIN')) && (
                      <li>YOC: {parseFloat(hoveredFeature.properties.yoc).toFixed(3)} %</li>
                    )}
                  </ul>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to={`redtech/${hoveredFeature.properties.id}`}
                    target="_blank">
                    View
                  </Button>
                </div>
              )}
              <div className="admin-search-map">
                <MapboxMap
                  parcels={rows}
                  parcelsGeo={parcelsGeo}
                  pointsGeo={pointsGeo}
                  viewport={viewport}
                  onHover={onHover}
                />
              </div>
            </>
          )}
        </Box>
        <Dialog open={archiveConfirmOpen}>
          <DialogTitle>
            Are you sure you want to archive {selected.length} underwriting(s)? You will lose access
            to all purchases and data associated with this underwriting.
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" color="secondary" onClick={() => handleArchive(true)}>
              Confirm Archive
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
