import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import AdminService from '../../services/admin.service';
import LoadingSpinner from '../common/loadingSpinner';

import MapboxMap from '../search/map/collectionMapGoogle';

import { v4 as uuidv4 } from 'uuid';

import { Button } from '@mui/material';

import { Close } from '@mui/icons-material';

import { useUserStore } from '../../store';

export default function Collection(props) {
  let navigate = useNavigate();

  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const accountInfo = useUserStore((state) => state.accountInfo);

  const [collection, setCollection] = useState(null);
  const [parcels, setParcels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewport] = useState({
    longitude: -104.8759,
    latitude: 39.7621,
    pitch: 0,
    bearing: 0
  });

  const [parcelsGeo, setParcelsGeo] = useState([]);
  const [pointsGeo, setPointsGeo] = useState([]);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const collectionId = props.collectionId;

  useEffect(() => {
    async function getCollection() {
      var parcels = [];
      var parcelsGeoData = [];
      var pointsGeoData = [];
      const collectionData = await AdminService.getParcelCollection(collectionId).then(
        (response) => {
          setCollection(response.data);
          return response.data;
        }
      );

      if (collectionData.parcelIds !== null) {
        await AdminService.getAllParcelsInCollection(collectionData.parcelIds).then((response) => {
          parcels = response.data.parcels;
          parcelsGeoData = response.data.parcelsGeoData;
          pointsGeoData = response.data.pointsGeoData;
        });
      }

      setParcels(parcels);
      setParcelsGeo({
        type: 'FeatureCollection',
        features: parcelsGeoData
      });
      setPointsGeo({ type: 'FeatureCollection', features: pointsGeoData });
      setLoading(false);
    }
    getCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId]);

  const onHover = async (hoveredFeature) => {
    if (hoveredFeature === null) {
      setHoveredFeature(null);
      return;
    } else {
      const googlePlace = await AdminService.reverseGeocode(
        hoveredFeature.h.latitude,
        hoveredFeature.h.longitude
      );
      setHoveredFeature({ properties: hoveredFeature.h, googlePlace: googlePlace.data.results[0] });
    }
  };

  const handleAnalyze = async (userParcel) => {
    const product = await AdminService.getProductsByZoneId(userParcel.properties.zoneId);

    var pl = null;
    if (product.data.products.length > 1) {
      pl = product.data.products.find((p) => p.key === 'multifamily');
      if (pl === undefined) {
        pl = product.data.products.find((p) => p.key === 'lowrise');
      }
    } else {
      pl = product.data.products[0];
    }

    if (collectionId === 'c6b66fb1-3f8e-4dae-bf80-21fa242473e3') {
      pl = product.data.products.find((p) => p.key === 'microstudio');
    }

    var productLineKey = pl.key;

    const googlePlace = await AdminService.reverseGeocode(
      userParcel.properties.latitude,
      userParcel.properties.longitude
    );

    const queryId = uuidv4();
    var user = null;
    if (isLoggedIn) {
      user = accountInfo.id;
    } else {
      user = 'anonymous';
    }

    await AdminService.addQuery(
      queryId,
      googlePlace.data.results[0].formatted_address,
      userParcel.properties.zipcode,
      userParcel.properties.latitude,
      userParcel.properties.longitude,
      userParcel.properties.cityId,
      (60 * userParcel.properties.max_buildable).toFixed(0),
      null,
      user,
      productLineKey,
      null,
      googlePlace,
      userParcel.properties.id
    );

    var metadata = {
      zipcode: userParcel.properties.zipcode,
      address: googlePlace.data.results[0].formatted_address
    };
    window.Intercom('trackEvent', 'new-search', metadata);
    navigate(`/redtech/${queryId}`);
  };

  if (loading === true) {
    return <LoadingSpinner />;
  } else {
    return (
      <div style={{ marginTop: '15px' }}>
        <h1 style={{ margin: 0, padding: 0, fontSize: '1.5em', marginLeft: '15px' }}>
          {collection.name}
        </h1>
        <div>
          {loading && (
            <div className="loading-spinner">
              <LoadingSpinner />
            </div>
          )}
          <>
            <div style={{ marginLeft: '15px' }}>
              <p style={{ padding: 0, margin: 0 }}>
                <strong>
                  <span className="orange">{parcels.length.toLocaleString()}</span> Results
                </strong>
              </p>
            </div>
            <div className="collection-map">
              <MapboxMap
                parcels={parcels}
                parcelsGeo={parcelsGeo}
                pointsGeo={pointsGeo}
                viewport={viewport}
                onHover={onHover}
              />
              {hoveredFeature !== null && (
                <div className="admin-search-map-hover">
                  <div style={{ float: 'right', textAlign: 'right' }}>
                    <Button onClick={() => setHoveredFeature(null)}>
                      <Close />
                    </Button>
                  </div>
                  <h2 style={{ fontSize: '1em', marginTop: -15 }}>
                    {hoveredFeature.googlePlace.formatted_address}
                  </h2>
                  <ul>
                    <li>Zone: {hoveredFeature.properties.zone_name}</li>
                    <li>
                      Size: {parseFloat(hoveredFeature.properties.size).toLocaleString()} ft
                      <sup>2</sup> ({hoveredFeature.properties.width_num}ft x{' '}
                      {hoveredFeature.properties.depth_num}ft)
                    </li>
                  </ul>
                  {hoveredFeature.properties.cityId === '9718a1bc-988f-4c01-80ff-e6a493e75842' && (
                    <Button
                      disabled={!isLoggedIn}
                      variant="contained"
                      color="secondary"
                      onClick={() => handleAnalyze(hoveredFeature)}>
                      {isLoggedIn ? 'Analyze' : 'Signin to Analyze'}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </>
        </div>
      </div>
    );
  }
}
