import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';

import { useUserStore, useSnackbarStore } from './store';

import { Route, Routes } from 'react-router-dom';

import CryptoJS from 'crypto-js';

import Home from './pages/home';
import PasswordReset from './pages/passwordReset';
import Dashboard from './pages/dashboard';
import TermsAndConditions from './pages/tandc';
import PrivacyPolicy from './pages/privacyPolicy';
import ProductLines from './pages/productLines';
import Page404 from './pages/page404';
import Help from './pages/help';
import Contact from './pages/contact';
import RedTechTool from './components/redtech/redtechTool';
import Download from './pages/download';
import Impact from './pages/impact';
import News from './pages/news';

import Footer from './components/common/footer';
import Notifications from './components/common/notifications';

import ApplicableZones from './pages/applicableZones';
import AdvancedSearch from './pages/advancedSearch';
import Collection from './pages/collection';
import Partners from './pages/partners';
import Pricing from './pages/pricing';

import EmailVerification from './pages/emailVerification';

import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

import AdminService from './services/admin.service';

function generateClientToken(secret, user) {
  let hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret);
  hmac.update(user);
  let hash = hmac.finalize();
  return hash.toString(CryptoJS.enc.Hex);
}

function App() {
  const maintenanceMode = false;
  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const signinTimestamp = useUserStore((state) => state.signinTimestamp);
  const accountInfo = useUserStore((state) => state.accountInfo);
  const update = useUserStore((state) => state.update);
  const logout = useUserStore((state) => state.logout);
  const { open, message, severity, duration } = useSnackbarStore();

  useEffect(() => {
    if (isLoggedIn) {
      if (accountInfo.id === undefined) {
        logout();
      } else {
        AdminService.getUserInfo(accountInfo.id).then((res) => {
          update(res.data);
        });
        const hash = generateClientToken(
          'ml14u7H1oIy-mmsbDJFMNwYxMMcnkO8jqA1EOv3F',
          accountInfo.email
        );
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: 'uznikqm7',
          name: accountInfo.firstName + ' ' + accountInfo.lastName,
          email: accountInfo.email,
          user_hash: hash
        });
      }
    } else {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'uznikqm7'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo.id, isLoggedIn, update, signinTimestamp]);

  if (maintenanceMode === true) {
    return (
      <div className="fourohfour-container">
        <h1>
          We apologize the REDtech app is currently down for scheduled maintenance please try again
          in a few hours.
        </h1>
      </div>
    );
  } else {
    return (
      <>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/impact" element={<Impact />} />
            <Route exact path="/reset-password/:token" element={<PasswordReset />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/redtech/:queryId" element={<RedTechTool />} />
            <Route exact path="/redtech/:queryId/om" element={<RedTechTool />} />
            <Route exact path="/redtech/:queryId/flyer" element={<RedTechTool />} />
            <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/applicable-zones" element={<ApplicableZones />} />
            <Route exact path="/products" element={<ProductLines />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/help" element={<Help />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/download" element={<Download />} />
            <Route exact path="/search" element={<AdvancedSearch />} />
            <Route exact path="/collection/parcels/:collectionId" element={<Collection />} />
            <Route exact path="/auth/email/verify/:token" element={<EmailVerification />} />
            <Route exact path="/impact" element={<Impact />} />
            <Route exact path="/news" element={<News />} />
            <Route exact path="/partners" element={<Partners />} />
            <Route exact path="/partners/onboarding" element={<Partners />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Router>
        <Footer />
        <Notifications open={open} duration={duration} severity={severity} message={message} />
        <>
          <Dialog open={isLoggedIn && accountInfo.confirmed_email !== true}>
            <DialogTitle>Verify your email address</DialogTitle>
            <DialogContent>
              <p>
                We have sent you an email to verify your email address. Please check your inbox and
                click the link to verify your email address.
              </p>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  logout();
                  window.location.href = '/';
                }}>
                Signout
              </Button>
              <Button
                onClick={() => {
                  window.location.reload();
                }}
                color="secondary"
                variant="contained">
                I have confirmed
              </Button>
            </DialogActions>
          </Dialog>
        </>
      </>
    );
  }
}

export default App;
