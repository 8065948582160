import React, { useState } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { Capacitor } from '@capacitor/core';
import { GoogleLogin } from 'react-google-login';

import AuthService from '../../../services/auth.service';
import { useUserStore, useSnackbarStore } from '../../../store';

const SignInForm = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const login = useUserStore((state) => state.login);
  const { showSnackbar } = useSnackbarStore();

  const handleSignin = async (e) => {
    e.preventDefault();
    setLoading(true);

    await AuthService.login(email, password)
      .then((response) => {
        if (response.accessToken !== null) {
          login(response);
          props.handleStatus(false);
          showSnackbar(response.message, 'success', 3000);
        } else {
          showSnackbar(response.message, 'error', 5000);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setLoading(true);

    AuthService.sendResetLink(window.location.hostname, email)
      .then((response) => {
        showSnackbar(response.message, 'warning', 5000);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onGoogleSuccess = (response) => {
    AuthService.googleLogin(response.accessToken)
      .then((response) => {
        login(response);
        props.handleStatus(false);
        showSnackbar(response.message, 'success', 3000);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onGoogleFailure = (response) => {
    setLoading(false);
    console.log(response);
  };

  return (
    <div className="form-container">
      <Grid container direction="column">
        <Grid item xs={12} md={4}>
          <h3>Sign In</h3>
          <TextField
            autoComplete="email"
            disabled={loading}
            fullWidth
            label="E-mail address"
            required
            type="email"
            value={email}
            variant="outlined"
            InputLabelProps={{ required: true }}
            onChange={onChangeEmail}
            placeholder="E-mail address"
          />
          <TextField
            autoComplete="new-password"
            disabled={loading}
            fullWidth
            label="Password"
            required
            type="password"
            value={password}
            variant="outlined"
            InputLabelProps={{ required: true }}
            onChange={onChangePassword}
            placeholder="Password"
          />
        </Grid>
        <Button
          disabled={!email || !password || loading}
          color="secondary"
          variant="contained"
          onClick={handleSignin}>
          Sign In
        </Button>
        <p>or</p>
        {Capacitor.getPlatform() !== 'ios' && (
          <Grid item xs={12}>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT}
              buttonText="Sign in with Google"
              onSuccess={onGoogleSuccess}
              onFailure={onGoogleFailure}
              className="google-login-button"
              cookiePolicy={'single_host_origin'}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <p>
            Need to create an account?{' '}
            <Button color="secondary" onClick={() => props.setType('signup')}>
              Sign Up
            </Button>
          </p>
          <p>
            Need help signing in?{' '}
            <Button
              disabled={!email || loading}
              color="secondary"
              onClick={handleForgotPassword}
              to="">
              Forgot Password
            </Button>
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignInForm;
