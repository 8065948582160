import React from 'react';

import { useLocation } from 'react-router-dom';

import PartnerOnboarding from '../components/partners/onboarding';
import PartnerLanding from '../components/partners/landing';

export default function Partners(props) {
  const location = useLocation();
  const pathCheck = location.pathname.split('/');

  if (pathCheck[2] === 'onboarding') {
    return <PartnerOnboarding />;
  } else {
    return <PartnerLanding />;
  }
}
