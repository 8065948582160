import axios from 'axios';

import { API_URL } from '../config';

class LeadsService {
  submitForm(email, address, shareLink) {
    const webhookUrl =
      'https://hooks.slack.com/services/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';

    const data = {
      text: `New Lead: ${email} \n${address}\n${shareLink}`
    };

    axios.post(webhookUrl, JSON.stringify(data), {
      withCredentials: false,
      transformRequest: [
        (data, headers) => {
          delete headers.post['Content-Type'];
          return data;
        }
      ]
    });
  }

  sendEmail(from, email, subject, message) {
    return axios.post(API_URL + 'query-send-email', { from, email, subject, message });
  }

  addMondayFeedbackForm(id, email, date, time, likely, features, extra) {
    let query =
      'mutation ($name: String!, $columnVals: JSON!) { create_item (board_id:1322089274 item_name:$name, column_values:$columnVals) { id } }';
    let vars = {
      name: `${id}`,
      columnVals: JSON.stringify({
        email: { email: `${email}`, text: `${email}` },
        date4: { date: `${date}`, time: `${time}` },
        numbers3: `${likely}`,
        long_text: { text: `${features}` },
        long_text9: { text: `${extra}` }
      })
    };

    return fetch('https://api.monday.com/v2', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization:
          'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjExMDA3NDk0MiwidWlkIjoyMDEzMzA5NSwiaWFkIjoiMjAyMS0wNS0xNFQxODo1NDozMC42MjFaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6ODExMjM2NCwicmduIjoidXNlMSJ9.SrfTllhe_Uy-eEQNk_6JZy4kAS2dDyD1gnXVScCxPa8'
      },
      body: JSON.stringify({
        query: query,
        variables: JSON.stringify(vars)
      })
    })
      .then((res) => res.json())
      .then((res) => console.log(JSON.stringify(res, null, 2)));
  }
}
// eslint-disable-next-line
export default new LeadsService();
