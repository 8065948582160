import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import PartnerService from '../../services/partner.service';

import { useSnackbarStore } from '../../store';

const OnboardingForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    title: '',
    companyName: '',
    industry: '',
    location: '',
    website: '',
    other: ''
  });

  const industries = ['Financing', 'Manufacturing', 'Construction', 'Operations', 'Other'];

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const { showSnackbar } = useSnackbarStore();

  const handleSubmit = async () => {
    await PartnerService.partnerOnboardingRequest(formData).then((response) => {
      if (response.status === 200) {
        showSnackbar(
          'Thank you for your interest in partnering with us! We look forward to speaking with you soon',
          'success',
          5000
        );

        setFormData({
          name: '',
          email: '',
          title: '',
          companyName: '',
          industry: '',
          location: '',
          website: '',
          other: ''
        });
      } else {
        showSnackbar(
          'We are sorry your request was not processed. Please try again',
          'error',
          5000
        );
      }
    });

    console.log('Form data submitted:', formData);
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Partner Onboarding Form
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            name="name"
            label="Contact Name"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="email"
            label="Contact Email"
            type="email"
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="title"
            label="Contact Title"
            value={formData.title}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="companyName"
            label="Company Name"
            value={formData.companyName}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            select
            margin="normal"
            name="industry"
            label="Company Industry"
            value={formData.industry}
            onChange={handleChange}>
            {industries.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            margin="normal"
            name="location"
            label="Company Location"
            value={formData.location}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="website"
            label="Company Website"
            value={formData.website}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            margin="normal"
            name="other"
            label="Anything Else We Should Know?"
            value={formData.other}
            onChange={handleChange}
          />
          <Button
            disabled={
              formData.name === '' ||
              formData.email === '' ||
              formData.title === '' ||
              formData.companyName === '' ||
              formData.industry === '' ||
              formData.location === '' ||
              formData.website === ''
            }
            onClick={() => handleSubmit()}
            variant="contained"
            color="secondary">
            Submit
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default OnboardingForm;
