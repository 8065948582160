import React, { Component } from 'react';

import { RENDERINGS_URL } from '../config';

import Header from '../components/common/header';
import oduImage from '../files/images/products/odu.jpg';
import msImage from '../files/images/products/microstudios.jpg';
import mfImage from '../files/images/products/multifamily.jpg';
import lrImage from '../files/images/products/lowrise.jpg';
import highImage from '../files/images/products/high.jpg';
import mediumImage from '../files/images/products/medium.jpg';
import lowImage from '../files/images/products/low.jpg';

import { Grid, Button } from '@mui/material';

class ProductLines extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="products-wrapper" id="product-offerings">
          <h2>Product Offerings</h2>
          <Grid container spacing={12}>
            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6} order={{ xs: 1, sm: 1 }}>
                  <h1>Optimized Dwelling Unit (ODU)</h1>
                  <h5>New York</h5>
                  <p>
                    The Optimized Dwelling Unit or ODU is the original Madelon product, concieved to
                    help New York City developers increase their NOI while providing functional
                    housing at much more affordable rate than are generally found in NYC. By sharing
                    common spaces like living rooms and kitchens we are able to reduce development
                    costs and increase renter counts thereby increasing YOC.
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} order={{ xs: 2, sm: 2 }}>
                  <img className="search-image" src={oduImage} alt="search" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6} order={{ xs: 3, sm: 4 }}>
                  <h1>Microstudios</h1>
                  <h5>Denver</h5>
                  <p>
                    The Microstudios came about as an adaptation of the ODU with several
                    differences. This product is ideal for Denver as new legislation has allowed for
                    massing and unit counts to be very similar to the ODU product, but allows for
                    each renter to have a larger private space with their own kitching and living
                    space within their unit.
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} order={{ xs: 4, sm: 3 }}>
                  <img className="search-image" src={msImage} alt="search" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6} order={{ xs: 5, sm: 5 }}>
                  <h1>Lowrise</h1>
                  <h5>Los Angeles</h5>
                  <p>
                    Born specifically out of recent California legislation (SB-9) which allows
                    single family lots to be split and a home to be built on each half of the
                    property, in addition to loosening the rules pertaining to accessory dwelling
                    units (ADUs). With this product we are able to turn what would normally be a
                    single family lot into what is effectivly 6 rentable units.
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} order={{ xs: 6, sm: 6 }}>
                  <img className="search-image" src={lrImage} alt="search" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6} order={{ xs: 7, sm: 8 }}>
                  <h1>Traditional Multifamily</h1>
                  <h5>All Cities</h5>
                  <p>
                    In addition to all of the other products we currently offer, we are also able to
                    provide automated underwritings for traditional multifamily projects. Through
                    the process we provide a means for adjusting the unit mixes for the project from
                    all studios to all two bedrooms to any combination in between. REDtech
                    automatically adjusts the unit totals, rents, and floorplans to match your
                    desired unit mix.
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} order={{ xs: 8, sm: 7 }}>
                  <img className="search-image" src={mfImage} alt="search" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <p className="centered">
                Have your own product? Email us{' '}
                <a href="mailto:hello@madelongroup.com">hello@madelongroup.com</a> to discuss our
                process of whitelabeling REDtech for your organization.
              </p>
            </Grid>
          </Grid>
          <div className="market-fit" id="market-fit">
            <h2>Market Fit Options</h2>
            <Grid container spacing={12}>
              <Grid item xs={12}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <h1>Workforce</h1>
                    <p>
                      The Workforce Housing market fit option delivers a beautifully designed
                      product that is aimed specifically at providing more affordable housing to
                      memebers of the workforce. Typically this means that at least a fraction of
                      the total units will be offered at below market rate rents based upon
                      percentage of area median imcome (AMI).
                    </p>
                    <a href={`${RENDERINGS_URL}odu-low`} target="_blank" rel="noopener noreferrer">
                      <Button color="secondary" variant="outlined">
                        View Tour
                      </Button>
                    </a>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <img className="search-image" src={lowImage} alt="search" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <h1>Market Rate</h1>
                    <p>
                      This market fit option is traditionally where we would start the converation
                      for all products in all markets. Intentionally designed to offer the best
                      yield for the neighborhood's typical rents. In fact the REDtech platfrom
                      defaults to this option when initially underwriting a new project, however,
                      you always have the choice to look at differnt market fit options!
                    </p>
                    <a href={`${RENDERINGS_URL}odu-mid`} target="_blank" rel="noopener noreferrer">
                      <Button color="secondary" variant="outlined">
                        View Tour
                      </Button>
                    </a>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <img className="search-image" src={mediumImage} alt="search" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <h1>Luxe</h1>
                    <p>
                      Our Luxe Housing market fit option is perfect for those very upscale
                      neighborhoods where a luxury product is not only the norm but is what the
                      market is demanding. This market fit option is best for those areas where
                      higher rents are acceptable and the completed project will still see normal
                      occupancy rates.
                    </p>
                    <a href={`${RENDERINGS_URL}odu-high`} target="_blank" rel="noopener noreferrer">
                      <Button color="secondary" variant="outlined">
                        View Tour
                      </Button>
                    </a>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <img className="search-image" src={highImage} alt="search" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

export default ProductLines;
