import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useUserStore } from '../../store';

import logoWhite from '../../files/images/logoWhite.png';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';

import Button from '@mui/material/Button';

import { Device } from '@capacitor/device';

import Authentication from '../user/authentication';
import { Search } from '@mui/icons-material';

import HeaderMenu from './headerMenu';

const Header = () => {
  const [marginTop, setMarginTop] = useState('0px');
  const isLoggedIn = useUserStore((state) => state.isLoggedIn);

  const [state, setState] = useState({
    status: false,
    type: ''
  });

  useEffect(() => {
    const fetchDeviceInfo = async () => {
      const info = await Device.getInfo();
      if (info.platform === 'ios') {
        setMarginTop('50px');
      }
    };
    fetchDeviceInfo();
  }, []);

  return (
    <AppBar elevation={0} position="sticky" sx={{ paddingTop: marginTop, zIndex: 10000 }}>
      <Toolbar>
        <div className="nav-logo-container">
          <Link to="/">
            <img className="nav-logo" src={logoWhite} alt="Logo" />
          </Link>
        </div>
        <div className="nav-buttons">
          {!isLoggedIn && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'flex-end'
              }}>
              <Tooltip title="Sign In">
                <Button
                  onClick={(prevState) => {
                    setState({ ...prevState, status: true, type: 'signin' });
                  }}
                  color="secondary"
                  variant="contained">
                  Sign in
                </Button>
              </Tooltip>
              <Tooltip title="Sign Up">
                <Button
                  size="medium"
                  onClick={(prevState) => {
                    setState({ ...prevState, status: true, type: 'signup' });
                  }}
                  color="inherit"
                  variant="outlined">
                  Free Trial
                </Button>
              </Tooltip>
            </Box>
          )}
          {isLoggedIn && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'flex-end'
              }}>
              <Tooltip title="Dashboard">
                <Button
                  color="secondary"
                  variant="contained"
                  component={Link}
                  to={'/search'}
                  startIcon={<Search />}>
                  Search
                </Button>
              </Tooltip>
              <HeaderMenu />
            </Box>
          )}
        </div>
      </Toolbar>
      <Authentication status={state.status} type={state.type} />
    </AppBar>
  );
};

export default Header;
