import { create } from 'zustand';

export const useUserStore = create((set, get) => {
  const savedAccountInfo = localStorage.getItem('accountInfo');
  const savedIsLoggedIn = !!savedAccountInfo;

  return {
    isLoggedIn: savedIsLoggedIn,
    accountInfo: JSON.parse(savedAccountInfo) || {},
    signinTimestamp: localStorage.getItem('siginTimestamp') || '',
    update: (account) => {
      localStorage.setItem('accountInfo', JSON.stringify(account));

      set({ accountInfo: { ...account } });
    },
    login: (account) => {
      localStorage.setItem('accountInfo', JSON.stringify(account));
      localStorage.setItem('signinTimestamp', account.signinTimestamp);
      set({
        isLoggedIn: true,
        accountInfo: { ...account },
        signinTimestamp: account.signinTimestamp
      });
    },
    logout: () => {
      localStorage.removeItem('accountInfo');
      set({ isLoggedIn: false, accountInfo: {} });
      window.Intercom('shutdown');
    }
  };
});

export const useSnackbarStore = create((set) => ({
  open: false,
  message: '',
  severity: 'info',
  duration: 0,
  showSnackbar: (message, severity, duration) => set({ open: true, message, severity, duration }),
  closeSnackbar: () => set({ open: false })
}));
