import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useUserStore } from '../store';

import { RENDERINGS_URL } from '../config';

import Iframe from 'react-iframe';

import { Device } from '@capacitor/device';

import logoHead from '../files/images/logoGreen.png';
import searchVideo from '../files/videos/search.mp4';
import underwriteVideo from '../files/videos/underwriting.mp4';
import searchVideoThumb from '../files/videos/searchThumb.png';
import underwriteVideoThumb from '../files/videos/underwritingThumb.png';

import stacksourceImage from '../files/images/home/logos/Stacksource.png';
import prosetImage from '../files/images/home/logos/PROSET.png';
import maisonImage from '../files/images/home/logos/Maison.png';

import appStore from '../files/images/app/appStore.svg';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { HashLink } from 'react-router-hash-link';

import { withCookies, Cookies } from 'react-cookie';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  IconButton
} from '@mui/material';

import HomepageSplash from '../components/common/homepageSplash';
import HomepageSelector from '../components/common/homepageSelector';

import Slider from '../components/redtech/shared/homeSlider';

import { InlineWidget } from 'react-calendly';

import { Capacitor } from '@capacitor/core';

import { instanceOf } from 'prop-types';

import Authentication from '../components/user/authentication';

const Home = (props) => {
  const { cookies } = props;
  const [state, setState] = useState({
    currentUser: null,
    menuOpen: false,
    googlePlace: null,
    zipcode: null,
    openAppDialog: false,
    openTourDialog: false,
    renderingURL: `${RENDERINGS_URL}/odu-mid`,
    finish: 'mid',
    scrolled: false,
    marginTop: '0px'
  });

  const [status, setStatus] = useState(false);
  const [type, setType] = useState('');

  const handleType = (value) => {
    setType(value);
  };

  const handleStatus = (value) => {
    setStatus(value);
  };

  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const accountInfo = useUserStore((state) => state.accountInfo);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isLoggedIn) {
      setState((prevState) => ({ ...prevState, currentUser: accountInfo }));
    }

    setState((prevState) => ({ ...prevState, width: window.innerWidth }));

    window.addEventListener('resize', () => {
      setState((prevState) => ({ ...prevState, width: window.innerWidth }));
    });

    if (
      Capacitor.getPlatform() === 'web' &&
      window.innerWidth < 768 &&
      !isLoggedIn &&
      cookies.get('appPopup') !== 'true'
    ) {
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, openAppDialog: true }));
      }, 3000);
    }

    Device.getInfo().then((info) => {
      if (info.platform === 'ios') {
        setState((prevState) => ({ ...prevState, marginTop: '50px' }));
      }
    });
  }, [isLoggedIn, accountInfo, props.user, cookies]);

  const toggleMenu = () => {
    setState((prevState) => ({ ...prevState, menuOpen: !prevState.menuOpen }));
  };

  const handleOpenTour = async () => {
    setState((prevState) => ({ ...prevState, openTourDialog: true }));
  };

  const handleDialogClose = () => {
    setState((prevState) => ({
      ...prevState,
      openAppDialog: false,
      openTourDialog: false,
      appPopup: cookies.set('appPopup', 'true', { path: '/' }),
      acceptedCookies: cookies.set('acceptCookies', 'true', { path: '/' })
    }));
  };

  const handleChangeFinish = (tier) => {
    if (tier === 'low') {
      setState((prevState) => ({
        ...prevState,
        renderingURL: `${RENDERINGS_URL}/odu-low`,
        finish: 'low'
      }));
    } else if (tier === 'high') {
      setState((prevState) => ({
        ...prevState,
        renderingURL: `${RENDERINGS_URL}/odu-high`,
        finish: 'high'
      }));
    } else if (tier === 'mid') {
      setState((prevState) => ({
        ...prevState,
        renderingURL: `${RENDERINGS_URL}/odu-mid`,
        finish: 'mid'
      }));
    }
  };

  return (
    <div className="home">
      <div className="home-header" style={{ paddingTop: state.marginTop }}>
        <Box sx={{ flexGrow: 1 }}>
          <Toolbar>
            <Link to="/" style={{ flex: 1 }}>
              <img className="home-header-logo" src={logoHead} alt="Logo" />
            </Link>
            {state.width > 768 && (
              <>
                {state.currentUser !== null && (
                  <div
                    className="home-header-buttons"
                    style={{ flex: 3, justifyContent: 'flex-end', alignItems: 'stretch' }}>
                    <Button
                      size="large"
                      variant="outlined"
                      color="primary"
                      component={Link}
                      to={'/dashboard?tab=underwritings'}>
                      Dashboard
                    </Button>
                  </div>
                )}
                {state.currentUser === null && (
                  <div
                    className="home-header-buttons"
                    style={{ flex: 3, justifyContent: 'flex-end', alignItems: 'stretch' }}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setStatus(true);
                        setType('signup');
                      }}>
                      Free Trial
                    </Button>
                    <Button
                      size="large"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setStatus(true);
                        setType('signin');
                      }}>
                      Sign In
                    </Button>
                  </div>
                )}
              </>
            )}
            {state.width <= 768 && (
              <>
                {!state.menuOpen && (
                  <div className="mobile-menu">
                    <IconButton size="medium" onClick={() => toggleMenu()} color="primary">
                      <MenuIcon />
                    </IconButton>
                  </div>
                )}
                {state.menuOpen && (
                  <div className="header-menu-mobile">
                    <IconButton size="medium" onClick={() => toggleMenu()} color="primary">
                      <CloseIcon />
                    </IconButton>
                    {state.currentUser !== null && (
                      <>
                        <Button
                          size="large"
                          variant="outlined"
                          color="primary"
                          component={Link}
                          to={'/dashboard?tab=underwritings'}>
                          Dashboard
                        </Button>
                      </>
                    )}
                    {state.currentUser === null && (
                      <>
                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setStatus(true);
                            setType('signup');
                          }}>
                          Free Trial
                        </Button>
                        <Button
                          size="large"
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setStatus(true);
                            setType('signin');
                          }}>
                          Sign In
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </Toolbar>
        </Box>
      </div>

      <div className="home-splash-wrapper">
        <HomepageSplash />
      </div>

      <div className="home-main-wrapper">
        <Grid container spacing={{ xs: 6, sm: 12 }}>
          <Grid item xs={12} md={12}>
            <Box>
              <h1 className="centered">The REDtech Process</h1>
              <Box sx={{ margin: '0 auto', width: { xs: '100%', md: '60%' } }}>
                <p>Stop reinventing the wheel for every project.</p>
                <p>
                  Madelon has productized development, meaning we balanced customization and
                  standardization to make ground up residential projects more repeatable therefore
                  reducing risk and shortening timelines.
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 1 }}>
            <h2>Search</h2>
            <p>
              Find properties by zipcode or address. Currently REDtech is live in New York City,
              Denver, and Los Angeles. Additionally REDtech search is in Beta in Chicago, Austin,
              and Minneapolis.
            </p>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 2 }}>
            <video src={searchVideo} poster={searchVideoThumb} playsInline controls={true} />
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 4, md: 3 }}>
            <video
              src={underwriteVideo}
              poster={underwriteVideoThumb}
              playsInline
              controls={true}
            />
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 3, md: 4 }}>
            <h2>Underwrite</h2>
            <p>REDtech provides a detailed underwriting model for each property.</p>
            <p>
              You can customize assumptions, download resources like an offering memorandum or a
              proforma. And finally you can order a feasibility study that provides you with
              everything you need to know to get started with the project.
            </p>
          </Grid>
          <Grid item xs={12} order={{ xs: 5 }} id="selector">
            <HomepageSelector />
          </Grid>
          <Grid item xs={12} sm={12} order={{ xs: 7, sm: 7 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2 className="centered">Our Partners</h2>
              </Grid>
              <Grid item xs={12} md={4}>
                <a href="https://www.stacksource.com/">
                  <img className="search-image" src={stacksourceImage} alt="stacksource" />
                </a>
              </Grid>
              <Grid item xs={12} md={4}>
                <a href="http://www.prosetmodular.com/">
                  <img className="search-image" src={prosetImage} alt="proset" />
                </a>
              </Grid>
              <Grid item xs={12} md={4}>
                <a href="https://maisonmeanshome.com/">
                  <img className="search-image" src={maisonImage} alt="maison" />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="home-main-wrapper dark-background">
        <Grid container spacing={{ xs: 4, md: 12 }}>
          <Grid item xs={12} sm={7} md={8}>
            <h1>Schedule a Demo</h1>
            <p>
              You can start underwriting today for free here! Need more convincing? We are happy to
              schedule a quick demo to walk you through some of the incredible things REDtech can
              do.
            </p>
            <p>
              If you would like to speak to us about anything else please contact us{' '}
              <a href="mailto:hello@madelongroup.com">hello@madelongroup.com</a>!
            </p>
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
            <InlineWidget url="https://calendly.com/madelongroup-sean/discovery" />
          </Grid>
        </Grid>
      </div>
      <div className="home-main-wrapper centered">
        <h1>What are they saying?</h1>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 4, sm: 8, md: 4 }}
          justifyContent="center"
          alignItems="center">
          <Grid item xs={12} sm={3}>
            <a
              href="https://www.denverpost.com/2022/03/31/denvers-micro-apartment-trend-santa-fe-arts-district/"
              target="_blank"
              rel="noreferrer">
              <img
                className="article-image"
                src="https://www.denverpost.com/wp-content/uploads/2020/11/dp-logo-1.svg"
                alt="dp"
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={3}>
            <a
              href="https://www.nbcnews.com/tech/tech-news/tech-companies-are-already-flipping-houses-can-build-rcna12982"
              target="_blank"
              rel="noreferrer">
              <svg
                className="article-image"
                viewBox="578 62 67 39"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor">
                <g fillRule="evenodd">
                  <path d="M579.074 90.58c1.717-4.494 6.472-6.08 10.697-3.37l18.29 12.754h-22.18c-4.62 0-8.252-4.23-6.8-9.385"></path>
                  <path
                    className="feather"
                    d="M579.074 90.58c1.717-4.494 6.472-6.08 10.697-3.37l18.29 12.754h-22.18c-4.62 0-8.252-4.23-6.8-9.385"></path>
                  <path
                    className="feather"
                    d="M597.563 75.863l9.442 21.613-19.478-13.35c-4.887-3.24-3.896-8.79-1.19-11.237 3.83-3.24 9.245-2.12 11.226 2.97"></path>
                  <path
                    className="feather"
                    d="M610.57 70.93l-3.433 24.19-9.772-23c-2.113-5.75 2.64-9.516 5.81-9.583 3.632-.396 8.188 2.38 7.396 8.394"></path>
                  <path
                    className="feather"
                    d="M625.494 72.103l-9.707 23.067-3.102-20.82c-.265-3.17 1.716-3.237 2.443-3.832-.133-.463-1.124-.463-1.124-.463h-1.848c-.198-5.42 4.028-7.8 7.462-7.535 3.697.132 7.856 4.428 5.876 9.583"></path>
                  <path
                    className="feather"
                    d="M635.338 84.167L615.86 97.584l9.177-21.348c2.775-6.742 9.245-5.42 11.556-2.842 2.51 2.51 3.235 7.6-1.255 10.773"></path>
                  <path
                    className="feather"
                    d="M637.448 99.9h-22.515l18.29-12.756c4.49-2.975 9.244-.266 10.498 3.635 1.327 4.36-1.65 9.12-6.27 9.12"></path>
                </g>
              </svg>
            </a>
          </Grid>
          <Grid item xs={12} sm={3}>
            <a
              href="https://www.inman.com/2022/02/08/with-redtech-madelon-group-is-betting-big-on-modular-multifamily/"
              target="_blank"
              rel="noreferrer">
              <img
                className="article-image"
                src="https://webassets.inman.com/files/static-images/logos/inman-logo.svg"
                alt="inman"
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={3}>
            <a
              href="https://www.wealthmanagement.com/multifamily/could-productizing-multifamily-development-help-solve-housing-crisis"
              target="_blank"
              rel="noreferrer">
              <img
                className="article-image"
                src="https://www.wealthmanagement.com/sites/all/themes/penton_subtheme_wealthmanagement/images/logos/header.png"
                alt="dp"
              />
            </a>
          </Grid>
        </Stack>
      </div>
      <div className="home-main-wrapper">
        <h1>Take a Peek</h1>
        <Button size="large" variant="outlined" color="primary" onClick={() => handleOpenTour()}>
          Take a Virtual Tour
        </Button>
        <div className="home-slider">
          <Slider />
        </div>
      </div>
      <div className="home-main-wrapper">
        <Grid container spacing={12} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <h1 className="centered">Still have questions? Get in touch!</h1>
            <p className="centered">
              Email us at <a href="mailto:hello@madelongroup.com">hello@madelongroup.com</a>.
            </p>
          </Grid>
        </Grid>
      </div>
      <div>
        <Dialog open={state.openAppDialog} onClose={handleDialogClose}>
          <DialogTitle id="form-dialog-title">Try Our Mobile App</DialogTitle>
          <DialogContent>
            <div className="store-popup">
              <a
                alt="ios app store"
                href="https://apps.apple.com/us/app/madelon-redtech/id1549482076">
                <img alt="ios app store" src={appStore} />
              </a>
            </div>
            <div className="store-popup">Android Coming Soon</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary">
              Dismiss
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={state.openTourDialog} onClose={handleDialogClose} fullScreen>
          <DialogTitle id="form-dialog-title">Virtual Tour</DialogTitle>
          <DialogContent>
            {window.innerWidth < 800 && (
              <>
                <p>Select the product you would like to tour!</p>
                <div className="tour-links">
                  <a href={`${RENDERINGS_URL}odu-low`} target="_blank" rel="noopener noreferrer">
                    <Button color="secondary" variant="outlined">
                      Workforce
                    </Button>
                  </a>
                  <a href={`${RENDERINGS_URL}odu-mid`} target="_blank" rel="noopener noreferrer">
                    <Button color="secondary" variant="outlined">
                      Market Rate
                    </Button>
                  </a>
                  <a href={`${RENDERINGS_URL}odu-high`} target="_blank" rel="noopener noreferrer">
                    <Button color="secondary" variant="outlined">
                      Luxe
                    </Button>
                  </a>
                  <Button
                    color="secondary"
                    variant="contained"
                    component={HashLink}
                    smooth
                    to={'/products#market-fit'}>
                    View Option Descriptions
                  </Button>
                </div>
              </>
            )}
            {window.innerWidth >= 800 && (
              <>
                <Iframe
                  url={state.renderingURL}
                  width="100%"
                  height="85%"
                  id="threedvisualinterior"
                  frameBoarder="0"
                  style={{ transform: [{ rotate: '90deg' }] }}
                />
                <div>
                  <List className="redtech-tool-menu">
                    <ListItem
                      button={true}
                      key={'low'}
                      value={'low'}
                      selected={'low' === state.finish}
                      onClick={() => handleChangeFinish('low')}>
                      Workforce
                    </ListItem>
                    <ListItem
                      button={true}
                      key={'mid'}
                      value={'mid'}
                      selected={'mid' === state.finish}
                      onClick={() => handleChangeFinish('mid')}>
                      Market Rate
                    </ListItem>
                    <ListItem
                      button={true}
                      key={'high'}
                      value={'high'}
                      selected={'high' === state.finish}
                      onClick={() => handleChangeFinish('high')}>
                      Luxe
                    </ListItem>
                  </List>
                </div>
                <div className="mt-3">
                  <Button
                    color="secondary"
                    variant="contained"
                    component={HashLink}
                    smooth
                    to={'/products#market-fit'}>
                    View Option Descriptions
                  </Button>
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary" variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Authentication
        status={status}
        type={type}
        handleStatus={handleStatus}
        handleType={handleType}
      />
    </div>
  );
};

Home.propTypes = {
  cookies: instanceOf(Cookies).isRequired
};

export default withCookies(Home);
