import React, { useEffect, useState } from 'react';
import AdminService from '../services/admin.service';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';

//import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

import { CSVLink } from 'react-csv';
import { v4 as uuidv4 } from 'uuid';

import { useJsApiLoader } from '@react-google-maps/api';

//material-ui
import {
  Select,
  MenuItem,
  Button,
  TextField,
  InputAdornment,
  Divider,
  Checkbox,
  Switch,
  FormControlLabel,
  ToggleButtonGroup,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  ToggleButton,
  Autocomplete,
  Drawer,
  Box
} from '@mui/material';

import { styled } from '@mui/material/styles';

import ListIcon from '@mui/icons-material/List';
import MapIcon from '@mui/icons-material/Map';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

//components
import EnhancedTable from '../components/search/util/table';
import Header from '../components/common/header';
import MapboxMap from '../components/search/map/advancedSearchMapGoogle';
import LoadingSpinner from '../components/common/loadingSpinner';
import { ChevronRight, Close } from '@mui/icons-material';
import CustomGeocoder from '../components/search/customGeocoder';

import { useUserStore, useSnackbarStore } from '../store';

import Authentication from '../components/user/authentication';

import { Device } from '@capacitor/device';

const libraries = ['places'];

export default function AdminParcelSearch() {
  let [query, setQuery] = useSearchParams();

  let navigate = useNavigate();
  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const accountInfo = useUserStore((state) => state.accountInfo);
  const { showSnackbar } = useSnackbarStore();

  const [marginTop, setMarginTop] = useState('64px');

  useEffect(() => {
    const fetchDeviceInfo = async () => {
      const info = await Device.getInfo();
      if (info.platform === 'ios') {
        setMarginTop('114px');
      }
    };
    fetchDeviceInfo();
  }, []);

  //const [googlePlace, setGooglePlace] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [products, setProducts] = useState([]);
  const [parcels, setParcels] = useState([]);
  const [cityInfo, setCityInfo] = useState(null);
  const [sizemin, setSizeMin] = useState(5000);
  const [sizemax, setSizeMax] = useState(10000);
  const [totalDevelopmentMax, setTotalDevelopmentMax] = useState();
  const [selectedUtilization, setUtilization] = useState(25);
  const [price, setPrice] = useState(40);
  const [hardCost, setHardCost] = useState(330);
  const [softCost, setSoftCost] = useState(70);
  const [financingCost, setFinancingCost] = useState(40);
  const [ami, setAMI] = useState(100);
  const [yoc, setYOC] = useState(6);
  const [unitSize, setUnitSize] = useState(725);
  const [ox, setOX] = useState(30);
  const [sizeChecked, setSizeChecked] = useState(true);
  const [tdcChecked, setTDCChecked] = useState(false);
  const [utilizationChecked, setUtilizationChecked] = useState(true);
  const [yocChecked, setYOCChecked] = useState(false);
  const [cityOwnedOnly, setCityOwnedOnly] = useState(false);
  const [toc, setTOC] = useState(null);
  const [tcac, setTCAC] = useState(null);
  const [filterDDA, setFilterDDA] = useState(false);
  const [filterQCT, setFilterQCT] = useState(false);
  const [maxBuildableChecked, setMaxBuildableChecked] = useState(false);
  const [maxBuildableMax, setMaxBuildableMax] = useState(null);
  const [maxBuildableMin, setMaxBuildableMin] = useState(null);

  const [presetValues, setPresetValues] = useState(null);

  const [zoneList, setZoneList] = useState([]);
  const [selectedZoneFeatures, setSelectedZoneFeatures] = useState([]);

  const [parcelsGeo, setParcelsGeo] = useState({ type: 'FeatureCollection', features: [] });
  const [pointsGeo, setPointsGeo] = useState(null);

  const [selectedZipcodes, setSelectedZipcodes] = useState([]);
  const [zipcodeFeatures, setZipcodeFeatures] = useState({ features: [] });
  const [selectedZipcodeFeatures, setSelectedZipcodeFeatures] = useState([]);

  const [selectedZones, setSelectedZones] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [presetsName, setPresetsName] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [presets, setPresets] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState('');

  const [utilizationOperator, setUtilizationOperator] = useState('value');

  const [searchStarted, setSearchStarted] = useState(false);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [csvParcels, setCSVParcels] = useState([]);

  const drawerWidth = { xs: '100%', md: '25%' };
  const [open, setOpen] = useState(true);

  const [filteredCSVList, setFilteredCsvList] = useState([]);

  const [viewport, setViewport] = useState({
    longitude: -98.5795,
    latitude: 39.8283,
    pitch: 0,
    bearing: 0
  });

  const [disabled, setDisabled] = useState(false);

  const [userSearches, setUserSearches] = useState([]);
  const [existsDialogOpen, setExistsDialogOpen] = useState(false);

  const [signinDialogOpen, setSigninDialogOpen] = useState(false);

  const [preset, setPreset] = useState({
    view: 'map',
    selectedCity: '0eb77cb6-1a83-48d6-a879-778a1320e749',
    selectedZones: '',
    hardCost: '',
    softCost: '',
    price: '',
    financingCost: '',
    ami: '',
    yoc: '',
    unitSize: '',
    ox: '',
    selectedUtilization: '',
    totalDevelopmentMax: '',
    sizemin: '',
    sizemax: '',
    utilizationChecked: false,
    tdcChecked: false,
    sizeChecked: false,
    yocChecked: false,
    selectedProduct: '0f2f45c8-e1b2-412c-add2-989e1e992971',
    cityOwnedOnly: false,
    filterQCT: false,
    filterDDA: false,
    toc: '',
    tcac: '',
    selectedZipcodes: [],
    selectedZipcodeFeatures: []
  });

  useEffect(() => {
    async function getCities() {
      const cities = await AdminService.getAllCities().then((response) => {
        return response.data
          .filter((city) => city.citykey !== null)
          .filter((city) => city.include === true || city.comingSoon === true)
          .sort((a, b) => a.name.localeCompare(b.name));
      });
      setCities(cities);
    }
    async function getProducts() {
      var products = await AdminService.getProducts().then((response) => {
        if (isLoggedIn && accountInfo.roles.includes('ROLE_ADMIN')) return response.data;
        else return response.data.filter((product) => product.showLive === true);
      });
      setProducts(products);
    }

    async function getPresets() {
      const val = query.get('collection');
      const cityParam = query.get('city');
      if (cityParam !== null) {
        setPreset((prevState) => ({
          ...prevState,
          selectedCity: cityParam
        }));
      }
      if (val) {
        setOpen(false);
        const presetResponse = await AdminService.getPreset(val).then((response) => {
          setPreset((prevState) => ({
            ...prevState,
            selectedCity: response.data.city
          }));
          return response.data;
        });
        await AdminService.getCityZipcodes(presetResponse.city).then((response) => {
          setZipcodeFeatures(response.data);
        });
        handlePresetSelectURL(val, presetResponse);
      }
      if (isLoggedIn) {
        var sitePresets = [];
        await AdminService.getSitePresets().then((response) => {
          sitePresets = response.data;
        });
        await AdminService.getAllPresets(accountInfo.id).then((response) => {
          setPresets([
            ...sitePresets,
            ...response.data
              .filter((preset) => preset.archived === false)
              .sort((a, b) => {
                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
              })
              .reverse()
          ]);
        });
      }
    }

    getCities();
    getProducts();
    getPresets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo.id]);

  useEffect(() => {
    async function getZips(city) {
      setSelectedZipcodes([]);
      setSelectedZipcodeFeatures([]);
      setParcels([]);
      setCSVParcels([]);
      setFilteredCsvList([]);
      setParcelsGeo({ type: 'FeatureCollection', features: [] });
      setPointsGeo(null);
      setZoneList([]);
      setSelectedZoneFeatures([]);
      await AdminService.getCityZipcodes(city.id).then((response) => {
        setZipcodeFeatures(response.data);
      });
    }
    const city = cities.find((city) => city.id === preset.selectedCity);

    if (city && city.comingSoon === true) {
      setDisabled(true);
      setUtilizationChecked(false);
    }

    setCityInfo(city);

    if (city) {
      setViewport({
        longitude: city.longitude,
        latitude: city.latitude,
        pitch: 0,
        bearing: 0
      });

      getZips(city);
    }
  }, [preset.selectedCity, cities]);

  //this is being called twice need to figure out why
  useEffect(() => {
    if (
      preset.selectedCity !== null &&
      selectedZipcodes.length > 0 &&
      preset.selectedProduct !== null
    ) {
      handleSearch();
    }
  }, [
    preset.selectedCity,
    selectedZipcodes,
    selectedZones,
    preset.selectedProduct,
    sizemin,
    sizemax,
    totalDevelopmentMax,
    selectedUtilization,
    price,
    hardCost,
    softCost,
    financingCost,
    ami,
    yoc,
    unitSize,
    ox,
    sizeChecked,
    tdcChecked,
    utilizationChecked,
    yocChecked,
    preset.view,
    cityOwnedOnly,
    toc,
    tcac,
    filterDDA,
    filterQCT,
    utilizationOperator
  ]);

  const handlePresetSelect = (event) => {
    setQuery({ collection: event.target.value });
    setSelectedPreset(event.target.value);
    const currentPreset = presets.find((preset) => preset.id === event.target.value);
    setPresetValues(currentPreset);
    if (currentPreset !== undefined) {
      setPreset((prevState) => ({
        ...prevState,
        view: currentPreset.view,
        selectedCity: currentPreset.city,
        selectedProduct: currentPreset.product
      }));
      setSelectedZones(currentPreset.selectedZones);
      setHardCost(currentPreset.hardCost);
      setSoftCost(currentPreset.softCost);
      setPrice(currentPreset.price);
      setFinancingCost(currentPreset.financingCost);
      setAMI(currentPreset.ami);
      setYOC(currentPreset.yoc);
      setUnitSize(currentPreset.unitSize);
      setOX(currentPreset.ox);
      setUtilization(currentPreset.selectedUtilization);
      setTotalDevelopmentMax(currentPreset.totalDevelopmentMax);
      setSizeMin(currentPreset.sizemin);
      setSizeMax(currentPreset.sizemax);
      setUtilizationChecked(currentPreset.utilizationChecked);
      setTDCChecked(currentPreset.tdcChecked);
      setSizeChecked(currentPreset.sizeChecked);
      setYOCChecked(currentPreset.yocChecked);
      setCityOwnedOnly(currentPreset.cityOwnedOnly);
      setFilterQCT(currentPreset.filterQCT);
      setFilterDDA(currentPreset.filterDDA);
      setTOC(currentPreset.toc);
      setTCAC(currentPreset.tcac);
      setSelectedZipcodes(currentPreset.selectedZipcodes);
      const zipcodeFeatureList = zipcodeFeatures.features.filter((zipcode) =>
        currentPreset.selectedZipcodes.includes(zipcode.properties.zipcode)
      );
      setSelectedZipcodeFeatures(zipcodeFeatureList);
    }
  };

  const handlePresetSelectURL = (value, currentPreset) => {
    setSelectedPreset(value);
    setPresetValues(currentPreset);
    if (currentPreset === null) {
      currentPreset = presets.find((preset) => preset.id === parseInt(value));
    }
    if (currentPreset !== undefined) {
      setPreset((prevState) => ({
        ...prevState,
        view: currentPreset.view,
        selectedCity: currentPreset.city,
        selectedProduct: currentPreset.product
      }));
      setSelectedZones(currentPreset.selectedZones);
      setHardCost(currentPreset.hardCost);
      setSoftCost(currentPreset.softCost);
      setPrice(currentPreset.price);
      setFinancingCost(currentPreset.financingCost);
      setAMI(currentPreset.ami);
      setYOC(currentPreset.yoc);
      setUnitSize(currentPreset.unitSize);
      setOX(currentPreset.ox);
      setUtilization(currentPreset.selectedUtilization);
      setTotalDevelopmentMax(currentPreset.totalDevelopmentMax);
      setSizeMin(currentPreset.sizemin);
      setSizeMax(currentPreset.sizemax);
      setUtilizationChecked(currentPreset.utilizationChecked);
      setTDCChecked(currentPreset.tdcChecked);
      setSizeChecked(currentPreset.sizeChecked);
      setYOCChecked(currentPreset.yocChecked);
      setFilterQCT(currentPreset.filterQCT);
      setFilterDDA(currentPreset.filterDDA);
      setTOC(currentPreset.toc);
      setTCAC(currentPreset.tcac);
      setCityOwnedOnly(currentPreset.cityOwnedOnly);
      setSelectedZipcodes(currentPreset.selectedZipcodes);
      const zipcodeFeatureList = zipcodeFeatures.features.filter((zipcode) =>
        currentPreset.selectedZipcodes.includes(zipcode.properties.zipcode)
      );
      setSelectedZipcodeFeatures(zipcodeFeatureList);
    }
  };

  const handleSetToDefault = () => {
    setPreset((prevState) => ({
      ...prevState,
      view: 'map',
      selectedCity: '',
      selectedProduct: ''
    }));
    setSelectedZipcodes([]);
    setSelectedZones([]);
    setSelectedZipcodeFeatures([]);
    setHardCost(330);
    setSoftCost(70);
    setPrice(60);
    setFinancingCost(40);
    setAMI(100);
    setYOC(5);
    setUnitSize(525);
    setOX(30);
    setUtilization(100);
    setTotalDevelopmentMax(20000000);
    setSizeMin(0);
    setSizeMax(20000);
    setUtilizationChecked(true);
    setTDCChecked(true);
    setSizeChecked(true);
    setYOCChecked(true);
  };

  const handleSearch = async () => {
    console.log('run');
    if (loading) {
      return;
    }
    setLoading(true);
    setSearchStarted(true);

    const zipcodeFeatureList = zipcodeFeatures.features.filter((zipcode) =>
      selectedZipcodes.includes(zipcode.properties.zipcode)
    );

    setSelectedZipcodeFeatures(zipcodeFeatureList);
    const cityId = preset.selectedCity;
    const data = {
      productId: preset.selectedProduct,
      filterApplied: true,
      sizemin: sizemin,
      sizemax: sizemax,
      totalDevelopmentMax: totalDevelopmentMax,
      selectedUtilization: selectedUtilization,
      price: price,
      hardCost: hardCost,
      softCost: softCost,
      financingCost: financingCost,
      ami: ami,
      yoc: yoc,
      unitSize: unitSize,
      ox: ox,
      sizeChecked: sizeChecked,
      tdcChecked: tdcChecked,
      utilizationChecked: utilizationChecked,
      yocChecked: yocChecked,
      selectedZones: selectedZones,
      selectedZipcodes: selectedZipcodes,
      cityOwnedOnly: cityOwnedOnly,
      toc: toc,
      tcac: tcac,
      filterDDA: filterDDA,
      filterQCT: filterQCT,
      utilizationOperator: utilizationOperator
    };

    await AdminService.getAllParcelsV2(cityId, data).then((response) => {
      if (response.data.success === true) {
        setParcels(response.data.filteredParcels);
        setCSVParcels(response.data.csvParcelsList);
        setFilteredCsvList(response.data.csvParcelsList);
        setParcelsGeo(response.data.geometry.parcelsObj);
        setPointsGeo(response.data.geometry.pointsObj);
        setZoneList(response.data.zoneNames);
        const zoneFeatureList = response.data.zoneNames.filter((zone) =>
          selectedZones.includes(zone.id)
        );
        setSelectedZoneFeatures(zoneFeatureList);
      }
      setLoading(false);
      showSnackbar(response.data.message, response.data.severity, response.data.duration);
    });

    if (window.innerWidth < 768) {
      setOpen(false);
    }
    window.scrollTo(0, 0);
  };

  /*

  const showListings = async () => {
    setLoading(true);
    var listingsArray = [];
    var filteredListingsArray = filteredListings;

    if (filteredListings.length === 0) {
      for (let k = 0; k < filteredZipcodes.length; k++) {
        const listings = await AdminService.fetchSaleListingsV3(filteredZipcodes[k]).then(
          (response) => {
            return response.data.data.home_search.results;
          }
        );

        listingsArray.push(...listings);
      }
    }

    for (let i = 0; i < listingsArray.length; i++) {
      if (listingsArray[i].location.address.coordinate !== null) {
        for (let j = 0; j < parcelsGeo.features.length; j++) {
          if (
            booleanPointInPolygon(
              [
                listingsArray[i].location.address.coordinate.lon,
                listingsArray[i].location.address.coordinate.lat
              ],
              parcelsGeo.features[j].geometry
            )
          ) {
            parcelsGeo.features[j].properties.listing = listingsArray[i];
            parcelsGeo.features[j].properties.listingStatus = 1;
            filteredListingsArray.push(listingsArray[i]);
          }
        }
      }
    }
    setUpdateMap(true);
    setFilteredListings(filteredListingsArray);
    setLoading(false);
  };

  */

  const handleSave = async () => {
    const parcelIds = parcels.map((parcel) => parcel.id);
    const data = {
      id: uuidv4(),
      userid: accountInfo.id,
      name: presetsName,
      city: preset.selectedCity,
      product: preset.selectedProduct,
      sizemin: sizemin,
      sizemax: sizemax,
      totalDevelopmentMax: totalDevelopmentMax,
      selectedUtilization: selectedUtilization,
      price: price,
      hardCost: hardCost,
      softCost: softCost,
      financingCost: financingCost,
      ami: ami,
      yoc: yoc,
      unitSize: unitSize,
      ox: ox,
      sizeChecked: sizeChecked,
      tdcChecked: tdcChecked,
      utilizationChecked: utilizationChecked,
      yocChecked: yocChecked,
      view: preset.view,
      selectedZones: selectedZones,
      selectedZipcodes: selectedZipcodes,
      archived: false,
      cityOwnedOnly: cityOwnedOnly,
      filterQCT: filterQCT,
      filterDDA: filterDDA,
      toc: toc,
      tcac: tcac,
      parcel_collection: parcelIds,
      share_id: uuidv4(),
      maxBuildableChecked: maxBuildableChecked,
      maxBuildableMax: maxBuildableMax,
      maxBuildableMin: maxBuildableMin
    };

    await AdminService.savePresets(data).then((response) => {
      if (response.status === 200) {
        setDialogOpen(false);
        AdminService.getAllPresets(accountInfo.id).then((response) => {
          setPresets(
            response.data
              .filter((preset) => preset.archived === false)
              .sort((a, b) => {
                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
              })
              .reverse()
          );
        });
        setSelectedPreset(response.data.id);
        setSnackbarOpen(true);
      }
    });

    setQuery(data.id);
  };

  const handleUpdate = async () => {
    const parcelIds = parcels.map((parcel) => parcel.id);
    const data = {
      city: preset.selectedCity,
      product: preset.selectedProduct,
      sizemin: sizemin,
      sizemax: sizemax,
      totalDevelopmentMax: totalDevelopmentMax,
      selectedUtilization: selectedUtilization,
      price: price,
      hardCost: hardCost,
      softCost: softCost,
      financingCost: financingCost,
      ami: ami,
      yoc: yoc,
      unitSize: unitSize,
      ox: ox,
      sizeChecked: sizeChecked,
      tdcChecked: tdcChecked,
      utilizationChecked: utilizationChecked,
      yocChecked: yocChecked,
      view: preset.view,
      selectedZones: selectedZones,
      selectedZipcodes: selectedZipcodes,
      archived: false,
      cityOwnedOnly: cityOwnedOnly,
      filterQCT: filterQCT,
      filterDDA: filterDDA,
      toc: toc,
      tcac: tcac,
      parcel_collection: parcelIds,
      maxBuildableChecked: maxBuildableChecked,
      maxBuildableMax: maxBuildableMax,
      maxBuildableMin: maxBuildableMin
    };

    await AdminService.updatePresets(selectedPreset, data).then((response) => {
      if (response.status === 200) {
        setSnackbarOpen(true);
      }
    });
  };

  const onHover = (hoveredFeature) => {
    setHoveredFeature({ properties: hoveredFeature.h });
  };

  var zipcodes = selectedZipcodes;

  const zipcodeHover = async (hoveredFeature) => {
    zipcodes = [...zipcodes, hoveredFeature];
    setSelectedZipcodes(zipcodes);
  };

  const onChangeZones = (e, v) => {
    const valueArray = [];
    const objectArray = [];
    for (let i = 0; i < v.length; i++) {
      valueArray.push(v[i].id);
      objectArray.push(v[i]);
    }
    setSelectedZoneFeatures(objectArray);
    setSelectedZones(valueArray);
  };

  const onChangeZipcodes = (e, v) => {
    const valueArray = [];
    const nameArray = selectedZipcodes;
    for (let i = 0; i < v.length; i++) {
      valueArray.push(v[i]);
      nameArray.push(v[i].properties.zipcode);
    }
    setSelectedZipcodes(nameArray);
    setSelectedZipcodeFeatures(valueArray);
  };

  useEffect(() => {
    async function fetchSearches() {
      await AdminService.getQueriesByUserId(accountInfo.id).then(
        (response) => {
          const allSearches = response.data;
          const allSearchesMinusArchived = allSearches.filter((a) => a.archived === false);
          setUserSearches(allSearchesMinusArchived);
        },
        (error) => {}
      );
    }
    if (isLoggedIn) {
      fetchSearches();
    }
  }, [isLoggedIn, accountInfo.id]);

  const [existsDialogId, setExistsDialogId] = useState();

  const handleAnalyze = async (userParcel, continueWithAnalysis) => {
    const pl = products.find((product) => product.id === preset.selectedProduct);
    var productLineKey = pl.key;

    const googlePlace = await AdminService.reverseGeocode(
      userParcel.properties.latitude,
      userParcel.properties.longitude
    );

    if (!existsDialogId) {
      for (let i = 0; i < userSearches.length; i++) {
        if (googlePlace.data.results[0].formatted_address === userSearches[i].address) {
          setExistsDialogOpen(true);
          continueWithAnalysis = false;
          setExistsDialogId(userSearches[i].id);
        }
      }
    }

    if (continueWithAnalysis === true) {
      const queryId = uuidv4();
      await AdminService.addQuery(
        queryId,
        googlePlace.data.results[0].formatted_address,
        userParcel.properties.zipcode,
        userParcel.properties.latitude,
        userParcel.properties.longitude,
        userParcel.properties.cityId,
        (price * userParcel.properties.max_buildable).toFixed(0),
        null,
        accountInfo.id,
        productLineKey,
        null,
        googlePlace,
        userParcel.properties.id,
        true,
        ['prod_Nebs4io9n5o4sZ']
      );

      var metadata = {
        zipcode: userParcel.properties.zipcode,
        address: googlePlace.data.results[0].formatted_address
      };
      window.Intercom('trackEvent', 'new-search', metadata);

      navigate(`/redtech/${queryId}?search=true`);
    }
  };

  const handleAnalyzeBasic = async (userParcel, continueWithAnalysis) => {
    const pl = products.find((product) => product.id === preset.selectedProduct);
    var productLineKey = pl.key;

    const googlePlace = await AdminService.reverseGeocode(
      userParcel.properties.latitude,
      userParcel.properties.longitude
    );

    if (!existsDialogId) {
      for (let i = 0; i < userSearches.length; i++) {
        if (googlePlace.data.results[0].formatted_address === userSearches[i].address) {
          setExistsDialogOpen(true);
          continueWithAnalysis = false;
          setExistsDialogId(userSearches[i].id);
        }
      }
    }

    if (continueWithAnalysis === true) {
      const queryId = uuidv4();

      var user = null;
      if (isLoggedIn) {
        user = accountInfo.id;
      } else {
        user = 'anonymous';
      }

      await AdminService.addQuery(
        queryId,
        googlePlace.data.results[0].formatted_address,
        userParcel.properties.zipcode,
        userParcel.properties.latitude,
        userParcel.properties.longitude,
        userParcel.properties.cityId,
        (price * userParcel.properties.max_buildable).toFixed(0),
        null,
        user,
        productLineKey,
        null,
        googlePlace,
        userParcel.properties.id,
        false,
        []
      );

      var metadata = {
        zipcode: userParcel.properties.zipcode,
        address: googlePlace.data.results[0].formatted_address
      };
      window.Intercom('trackEvent', 'basic-analysis', metadata);

      navigate(`/redtech/${queryId}?search=true`);
    }
  };

  const handleSelectedTable = (v) => {
    const filteredList = csvParcels.filter((a) => v.includes(a.id));
    setFilteredCsvList(filteredList);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const accountCreated = new Date(accountInfo.createdAt);

  const isAccountOlderThanSevenDays = () => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    return accountCreated.getTime() < sevenDaysAgo.getTime();
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  }));

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: libraries
  });

  return (
    <>
      <Header />
      <div className="admin-search-container">
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              paddingTop: `${marginTop}`
            }
          }}
          variant="persistent"
          anchor="left"
          open={open}>
          <DrawerHeader>
            <Button color="secondary" onClick={handleDrawerClose}>
              {<ChevronLeftIcon />} Close
            </Button>
          </DrawerHeader>
          <div className="admin-search-filters">
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              {presets.length > 0 && (
                <>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Saved Collection</InputLabel>
                    <Select
                      onChange={handlePresetSelect}
                      value={selectedPreset}
                      label="Select Saved Preset">
                      <MenuItem key={'none'} value={''}>
                        Select Saved Collection
                      </MenuItem>
                      {presets.map((preset) => (
                        <MenuItem key={preset.id} value={preset.id}>
                          {preset.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
              {isLoggedIn &&
                selectedPreset !== '' &&
                presetValues &&
                presetValues.userid === accountInfo.id && (
                  <Button fullWidth variant="outlined" color="secondary" onClick={handleUpdate}>
                    Update Collection
                  </Button>
                )}
              {isLoggedIn && selectedPreset === '' && parcels.length > 0 && (
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={() => setDialogOpen(true)}>
                  Create Collection
                </Button>
              )}
            </Box>
            <Divider />
            {isLoaded && <CustomGeocoder isLoaded={isLoaded} />}
            {cities && (
              <Select
                label="City"
                onChange={(e) =>
                  setPreset((prevState) => ({
                    ...prevState,
                    selectedCity: e.target.value
                  }))
                }
                value={preset.selectedCity}>
                {cities.map((city) => (
                  <MenuItem key={city.id} value={city.id}>
                    {city.name}, {city.state}
                  </MenuItem>
                ))}
              </Select>
            )}
            {zipcodeFeatures.features.length > 0 && (
              <Autocomplete
                multiple
                id="zipcodes"
                options={zipcodeFeatures.features}
                value={selectedZipcodeFeatures}
                getOptionLabel={(opt) => opt.properties.zipcode}
                onChange={onChangeZipcodes}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="secondary"
                    variant="outlined"
                    label="Zipcodes"
                    placeholder="Zipcodes"
                  />
                )}
              />
            )}
            {products && (
              <Select
                label="Product"
                onChange={(e) =>
                  setPreset((prevState) => ({
                    ...prevState,
                    selectedProduct: e.target.value
                  }))
                }
                value={preset.selectedProduct}>
                {products.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            <h6>
              Filters{' '}
              <Button variant="outlined" color="primary" onClick={() => handleSetToDefault()}>
                Reset to Defaults
              </Button>
            </h6>
            {zoneList.length > 0 && (
              <Autocomplete
                multiple
                id="zones"
                options={zoneList}
                value={selectedZoneFeatures}
                getOptionLabel={(opt) => opt.name}
                onChange={onChangeZones}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="secondary"
                    variant="outlined"
                    label="Zones"
                    placeholder="Zones"
                  />
                )}
              />
            )}
            <FormControlLabel
              disabled={disabled}
              control={
                <Switch
                  checked={cityOwnedOnly}
                  onChange={(e) => {
                    setCityOwnedOnly(e.target.checked);
                  }}
                  color="secondary"
                />
              }
              label="City Owned Parcels Only"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={filterQCT}
                  onChange={() => setFilterQCT(!filterQCT)}
                  color="secondary"
                />
              }
              label="Show only Qualified Census Tracts"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={filterDDA}
                  onChange={() => setFilterDDA(!filterDDA)}
                  color="secondary"
                />
              }
              label="Show only Difficult Development Areas"
            />
            {cityInfo && cityInfo.state === 'California' && (
              <Autocomplete
                multiple
                id="ctcac"
                options={[
                  'Highest Resource',
                  'High Resource',
                  'Moderate Resource(Rapidly Changing)',
                  'Moderate Resources',
                  'Low Resource',
                  'High Segregation & Poverty',
                  'Missing/Insufficient Data',
                  'National & State Park/Forest/Rec Area'
                ]}
                getOptionLabel={(opt) => opt}
                onChange={() => setTCAC()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    focused
                    color="secondary"
                    variant="outlined"
                    label="California TCAC"
                    placeholder="California TCAC"
                  />
                )}
              />
            )}
            {cityInfo && cityInfo.name === 'Los Angeles' && (
              <Autocomplete
                multiple
                id="toc"
                options={['1', '2', '3', '4']}
                getOptionLabel={(opt) => opt}
                onChange={() => setTOC()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    focused
                    color="secondary"
                    variant="outlined"
                    label="LA TOC"
                    placeholder="LA TOC"
                  />
                )}
              />
            )}
            <p>Parcel Size</p>
            <div className="admin-parcel-search-filters-row">
              <div className="admin-parcel-search-filters-column">
                <TextField
                  id="outlined-basic"
                  label="Minimum Parcel Size"
                  variant="outlined"
                  value={sizemin}
                  onChange={(e) => setSizeMin(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        ft<sup>2</sup>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Maximum Parcel Size"
                  variant="outlined"
                  value={sizemax}
                  onChange={(e) => setSizeMax(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        ft<sup>2</sup>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <Checkbox
                checked={sizeChecked}
                onChange={(event) => setSizeChecked(event.target.checked)}
                color="secondary"
              />
            </div>
            {/* 
            
                  <p>Max Buildable</p>
            <div className="admin-parcel-search-filters-row">
              <div className="admin-parcel-search-filters-column">
                <TextField
                  id="outlined-basic"
                  label="Max Buildable Min"
                  variant="outlined"
                  value={maxBuildableMin}
                  onChange={(e) => setMaxBuildableMin(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        ft<sup>2</sup>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Max Buildable Max"
                  variant="outlined"
                  value={maxBuildableMax}
                  onChange={(e) => setMaxBuildableMax(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        ft<sup>2</sup>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <Checkbox
                checked={maxBuildableChecked}
                onChange={(event) => setMaxBuildableChecked(event.target.checked)}
                color="secondary"
              />
            </div>
            */}

            <p>Total Development Cost</p>
            <div className="admin-parcel-search-filters-row">
              <div className="admin-parcel-search-filters-column">
                <TextField
                  disabled={disabled}
                  id="outlined-basic"
                  label="Maximum Total Development Cost"
                  variant="outlined"
                  value={totalDevelopmentMax}
                  onChange={(e) => setTotalDevelopmentMax(e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                />
              </div>
              <Checkbox
                disabled={disabled}
                checked={tdcChecked}
                onChange={(event) => setTDCChecked(event.target.checked)}
                color="secondary"
              />
            </div>
            <p>Other</p>
            <div className="admin-parcel-search-filters-row">
              <div className="admin-parcel-search-filters-column">
                <TextField
                  disabled={disabled}
                  id="outlined-basic"
                  label="Maximum Utilization"
                  variant="outlined"
                  value={selectedUtilization}
                  onChange={(e) => setUtilization(e.target.value)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </div>
              <ToggleButtonGroup
                disabled={disabled}
                color="secondary"
                value={utilizationOperator}
                exclusive
                onChange={(e, v) => {
                  setUtilizationOperator(v);
                }}>
                <ToggleButton disabled={disabled} value="building">
                  Built
                </ToggleButton>
                <ToggleButton value="value">Value</ToggleButton>
              </ToggleButtonGroup>
              <Checkbox
                disabled={disabled}
                checked={utilizationChecked}
                onChange={(event) => setUtilizationChecked(event.target.checked)}
                color="secondary"
              />
            </div>
            <div className="admin-parcel-search-filters-row">
              <div className="admin-parcel-search-filters-column">
                <TextField
                  disabled={disabled}
                  id="outlined-basic"
                  label="Minimum Yield On Cost"
                  variant="outlined"
                  value={yoc}
                  onChange={(e) => setYOC(e.target.value)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                />
              </div>
              <Checkbox
                disabled={disabled}
                checked={yocChecked}
                onChange={(event) => setYOCChecked(event.target.checked)}
                color="secondary"
              />
            </div>
            <Divider />
            <h6>Assumptions</h6>
            <p>Architecture</p>
            <TextField
              id="outlined-basic"
              label="Average Unit Size"
              variant="outlined"
              value={unitSize}
              onChange={(e) => setUnitSize(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    ft<sup>2</sup>
                  </InputAdornment>
                )
              }}
            />
            <p>Cost</p>
            <div className="admin-parcel-search-filters-column-narrow">
              <TextField
                id="outlined-basic"
                label="Land Cost / Buildable SQFT"
                variant="outlined"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
              />
              <TextField
                id="outlined-basic"
                label="Hard Cost / Buildable SQFT"
                variant="outlined"
                value={hardCost}
                onChange={(e) => setHardCost(e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
              />
              <TextField
                id="outlined-basic"
                label="Soft Cost / Buildable SQFT"
                variant="outlined"
                value={softCost}
                onChange={(e) => setSoftCost(e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
              />
              <TextField
                id="outlined-basic"
                label="Financing Cost / Buildable SQFT"
                variant="outlined"
                value={financingCost}
                onChange={(e) => setFinancingCost(e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
              />
            </div>
            <p>Operations</p>
            <TextField
              id="outlined-basic"
              label="Percent Operating Expenses"
              variant="outlined"
              value={ox}
              onChange={(e) => setOX(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
            <TextField
              id="outlined-basic"
              label="Target Percent AMI"
              variant="outlined"
              value={ami}
              onChange={(e) => setAMI(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          </div>
        </Drawer>
        {loading && (
          <div className="soft-loading-spinner">
            <LoadingSpinner />
          </div>
        )}
        <Box sx={{ marginLeft: open ? { xs: '100%', md: '25%' } : 0 }}>
          <div className="admin-search-results">
            <>
              <div className="admin-search-header">
                {preset.view === 'list' && (
                  <Button
                    size="large"
                    variant="outlined"
                    color="secondary"
                    startIcon={<FileCopyIcon />}>
                    <CSVLink className="csv-link" data={filteredCSVList}>
                      Download CSV ({filteredCSVList.length})
                    </CSVLink>
                  </Button>
                )}
                {preset.view === 'map' && !open && (
                  <Button
                    endIcon={<ChevronRight />}
                    variant="contained"
                    color="secondary"
                    onClick={handleDrawerOpen}>
                    Filters
                  </Button>
                )}
                <p>
                  <strong>
                    {selectedPreset !== '' && presetValues && presetValues.name + ': '}
                  </strong>
                  {parcels.length > 0 && (
                    <strong>
                      <span className="orange">{parcels.length.toLocaleString()}</span> Results
                    </strong>
                  )}
                  {parcels.length === 0 && <strong>Select a zipcode to get started</strong>}
                </p>
                <ToggleButtonGroup
                  variant="contained"
                  color="secondary"
                  exclusive
                  value={preset.view}
                  onChange={(e, v) => {
                    setPreset((prevState) => ({
                      ...prevState,
                      view: v
                    }));
                    setHoveredFeature(null);
                  }}>
                  <ToggleButton value="list">
                    <ListIcon />
                  </ToggleButton>
                  <ToggleButton value="map">
                    <MapIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              {preset.view === 'list' && (
                <EnhancedTable
                  parcels={parcels}
                  handleAnalyze={() =>
                    isAccountOlderThanSevenDays() ? handleAnalyzeBasic : handleAnalyze
                  }
                  handleSelectedTable={handleSelectedTable}
                  cityInfo={cityInfo}
                />
              )}

              {preset.view === 'map' && (
                <div className="admin-search-map">
                  <MapboxMap
                    zipcodeFeatures={zipcodeFeatures}
                    selectedZipcodes={selectedZipcodes}
                    parcels={parcels}
                    parcelsGeo={parcelsGeo}
                    pointsGeo={pointsGeo}
                    viewport={viewport}
                    onHover={onHover}
                    zipcodeHover={zipcodeHover}
                    isLoaded={isLoaded}
                  />
                  {hoveredFeature !== null && (
                    <div className="admin-search-map-hover">
                      <div style={{ float: 'right', textAlign: 'right' }}>
                        <Button onClick={() => setHoveredFeature(null)}>
                          <Close />
                        </Button>
                      </div>
                      <h2 style={{ marginTop: -15 }}>{hoveredFeature.properties.address}</h2>
                      <ul>
                        <li>Zone: {hoveredFeature.properties.zone_name}</li>
                        <li>
                          Size: {parseFloat(hoveredFeature.properties.size).toLocaleString()} ft
                          <sup>2</sup> ({hoveredFeature.properties.width}' x{' '}
                          {hoveredFeature.properties.depth}')
                        </li>
                      </ul>
                      {!isLoggedIn && cityInfo.comingSoon !== true && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setSigninDialogOpen(true)}>
                          Signin for Analysis
                        </Button>
                      )}
                      {isLoggedIn &&
                        accountInfo.roles.includes('ROLE_ADMIN') &&
                        cityInfo.comingSoon !== true && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleAnalyze(hoveredFeature, true)}>
                            Analyze Property
                          </Button>
                        )}
                      {isLoggedIn &&
                        !accountInfo.roles.includes('ROLE_ADMIN') &&
                        cityInfo.comingSoon !== true && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              isAccountOlderThanSevenDays()
                                ? handleAnalyzeBasic(hoveredFeature, true)
                                : handleAnalyze(hoveredFeature, true)
                            }>
                            Analyze Property
                          </Button>
                        )}
                    </div>
                  )}
                </div>
              )}
            </>
          </div>
        </Box>
        <Dialog open={dialogOpen} fullWidth={true} maxWidth={'sm'}>
          <DialogTitle>Save Collection</DialogTitle>
          <DialogContent>
            <div>
              <p>
                Enter the name of the collection, something you will remember for future use (ie
                Denver Multifamily Limited Size)
              </p>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Collection Name"
                variant="outlined"
                value={presetsName}
                onChange={(e) => setPresetsName(e.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave} color="secondary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={existsDialogOpen} fullWidth={true} maxWidth={'sm'}>
          <DialogTitle>Previously Analyzed</DialogTitle>
          <DialogContent>
            <div>
              <p>
                You have previously analyzed this property. Please decide how you would like to
                proceed.
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setExistsDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/redtech/${existsDialogId}`);
              }}
              color="secondary">
              View Previous
            </Button>
          </DialogActions>
        </Dialog>
        <Authentication status={signinDialogOpen} type={'signin'} />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity="success">
            Successfully saved to your profile!
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}
