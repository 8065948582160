import React from 'react';

import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

import Header from '../common/header';

export default function PartnerLanding(props) {
  return (
    <>
      <Header />
      <Box sx={{ padding: '25px 10%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '1.3rem'
          }}>
          <h4>Become a Madelon industrialized con-tech partner!</h4>
          <h6>
            Welcome to REDtech! To get started as a partner, follow our simple onboarding process.
            This will guide you through the necessary steps to integrate your services with our
            platform.
          </h6>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '50px',
              marginBottom: '50px'
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <h5>Our con-tech partners include</h5>
              <ul style={{ textAlign: 'left' }}>
                <li>Volumetric pre-fab factories</li>
                <li>Masstimber producers</li>
                <li>Kit-of-parts suppliers</li>
              </ul>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <h5>Benefits of partnering with Madelon</h5>
              <ul style={{ textAlign: 'left' }}>
                <li>Madelon will send you pre-qualified leads</li>
                <li>Recieve standaradized product sales across projects and customers</li>
                <li>Decrease customer acquisition cost and increase conversion rates</li>
              </ul>
            </Box>
          </Box>

          <Button variant="contained" color="secondary" component={Link} to="/partners/onboarding">
            Start 1 Minute Onboarding
          </Button>
          <p>
            Have questions?
            <br />
            <a href="mailto:hello@madelongroup.com">Send us an email</a>
          </p>
        </Box>
      </Box>
    </>
  );
}
