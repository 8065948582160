import React, { useState } from 'react';

import { Box, Card } from '@mui/material';
import { DoubleArrow } from '@mui/icons-material';

export default function HomepageSplash(props) {
  const [showCardBack, setShowCardBack] = useState(false);
  const [cardStyle, setCardStyle] = useState({
    width: '250px',
    height: '200px',
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#fff',
    color: '#eb4e32',
    transition: 'all 0.5s ease',
    borderRadius: '10px',
    fontWeight: 'bold'
  });

  const handleMouseOver = () => {
    setShowCardBack(true);
    setCardStyle({
      ...cardStyle,
      backgroundColor: '#eb4e32',
      color: '#fff',
      transition: 'all 0.5s ease',
      cursor: 'pointer'
    });
  };
  const handleMouseOut = () => {
    setShowCardBack(false);
    setCardStyle({
      ...cardStyle,
      backgroundColor: '#fff',
      color: '#eb4e32',
      transition: 'all 0.5s ease'
    });
  };

  return (
    <Card sx={cardStyle} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}>
        {showCardBack ? <p>{props.back}</p> : <h1>{props.front}</h1>}
        <DoubleArrow sx={{ position: 'absolute', bottom: 0 }} />
      </Box>
    </Card>
  );
}
