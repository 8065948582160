import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AdminService from '../../services/admin.service';

import { Box, Button, Divider } from '@mui/material';

import { useUserStore } from '../../store';

import Moment from 'react-moment';

export default function Presets() {
  const [presetsRender, setPresetsRender] = useState(null);
  const [refresh, setRefesh] = useState(false);

  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const accountInfo = useUserStore((state) => state.accountInfo);

  const handleArchive = async (presetId) => {
    const data = {
      archived: true
    };

    await AdminService.updatePresets(presetId, data).then((response) => {
      if (response.status === 200) {
        setRefesh(true);
      }
    });
  };

  useEffect(() => {
    async function getPresets() {
      if (isLoggedIn) {
        await AdminService.getAllPresets(accountInfo.id).then((response) => {
          console.log(response);
          const data = response.data
            .filter((preset) => preset.archived === false)
            .sort((a, b) => {
              return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
            })
            .reverse();

          const presetsRender = data.map((preset) => {
            console.log(preset);
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#fff',
                  padding: '15px',
                  borderRadius: '5px',
                  gap: '10px',
                  flex: '1 1 300px'
                }}
                key={preset.id}>
                <h6 style={{ padding: 0, margin: 0 }}>{preset.name}</h6>
                <p style={{ padding: 0, margin: 0 }}>
                  Last Updated:<Moment format="YYYY - MM - DD">{preset.updatedAt}</Moment>
                </p>
                <p style={{ padding: 0, margin: 0 }}>Results: {preset.parcel_collection.length}</p>
                <Divider />
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to={`/search?collection=${preset.id}`}>
                  View Collection
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleArchive(preset.id)}>
                  Archive Collection
                </Button>
              </Box>
            );
          });
          setPresetsRender(presetsRender);
        });
      }
    }
    getPresets();
  }, [accountInfo.id, isLoggedIn, refresh]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '25px', flexWrap: 'wrap' }}>
      {presetsRender}
    </Box>
  );
}
