import React, { useState, useCallback } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%'
};

function ParcelMapGoogle(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY
  });

  const [center] = useState({
    lat: parseFloat(props.parcel.latitude),
    lng: parseFloat(props.parcel.longitude)
  });

  // eslint-disable-next-line
  const [map, setMap] = useState(null);

  const geoJsonParcel = {
    type: 'FeatureCollection',
    features: [{ id: props.parcel.id, type: 'Feature', geometry: props.parcel.geo }]
  };

  const onLoad = useCallback(function callback(map) {
    map.setZoom(17);
    map.data.addGeoJson(geoJsonParcel);
    map.data.setStyle({
      fillColor: '#EEB902',
      fillOpacity: 0,
      strokeWeight: 2,
      strokeColor: 'red'
    });
    setMap(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={11}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          disableDefaultUI: true,
          mapTypeId: 'satellite'
        }}>
        <></>
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default React.memo(ParcelMapGoogle);
