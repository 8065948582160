import React, { useState } from 'react';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

import AdminService from '../../services/admin.service';

import { useUserStore } from '../../store';

const Account = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const accountInfo = useUserStore((state) => state.accountInfo);

  const handleDeleteAccount = async () => {
    await AdminService.deleteUser(accountInfo.id);
    window.location.pathname = '/';
  };

  return (
    <>
      {isLoggedIn && (
        <>
          <p>{accountInfo.email}</p>
          <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
            Delete Account
          </Button>
        </>
      )}

      {!isLoggedIn && (
        <>
          <div className="alerts">
            <Alert severity="warning">Not Signed In</Alert>
          </div>
        </>
      )}
      <Dialog open={openDialog}>
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your account? This action cannot be undone and all
            access to purchases will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAccount} color="secondary" variant="contained">
            Confirm Delete Account
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Account;
