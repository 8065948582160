import axios from 'axios';

import { API_URL } from '../config';

class PartnerService {
  partnerOnboardingRequest(formData) {
    return axios.post(API_URL + `v2/partner/onboarding-request`, {
      formData
    });
  }
}
// eslint-disable-next-line
export default new PartnerService();
