import React, { useState } from 'react';

import {
  FormControlLabel,
  FormControl,
  Switch,
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
  MenuItem
} from '@mui/material';

import HelpIcon from '@mui/icons-material/Help';

export default function RedtechOptions(props) {
  /*
  const [dealStatus, setDealStatus] = useState('Unclassified');
  const handleChange = (event) => {
    setDealStatus(event.target.value);
    console.log(dealStatus);
  };

  var dealStatusOptions = ['Unclassified', 'Active', 'Closed', 'Cancelled'];

  */

  var productLineOptions = [
    { key: 'multifamily', name: 'Multi-Family' },
    { key: 'microstudio', name: 'Microstudio' }
  ];

  const [cost, setCost] = useState(props.allData.query.cost);

  const handleCostChange = (event) => {
    const value = event.target.value.replaceAll(',', '');
    setCost(parseInt(value));
    setTimeout(() => {
      props.handleUpdateUserData({ cost: parseInt(value) });
    }, 1500);
  };

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
      {/*
      <FormControl>
        <TextField
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.allData.query.deal_status}
          variant="outlined"
          label="Project Status"
          select
          onChange={handleChange}>
          {dealStatusOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
          */}
      <FormControl>
        <TextField
          value={cost.toLocaleString()}
          onChange={handleCostChange}
          variant="outlined"
          label="Acquisition/Sale Price"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
        />
      </FormControl>

      {props.allData.query.productLine === 'lowrise' && (
        <>
          <FormControlLabel
            control={
              <Switch
                checked={props.redtechOptions.flexmf}
                onChange={(event) =>
                  props.handleUpdateRedtechOptions({ flexmf: event.target.checked })
                }
              />
            }
            label="Underwrite as Flex Multifamily"
          />
          <Tooltip
            title={
              <>
                <Typography color="inherit">What is Flex Multifamily?</Typography>
                <Typography color="inherit">
                  The way in which we have designed this lowrise product owners have the ability to
                  increase the number of keys availible for rent.
                </Typography>
              </>
            }>
            <HelpIcon />
          </Tooltip>
        </>
      )}
      {(props.allData.query.productLine === 'odu' ||
        props.allData.query.productLine === 'multifamily' ||
        props.allData.query.productLine === 'microstudio') && (
        <FormControl>
          <TextField
            fullWidth
            id="demo-simple-select"
            value={props.allData.query.productLine}
            variant="outlined"
            label="Product Line"
            select
            onChange={(event) => props.handleUpdateUserData({ productLine: event.target.value })}>
            {productLineOptions.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      )}
    </div>
  );
}
