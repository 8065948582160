import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

class Page404 extends Component {
  render() {
    return (
      <div className="fourohfour-container">
        <h1>welcome to the 404</h1>
        <h1>...no not Atlanta</h1>
        <Button variant="outlined" color="inherit" component={Link} to="/">
          Go Home
        </Button>
        <p>
          If you think this is an error get in touch: <br />
          <a href="mailto:hello@madelongroup.com">hello@madelongroup.com</a>
        </p>
      </div>
    );
  }
}

export default Page404;
